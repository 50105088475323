import React,{Component} from 'react';
import * as stringUtil from '../utils/stringUtil.js';
import * as arithUtil from '../utils/arithUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import TabTop from '../components/TabTop';
import TabBarBn from '../components/TabBarBn.js';
import MaskModal from '../components/MaskModal';

import TabHome from './TabHome';
import TabMe from './TabMe';
import TabContact from './TabContact';
import TabLog from './TabLog';
import TabRecept from './TabRecept';
import TabProcess from './TabProcess';
import TabWholesale from './TabWholesale';
import TabMoney from './TabMoney';
import TabSetting from './TabSetting';
import ShopUpdateActivity from './ShopUpdateActivity';
import AddOwnerBatchActivity from './AddOwnerBatchActivity';
import AddSelfBatchActivity from './AddSelfBatchActivity';
import AddQuarrelBatchActivity from './AddQuarrelBatchActivity';
import ProcureOrdersActivity from './ProcureOrdersActivity';
import WarehouseOutEntActivity from './WarehouseOutEntActivity';
import CountsOrdersActivity from './CountsOrdersActivity';
import CargoSonsAddActivity from './CargoSonsAddActivity';
import ReceivedSendActivity from './ReceivedSendActivity';
import CargoAddCommodityActivity from './CargoAddCommodityActivity';
import CargoSettlesActivity from './CargoSettlesActivity';
import CargoSonsActivity from './CargoSonsActivity';
import WarehouseOutEntAddActivity from './WarehouseOutEntAddActivity';
import ReceivedSendAddActivity from './ReceivedSendAddActivity';
import DeleteContactActivity from './DeleteContactActivity';
import CustomerAddActivity from './CustomerAddActivity';
import OwnerAddActivity from './OwnerAddActivity';
import SupplierAddActivity from './SupplierAddActivity';
import CompanyAddActivity from './CompanyAddActivity';
import CustomerUpdateActivity from './CustomerUpdateActivity';
import OwnerUpdateActivity from './OwnerUpdateActivity';
import SupplierUpdateActivity from './SupplierUpdateActivity';
import CompanyUpdateActivity from './CompanyUpdateActivity';
import WorkerUpdateActivity from './WorkerUpdateActivity';
import BusinessDetailsActivityCustomer from './BusinessDetailsActivityCustomer';
import BusinessDetailsActivityOwner from './BusinessDetailsActivityOwner';
import BusinessDetailsActivitySupplier from './BusinessDetailsActivitySupplier';
import BusinessDetailsActivityWorker from './BusinessDetailsActivityWorker';
import AuthoritySettingActivity from './AuthoritySettingActivity';
import ProcessOrderAddActivity from './ProcessOrderAddActivity';
import ProcessOrdersActivity from './ProcessOrdersActivity';
import ProcessOrderSaveActivity from './ProcessOrderSaveActivity';
import ProcessOrderInfoActivity from './ProcessOrderInfoActivity';
import DebtCollectActivity from './DebtCollectActivity';
import CountsOrderAddActivity from './CountsOrderAddActivity';
import DateSettleActivity from './DateSettleActivity';
import DateCollectExpendActivity from './DateCollectExpendActivity';
import DebtCustomerActivity from './DebtCustomerActivity';
import CollectPayAccountsActivity from './CollectPayAccountsActivity';
import OwnerSaleStatisticsActivity from './OwnerSaleStatisticsActivity';
import OwnerSaleStatistics from './OwnerSaleStatistics';
import SaleDateActivity from './SaleDateActivity';
import OwnerSettleSummaryActivity from './OwnerSettleSummaryActivity';
import CustomerAccountsDetailsActivity from './CustomerAccountsDetailsActivity';
import SupplierAccountsDetailsActivity from './SupplierAccountsDetailsActivity';
import CustomerSaleSettleActivity from './CustomerSaleSettleActivity';
import SaleProfitActivity from './SaleProfitActivity';
import SaleStatisticsActivity from './SaleStatisticsActivity';
import SaleProfitLossActivity from './SaleProfitLossActivity';
import ShopProfitLossActivity from './ShopProfitLossActivity';
import ProcessStatisticsActivity from './ProcessStatisticsActivity';
import InOutWarehouseActivity from './InOutWarehouseActivity';
import OrdersListActivity from './OrdersListActivity';
import OrdersWarehouseActivity from './OrdersWarehouseActivity';
import OrdersWarehousesActivity from './OrdersWarehousesActivity';
import SaleTimingActivity from './SaleTimingActivity';
import PurchaseStatisticsActivity from './PurchaseStatisticsActivity';
import WorkerSelfSortActivity from './WorkerSelfSortActivity';
import BuyerSortActivity from './BuyerSortActivity';
import WholesaleAddActivity from './WholesaleAddActivity';
import OrdersAddActivity from './OrdersAddActivity';
import OrdersSaveActivity from './OrdersSaveActivity';
import WholesaleAddCargoActivity from './WholesaleAddCargoActivity';
import WholesaleAddsActivity from './WholesaleAddsActivity';
import BackAddWholesaleActivity from './BackAddWholesaleActivity';
import WholesaleSaveActivity from './WholesaleSaveActivity';
import WholesaleSavesActivity from './WholesaleSavesActivity';
import WholesaleUpdateActivity from './WholesaleUpdateActivity';
import CargoTransferOwnerActivity from './CargoTransferOwnerActivity';
import CargoTransferSelfActivity from './CargoTransferSelfActivity';
import CargoTransferQuarrelActivity from './CargoTransferQuarrelActivity';
import BuyerStatisticsActivity from './BuyerStatisticsActivity';
import SaleReportDailyActivity from './SaleReportDailyActivity';

export default class Main extends Component{
  constructor(props){
    super(props);
    this.state={
      positionVal:0
    };
  }
  
  componentDidMount(){}

 render(){
   let showViews = [<TabMe context={this}/>,
      <TabHome context={this}/>,<TabContact context={this}/>,<TabRecept context={this}/>,<TabProcess context={this}/>,
      <TabWholesale context={this}/>,<TabMoney context={this}/>,<TabSetting context={this}/>,<TabLog context={this}/>
    ];
    if (otherUtil.authorityFindCode("02")==null) {//stringUtil.language(1084)stringUtil.language(1171)020101
        delete showViews[3];
    }
    if (otherUtil.authorityFindCode("0203")==null) {//stringUtil.language(1056)stringUtil.language(1171)020301
        delete showViews[4];
    }
    if (otherUtil.authorityFindCode("0204")==null) {//stringUtil.language(1037)stringUtil.language(1171)020401
        delete showViews[5];
    }
    if (otherUtil.authorityFindCode("03")==null) {//财务模块
        delete showViews[6];
    }
    if (localStorage.getItem("currentStallItem")!=null && JSON.parse(localStorage.getItem("currentStallItem")).shopRole!=0){//0老板
        delete showViews[showViews.length-1];
    }
    let showView = <div><span>{stringUtil.language(62)}</span></div>;
    for(let i=-1;i<showViews.length;i++){
      let svs = showViews[i+1];
      if(this.state.positionVal==i && typeof(svs) != undefined){showView = svs}
    }
    try{
      let {mainPageItem,mainStacks} = this.props.location.params;
      let mainStacksTemp = mainStacks;
      this.props.location.params.mainStacks = null;
      switch(mainPageItem){
        case 'ShopUpdateActivity':showView = <ShopUpdateActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'AddOwnerBatchActivity':showView = <AddOwnerBatchActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'AddSelfBatchActivity':showView = <AddSelfBatchActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'AddQuarrelBatchActivity':showView = <AddQuarrelBatchActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'ProcureOrdersActivity':showView = <ProcureOrdersActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WarehouseOutEntActivity':showView = <WarehouseOutEntActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CountsOrdersActivity':showView = <CountsOrdersActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CargoSonsAddActivity':showView = <CargoSonsAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'ReceivedSendActivity':showView = <ReceivedSendActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CargoAddCommodityActivity':showView = <CargoAddCommodityActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CargoSettlesActivity':showView = <CargoSettlesActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CargoSonsActivity':showView = <CargoSonsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WarehouseOutEntAddActivity':showView = <WarehouseOutEntAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'ReceivedSendAddActivity':showView = <ReceivedSendAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'DeleteContactActivity':showView = <DeleteContactActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CustomerAddActivity':showView = <CustomerAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OwnerAddActivity':showView = <OwnerAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'SupplierAddActivity':showView = <SupplierAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CompanyAddActivity':showView = <CompanyAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CustomerUpdateActivity':showView = <CustomerUpdateActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OwnerUpdateActivity':showView = <OwnerUpdateActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'SupplierUpdateActivity':showView = <SupplierUpdateActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CompanyUpdateActivity':showView = <CompanyUpdateActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WorkerUpdateActivity':showView = <WorkerUpdateActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'BusinessDetailsActivityCustomer':showView = <BusinessDetailsActivityCustomer context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'BusinessDetailsActivityOwner':showView = <BusinessDetailsActivityOwner context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'BusinessDetailsActivitySupplier':showView = <BusinessDetailsActivitySupplier context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'BusinessDetailsActivityWorker':showView = <BusinessDetailsActivityWorker context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'AuthoritySettingActivity':showView = <AuthoritySettingActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'ProcessOrderAddActivity':showView = <ProcessOrderAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'ProcessOrdersActivity':showView = <ProcessOrdersActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'ProcessOrderSaveActivity':showView = <ProcessOrderSaveActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'ProcessOrderInfoActivity':showView = <ProcessOrderInfoActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'DebtCollectActivity':showView = <DebtCollectActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CountsOrderAddActivity':showView = <CountsOrderAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'DateSettleActivity':showView = <DateSettleActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'DateCollectExpendActivity':showView = <DateCollectExpendActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'DebtCustomerActivity':showView = <DebtCustomerActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CollectPayAccountsActivity':showView = <CollectPayAccountsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OwnerSaleStatisticsActivity':showView = <OwnerSaleStatisticsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OwnerSaleStatistics':showView = <OwnerSaleStatistics context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'SaleDateActivity':showView = <SaleDateActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OwnerSettleSummaryActivity':showView = <OwnerSettleSummaryActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CustomerAccountsDetailsActivity':showView = <CustomerAccountsDetailsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'SupplierAccountsDetailsActivity':showView = <SupplierAccountsDetailsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CustomerSaleSettleActivity':showView = <CustomerSaleSettleActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'SaleProfitActivity':showView = <SaleProfitActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'SaleStatisticsActivity':showView = <SaleStatisticsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'SaleProfitLossActivity':showView = <SaleProfitLossActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'ShopProfitLossActivity':showView = <ShopProfitLossActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'ProcessStatisticsActivity':showView = <ProcessStatisticsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'InOutWarehouseActivity':showView = <InOutWarehouseActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OrdersListActivity':showView = <OrdersListActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OrdersWarehouseActivity':showView = <OrdersWarehouseActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OrdersWarehousesActivity':showView = <OrdersWarehousesActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'SaleTimingActivity':showView = <SaleTimingActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'PurchaseStatisticsActivity':showView = <PurchaseStatisticsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WorkerSelfSortActivity':showView = <WorkerSelfSortActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'BuyerSortActivity':showView = <BuyerSortActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WholesaleAddActivity':showView = <WholesaleAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OrdersAddActivity':showView = <OrdersAddActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'OrdersSaveActivity':showView = <OrdersSaveActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WholesaleAddCargoActivity':showView = <WholesaleAddCargoActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WholesaleAddsActivity':showView = <WholesaleAddsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'BackAddWholesaleActivity':showView = <BackAddWholesaleActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WholesaleSaveActivity':showView = <WholesaleSaveActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WholesaleSavesActivity':showView = <WholesaleSavesActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'WholesaleUpdateActivity':showView = <WholesaleUpdateActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CargoTransferOwnerActivity':showView = <CargoTransferOwnerActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CargoTransferSelfActivity':showView = <CargoTransferSelfActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'CargoTransferQuarrelActivity':showView = <CargoTransferQuarrelActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'BuyerStatisticsActivity':showView = <BuyerStatisticsActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        case 'SaleReportDailyActivity':showView = <SaleReportDailyActivity context={this} navigationStacks={mainStacksTemp}/>;break;
        default:break;
      }
      this.props.location.params.mainPageItem = '';
    }catch(error){}
    return (<div style={{width:window.screen.width,height:document.body.clientHeight}}>
      <TabTop viewHeight={arithUtil.ProportionTo(document.body.clientHeight,0.1)} context={this}/>
      <div style={{width:window.screen.width,height:arithUtil.ProportionTo(document.body.clientHeight,0.9),display:'flex',flexDirection:'row',flexWrap:'nowrap'}}>
          <TabBarBn viewHeight={arithUtil.ProportionTo(document.body.clientHeight,0.9)} changePages={(positionVal)=>{this.setState({ positionVal: positionVal });}}/>
          <div style={{width:0,height:arithUtil.ProportionTo(document.body.clientHeight,0.9),display:'flex',flexGrow:1,backgroundColor:'#ECECEC',overflow:'hidden'}}>{showView}</div>
      </div>
    </div>);
  }
}

const styles = {};