import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import OwnerCargoCommodityEditView from './OwnerCargoCommodityEditView';
import OwnerExpendAddView from './OwnerExpendAddView';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

export default class TabRecept extends Component{
  constructor(props){
    super(props);
    this.state={
      weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
      tabIndex:0,//0stringUtil.language(730)，1stringUtil.language(1053)车次
      rightShowIndex:-1,//stringUtil.language(730)：-1stringUtil.language(732)、0代销、1stringUtil.language(1051)、2炒货
      nWmode:0,//0stringUtil.language(1021)，1stringUtil.language(1022)，2stringUtil.language(432)
      searchs:{
          keySearch:'',
          targetId:-1,
          warehouseId:-1,
      },
      cargoTotalInfo:{},
      warehousesAll:[],
      personsAll:[],
      linesAll:[],
      searchsCargoFinish:{
          cargoMode:-1,
          targetId:-1,
          cargoNo:'',
          startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
          endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
      },
      currCargoItem:{},
      delShowPop:false,
      quashTransferShowPop:false,
      cargoFinishShowPop:false,finishShowPopIndex:0,
      cargoNoFinishShowPop:false,
      cargoCommodityPriceShowPop:false,
      ownerCargosData: [],ownerCargosDataTemp:[],
      isRefreshingFinish: false,
      isLoadMoreFinish: false,
      cargoComEditShowPop:false,addShowPopIndex:0,
      editExpendShowPop:false,
      ownerExpendAddShowPop:false,
      totalNum:'',//总记录数
      currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
      totalPage:'',//总{stringUtil.language(1199)}数
      totalNumFinish:'',//总记录数
      currentPageFinish: 1,
      totalPageFinish:'',//总{stringUtil.language(1199)}数
      ownerCargosDataFinish: [],
      warehouseCommodityItemAll:[],
      warehouseCommodityItemData:[],
      cargoFinishItem:{},
    };
    if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
  }
  componentDidMount(){
    this.initCargoTotalInfo();
    this.initWarehouseCommodityItemData();
    this.getNowDataList();
    this.getFinishDataList();
    stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.state.warehousesAll= successResponse.data;
            this.state.warehousesAll.unshift({id:-1,name:stringUtil.language(453)});
            this.setState({warehousesAll:this.state.warehousesAll});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            let ownersAll = successResponse.data;
            this.state.personsAll = [];
            this.state.personsAll.unshift({targetType:-1,targetId:-1,noteName:stringUtil.language(425)});
            for(let i=0;i<ownersAll.length;i++){
                if (ownersAll[i].isDel==0) {
                    this.state.personsAll.push({targetType:0,targetId:ownersAll[i].id,noteName:ownersAll[i].noteName});
                }
            }
            this.state.personsAll.push({targetType:1,targetId:-1,noteName:stringUtil.language(426)});
            stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
                if (successResponse.status==200) {
                    let workersAll = successResponse.data;
                    for(let i=0;i<workersAll.length;i++){
                        if (workersAll[i].state==0) {
                            this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                        }
                    }
                    this.setState({personsAll:this.state.personsAll});
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    stringUtil.httpRequest(urlUtil.BASIC_OWNER_LINE_WORKER_URL,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
          this.state.linesAll = successResponse.data;
          this.state.linesAll.unshift({targetType:-1,targetId:-1,name:stringUtil.language(517)});
          this.setState({linesAll:this.state.linesAll});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initCargoTotalInfo(){
    stringUtil.httpRequest(urlUtil.SALE_STOCK_CARGO_URL,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.setState({cargoTotalInfo:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initWarehouseCommodityItemData(){
      stringUtil.httpRequest(urlUtil.SALE_STOCK_COMMODITY_URL,{
          warehouseId:this.state.searchs.warehouseId,
          cargoId:''
      },['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.warehouseCommodityItemAll = successResponse.data;
              this.searchCommoditys();
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  searchCommoditys(){
      this.state.warehouseCommodityItemData = [];
      for(let i=0;i<this.state.warehouseCommodityItemAll.length;i++){
          let commodityItem = this.state.warehouseCommodityItemAll[i];
          if (commodityItem.commodityName.indexOf(this.state.searchs.keySearch)!=-1||commodityItem.cargoNo.indexOf(this.state.searchs.keySearch)!=-1||(commodityItem.commodityCode?commodityItem.commodityCode:'').indexOf(this.state.searchs.keySearch)!=-1) {
              if (this.state.searchs.targetId==null || this.state.searchs.targetId==-1 || (this.state.searchs.targetId==0&&commodityItem.targetType==1) || (commodityItem.targetType!=1&&commodityItem.targetId==this.state.searchs.targetId)) {//targetType：1stringUtil.language(426)，2stringUtil.language(411)
                  this.state.warehouseCommodityItemData.push(commodityItem);
              }
          }
      }
      this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemData});
  }
  getNowDataList(){
    stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
      state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
      warehouseId:-1,
      cargoMode:-1,
      targetId:-1,
      currPage:this.state.currentPage-1,
      pageSize:20
    },['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        let ownerCargosResultObj = successResponse.data;
        this.state.totalNum = ownerCargosResultObj.count;
        this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
        this.state.ownerCargosData = JSON.parse(JSON.stringify(ownerCargosResultObj.items));
        this.state.ownerCargosDataTemp = JSON.parse(JSON.stringify(ownerCargosResultObj.items));
        this.setState({
          totalNum:this.state.totalNum,
          totalPage:this.state.totalPage,
          ownerCargosData:this.state.ownerCargosData
        });
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  goPrevClick(){//上一步
		var _this = this;
		let cur = this.state.currentPage;
		if(cur > 1) _this.pageClick( cur - 1);
	}
	goNext(){//下一步
		var _this = this;
		let cur = _this.state.currentPage;
		if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
	}
	pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
		let _this = this;
		if(pageNum != _this.state.currentPage){
			_this.state.currentPage = pageNum
		}
		this.getNowDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
	}
  getFinishDataList(){
    stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
      state:2,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
      warehouseId:-1,
      cargoMode:this.state.searchsCargoFinish.cargoMode,
      targetId:this.state.searchsCargoFinish.targetId,
      cargoNo:this.state.searchsCargoFinish.cargoNo,
      startTime:arithUtil.settleSub(this.state.searchsCargoFinish.startTime),
      endTime:arithUtil.settleSub(this.state.searchsCargoFinish.endTime),
      currPage:this.state.currentPageFinish-1,
      pageSize:20
    },['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        let ownerCargosResultObj = successResponse.data;
        this.state.totalNumFinish = ownerCargosResultObj.count;
        this.state.totalPageFinish = Math.ceil( this.state.totalNumFinish / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数 = 总记录数 / 每{stringUtil.language(1199)}显示的条数
        this.state.ownerCargosDataFinish = JSON.parse(JSON.stringify(ownerCargosResultObj.items));
        this.setState({
          totalNumFinish:this.state.totalNumFinish,
          totalPageFinish:this.state.totalPageFinish,
          ownerCargosDataFinish:this.state.ownerCargosDataFinish
        });
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  goPrevClickFinish(){//上一步
		var _this = this;
		let cur = this.state.currentPageFinish;
		if(cur > 1) _this.pageClickFinish( cur - 1);
	}
	goNextFinish(){//下一步
		var _this = this;
		let cur = _this.state.currentPageFinish;
		if(cur < _this.state.totalPageFinish) _this.pageClickFinish(cur + 1);
	}
	pageClickFinish(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
		let _this = this;
		if(pageNum != _this.state.currentPageFinish){
			_this.state.currentPageFinish = pageNum
		}
		this.getFinishDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
	}
  getWidthSize(scal,sourScal){
    let result;
    if(sourScal!=null)result=((100).div(scal+sourScal.length,1))+'%';
    else result=((100).div(scal,1))+'%';
    return result;
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'row',padding:'0.5%'}}>
        <div style={{width:'30%',height:'100%',marginRight:arithUtil.pxToDp(4),backgroundColor:'#FFF',borderRadius:5}}>
          <div style={{height:'7%',borderTopLeftRadius:5,borderTopRightRadius:5,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <span style={arithUtil.stylesAdd(styles.roleTabItem,this.state.tabIndex==0?{borderTopLeftRadius:5,backgroundColor:'#52A056'}:{borderTopLeftRadius:5,color:'#C0C0C0'})} onClick={()=>{
              this.state.tabIndex = 0;
              this.setState({tabIndex:this.state.tabIndex});
            }}>{stringUtil.language(730)}</span>
            <span style={arithUtil.stylesAdd(styles.roleTabItem,this.state.tabIndex==1?{borderTopRightRadius:5,backgroundColor:'#52A056'}:{borderTopRightRadius:5,color:'#C0C0C0'})} onClick={()=>{
              this.state.tabIndex = 1;
              this.setState({tabIndex:this.state.tabIndex});
            }}>{stringUtil.language(731)}</span>
          </div>
          {this.state.tabIndex==0?<div style={{height:'85%'}}>
            <div style={arithUtil.stylesAdd(styles.roleItem,{height:'14%'})} onClick={()=>{
              this.setState({rightShowIndex:-1});
            }}>
              <i className="iconfontIndexCss" style={{fontSize:40,color:'#d2855d'}}>&#xe68d;</i>
              <div>
                <span style={{fontSize:20,marginLeft:arithUtil.pxToDp(15)}}>{stringUtil.language(732)}</span><br/>
                <span style={{fontSize:12,color:'#D2855D',marginLeft:arithUtil.pxToDp(15)}}>{stringUtil.language(1182)}{this.state.cargoTotalInfo.ownerNum}{stringUtil.language(988)}，{this.state.cargoTotalInfo.cargoNum}{stringUtil.language(1176)}，{this.state.cargoTotalInfo.commodityNum}{stringUtil.language(989)}</span>
              </div>
            </div>
            <div style={{height:this.state.totalPage>1?'71%':'81%',overflow:'auto'}}>
              {this.state.ownerCargosDataTemp.map((item,key)=>{
									return(<div style={styles.roleItem} key={key} onClick={()=>{
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+item.id,null,['key','get',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          let cargoInfo= successResponse.data;
                          this.state.currCargoItem = JSON.parse(JSON.stringify(cargoInfo));
                          this.setState({rightShowIndex:this.state.currCargoItem.cargoMode});//-1库存 0代销 1stringUtil.language(1051) 2炒货
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }}>{item.cargoMode==0?<i className="iconfontIndexCss" style={{fontSize:30,color:'#53B8DB',marginLeft:5}}>&#xe60e;</i>:
                        item.cargoMode==1?<i className="iconfontIndexCss" style={{fontSize:30,color:'#53B8DB',marginLeft:5}}>&#xe714;</i>:
                        <i className="iconfontIndexCss" style={{fontSize:30,color:'#53B8DB',marginLeft:5}}>&#xe658;</i>}
                    <div>
                      <span style={{fontSize:18,fontWeight:'bold',marginLeft:20}}>{item.cargoMode==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,10):''):stringUtil.subStr(item.targetName,12)}</span><br/>
                      <span style={{fontSize:12,color:'#A9A9A9',marginLeft:20}}>{stringUtil.language(1175)}{item.cargoNo}{stringUtil.language(1176)}{item.deliveryMode==1?<span style={{fontSize:7,color:'#53B8DB',marginLeft:10}}>{stringUtil.language(733)}</span>:''}</span>
                    </div>
                  </div>)
							})}
            </div>
            <div style={{height:'15%',display:'flex',flexDirection:'row',justifyContent:'space-between',paddingLeft:5,paddingRight:5}}>
              <input style={{height:35,backgroundColor:'transparent',borderBottomStyle:'solid',borderBottomColor:'#808080',borderBottomWidth:1}} placeholder={stringUtil.language(76)} onChange={(event)=>{//20220603stringUtil.language(1027)在此加这样的stringUtil.language(1034)
                this.state.ownerCargosDataTemp=[];
                for(let i=0;i<this.state.ownerCargosData.length;i++){let item=this.state.ownerCargosData[i];
                  if((item.cargoMode==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,10):''):stringUtil.subStr(item.targetName,12)).indexOf(event.target.value)!=-1){
                    this.state.ownerCargosDataTemp.push(item);
                  }
                }this.setState({ownerCargosDataTemp:this.state.ownerCargosDataTemp});
              }}/>
              {this.state.totalPage>1?<Pagination total={this.state.totalNum}
                  current={this.state.currentPage}
                  totalPage={this.state.totalPage}
                  pageClick={this.pageClick.bind(this)}
                  goPrev={this.goPrevClick.bind(this)}
                  goNext={this.goNext.bind(this)}/>:''}
            </div>
          </div>:<div/>}
          {this.state.tabIndex==1?<div style={{height:'85%'}}>
            <div style={{width:'100%',height:'12%',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC'}}>
              <div style={{width:'100%',height:'50%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:5,paddingRight:5}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <div style={{height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:1,borderColor:'#A9A9A9',borderRadius:3}}>
                    <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsCargoFinish.startTime,1)),'Y-M-D'))} onChange={(date) => {
                        this.state.searchsCargoFinish.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                        this.setState({ searchsCargoFinish: this.state.searchsCargoFinish })
                    }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;
                  <div style={{height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:1,borderColor:'#A9A9A9',borderRadius:3}}>
                    <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsCargoFinish.endTime,1)),'Y-M-D'))} onChange={(date) => {
                        this.state.searchsCargoFinish.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                        this.setState({ searchsCargoFinish: this.state.searchsCargoFinish })
                    }} /></div>
                </div>
              </div>
              <div style={{height:'50%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:5,paddingRight:5}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <Select showSearch defaultValue={stringUtil.language(517)} style={{width:150,height:30,fontSize:10}} onChange={(event)=>{
                    this.state.searchsCargoFinish.targetId = JSON.parse(event).targetId;
                    this.state.searchsCargoFinish.cargoMode = JSON.parse(event).targetType;
                  }}>
                    {this.state.linesAll.map((item,key)=>{
                      return(<option value={JSON.stringify(item)} key={key}>{item.targetType==1?stringUtil.language(426)+(item.name?'_'+item.name:''):item.name}</option>)
                    })}
                  </Select>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:10}}>
                    <span style={{fontSize:12}}>{stringUtil.language(1084)}:</span>
                    <input style={{height:'100%',fontSize:12,backgroundColor:'transparent'}} placeholder={stringUtil.language(77)} onChange={(event)=>{
                      this.state.searchsCargoFinish.cargoNo = event.target.value;
                    }}/>
                  </div>
                </div>
                <div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center',cursor:'pointer'}} onClick={()=>{this.getFinishDataList();}}>
                  <span style={{color:'#52A056',borderBottomStyle:'solid',borderBottomColor:'#52A056',borderBottomWidth:1}}>{stringUtil.language(1034)}</span>
                </div>
              </div>
            </div>
            <div style={{height:this.state.totalPageFinish>1?'78%':'88%',overflow:'auto'}}>
              {this.state.ownerCargosDataFinish.map((item,key)=>{
									return(<div style={styles.roleItem} key={key} onClick={()=>{
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+item.id,null,['key','get',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          let cargoInfo= successResponse.data;
                          this.state.currCargoItem = JSON.parse(JSON.stringify(cargoInfo));
                          this.setState({rightShowIndex:this.state.currCargoItem.cargoMode});//-1库存 0代销 1stringUtil.language(1051) 2炒货
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }}>{item.cargoMode==0?<i className="iconfontIndexCss" style={{fontSize:30,color:'#53B8DB',marginLeft:5}}>&#xe60e;</i>:
                        item.cargoMode==1?<i className="iconfontIndexCss" style={{fontSize:30,color:'#53B8DB',marginLeft:5}}>&#xe714;</i>:
                        <i className="iconfontIndexCss" style={{fontSize:30,color:'#53B8DB',marginLeft:5}}>&#xe658;</i>}
                    <div>
                      <span style={{fontSize:18,fontWeight:'bold',marginLeft:20}}>{item.cargoMode==1?stringUtil.language(426)+(item.targetName?'_'+item.targetName:''):stringUtil.subStr(item.targetName,14)}</span><br/>
                      <span style={{fontSize:12,color:'#A9A9A9',marginLeft:20}}>{stringUtil.language(1175)}{item.cargoNo}{stringUtil.language(1176)}{item.deliveryMode==1?<span style={{fontSize:7,color:'#53B8DB',marginLeft:10}}>{stringUtil.language(733)}</span>:''}</span>
                    </div>
                  </div>)
							})}
            </div>
            {this.state.totalPageFinish>1?<div style={{height:'10%'}}>
              <Pagination total={this.state.totalNumFinish}
                current={this.state.currentPageFinish}
                totalPage={this.state.totalPageFinish}
                pageClick={this.pageClickFinish.bind(this)}
                goPrev={this.goPrevClickFinish.bind(this)}
                goNext={this.goNextFinish.bind(this)}/>
            </div>:''}
          </div>:<div/>}
          {otherUtil.authorityFindCode("020102")!=null?<div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
              <span style={{width:'30%',height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#D2855D',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                  this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'AddOwnerBatchActivity',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(734),navigatePage:'AddOwnerBatchActivity',paramsPage:stringUtil.language(734)+'参数'}
                  ]}});
                }}>{stringUtil.language(734)}</span>
              <span style={{width:'30%',height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#52A056',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                  this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'AddSelfBatchActivity',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(1051),navigatePage:'AddSelfBatchActivity',paramsPage:stringUtil.language(1051)+stringUtil.language(1044)+'参数'}
                  ]}});
                }}>{stringUtil.language(1051)}</span>
              {(()=>{
                if (localStorage.getItem("currentStallItem")!=null && JSON.parse(localStorage.getItem("currentStallItem")).shopRole==1) {
                  return(<span style={{width:'30%',height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#74C676',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'AddQuarrelBatchActivity',mainStacks:[
                      {title:stringUtil.language(1128),navigatePage:''},
                      {title:stringUtil.language(438),navigatePage:'AddQuarrelBatchActivity',paramsPage:stringUtil.language(1051)}
                    ]}});
                  }}>炒货</span>);
                }
              })()}
          </div>:<div style={{position:'absolute'}}/>}
        </div>
        <div style={{width:'70%',height:'100%',backgroundColor:'#FFF',borderRadius:5}}>
          {this.state.rightShowIndex==-1?<div style={{width:'100%',height:'100%'}}>{/*-1库存 0代销 1stringUtil.language(1051) 2炒货*/}
            <div style={{height:'7%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
              <select style={{width:200,height:30,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:16}} onChange={(event)=>{
                  this.state.searchs.warehouseId = JSON.parse(event.target.value).id;
                  this.initWarehouseCommodityItemData();
              }}>
                {this.state.warehousesAll.map((item,key)=>{
                  return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                })}
              </select>
              <select style={{width:250,height:30,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:16}} onChange={(event)=>{
                  this.state.searchs.targetId = JSON.parse(event.target.value).targetId;
                  if(JSON.parse(event.target.value).targetType==1)this.state.searchs.targetId = 0;
                  this.searchCommoditys();
              }}>
                {this.state.personsAll.map((item,key)=>{
                  return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                })}
              </select>
              <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:60,fontSize:16}}>{stringUtil.language(1020)}：</span>
                <input style={{height:'100%',fontSize:16}} placeholder={'输'+stringUtil.language(1195)+stringUtil.language(1020)+'或'+stringUtil.language(1084)+stringUtil.language(1034)+'..'} onChange={(event)=>{
                  this.state.searchs.keySearch = event.target.value;
                  this.searchCommoditys();
                }}/>
              </div>
            </div>
            <div style={{height:'85%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
              <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'15%',height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'15%',height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1049)}</span>
                <span style={{width:'17%',height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1084)}</span>
                <span style={{width:'18%',height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1064)}</span>
                <span style={{width:'10%',height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(990)}</span>
                <span style={{width:'11%',height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(991)}</span>
                <span style={{width:'14%',height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1022)}</span>
              </div>
              {(()=>{this.state.totalInfoWh={number:0,numberUnitScale:0,weight:0};})()}
              {this.state.warehouseCommodityItemData.map((item,key)=>{
                this.state.totalInfoWh.number=this.state.totalInfoWh.number+item.number;
                this.state.totalInfoWh.numberUnitScale=this.state.totalInfoWh.numberUnitScale+item.number.mul(item.unitScale);
                this.state.totalInfoWh.weight=(this.state.totalInfoWh.weight).add(item.weight);
                return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                  <span style={{width:'15%',height:'100%',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                  <span style={{width:'15%',height:'100%',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{item.targetType==1?stringUtil.language(426):stringUtil.subStr(item.targetName,9)}</span>
                  <span style={{width:'17%',height:'100%',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{item.cargoNo}</span>
                  <span style={{width:'18%',height:'100%',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.subStr(item.warehouseName,20)}</span>
                  <span style={{width:'10%',height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{item.number+(item.unitMaster?item.unitMaster:stringUtil.language(1173))}</span>
                  <span style={{width:'11%',height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{item.number.mul(item.unitScale)+(item.unitSlave?item.unitSlave:stringUtil.language(1173))}</span>
                  <span style={{width:'14%',height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                </div>)
              })}
              <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'15%',fontSize:16,color:'#D2855D'}}>{stringUtil.language(890)}</span>
                <span style={{width:'15%'}}></span>
                <span style={{width:'17%'}}></span>
                <span style={{width:'18%'}}></span>
                <span style={{width:'10%',fontSize:16,color:'#D2855D',textAlign:'center'}}>{this.state.totalInfoWh.number}</span>
                <span style={{width:'11%',fontSize:16,color:'#D2855D',textAlign:'center'}}>{this.state.totalInfoWh.numberUnitScale}</span>
                <span style={{width:'14%',fontSize:16,color:'#D2855D',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfoWh.weight,this.state.weightMode)}</span>
              </div>
            </div>
            <div style={{height:'8%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
              {otherUtil.authorityFindCode("020201")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcureOrdersActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(735),navigatePage:'ProcureOrdersActivity',paramsPage:'abcdef参数'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#DD9C9C'}}>&#xe613;</i><span style={{color:'#24984D'}}>{stringUtil.language(735)}</span>
              </div>:<div style={{position:'absolute'}}/>}
              {otherUtil.authorityFindCode("020501")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WarehouseOutEntActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(736),navigatePage:'WarehouseOutEntActivity',paramsPage:stringUtil.language(736)}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe62a;</i><span style={{color:'#24984D'}}>{stringUtil.language(736)}</span>
              </div>:<div style={{position:'absolute'}}/>}
              {otherUtil.authorityFindCode("020701")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CountsOrdersActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(737),navigatePage:'CountsOrdersActivity',paramsPage:stringUtil.language(737)}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe655;</i><span style={{color:'#24984D'}}>{stringUtil.language(737)}</span>
              </div>:<div style={{position:'absolute'}}/>}
              {otherUtil.authorityFindCode("020601")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ReceivedSendActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(599),navigatePage:'ReceivedSendActivity',paramsPage:stringUtil.language(599)}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#8194AA'}}>&#xe645;</i><span style={{color:'#24984D'}}>{stringUtil.language(599)}</span>
              </div>:<div style={{position:'absolute'}}/>}
            </div>
          </div>:<div/>}
          {(this.state.rightShowIndex==0&&this.state.currCargoItem.state==1)?<div style={{width:'100%',height:'100%'}}>
            <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(856)}{this.state.currCargoItem.cargoMode==0?this.state.currCargoItem.targetName:''} {stringUtil.language(1175)}{this.state.currCargoItem.cargoNo}{stringUtil.language(1176)}</span>
                </div>
                <span style={{color:'#FF8C00'}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.receiveTime),'Y-M-D h:m:s')}</span>
              </div>
              {this.state.currCargoItem.carNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <span style={{fontSize:11}}>{stringUtil.language(857)}{this.state.currCargoItem.carNumber}</span>
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(430)}{this.state.currCargoItem.workerId==0?stringUtil.language(431):this.state.currCargoItem.workerName}</span>
                  {(()=>{if(this.state.currCargoItem.detailed==null&&this.state.nWmode==2)this.state.nWmode=0;})()}
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:0});
                  }}>{this.state.nWmode==0?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1021)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1021)}</i>}</div>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:1});
                  }}>{this.state.nWmode==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1022)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1022)}</i>}</div>
                  {this.state.currCargoItem.detailed?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:2});
                  }}>{this.state.nWmode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(432)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(432)}</i>}</div>:''}
                </div>
                <span>{this.state.currCargoItem.warehouseName}</span>
              </div>
            </div>
            <div style={{height:'82%',display:'flex',flexDirection:'column'}}>
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
              {(()=>{this.state.cargoInfoTotal={sendNumber:0,transferNumber:0,processNumber:0,purchaseNumber:0,stockInOutNumber:0,saleNumber:0,balanceNumber:0,
                  sendWeight:0,transferWeight:0,processWeight:0,purchaseWeight:0,stockInOutWeight:0,saleWeight:0,balanceWeight:0,purchaseGoodsMoney:0,saleGoodsMoney:0}})()}
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1020)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1059)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1068)}</span>
                {this.state.currCargoItem.diffGoodsMoney!=0?<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(538)}</span>:""}
                {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((item,key)=>{
                  this.state.cargoInfoTotal['costs'+item.costClauseId]=0;
                  return(<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={key}>{stringUtil.subStr(item.costClauseName,5)}</span>);
                }):''}
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1057)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1054)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1055)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1058)}</span>
              </div>:''}
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.commodities.map((item,key)=>{
                  this.state.cargoInfoTotal.sendNumber=(this.state.cargoInfoTotal.sendNumber)+(item.sendNumber);
                  this.state.cargoInfoTotal.transferNumber=(this.state.cargoInfoTotal.transferNumber)+(item.transferNumber);
                  this.state.cargoInfoTotal.processNumber=(this.state.cargoInfoTotal.processNumber)+(item.processNumber);
                  this.state.cargoInfoTotal.purchaseNumber=(this.state.cargoInfoTotal.purchaseNumber)+(item.purchaseNumber);
                  this.state.cargoInfoTotal.stockInOutNumber=(this.state.cargoInfoTotal.stockInOutNumber)+(item.stockInOutNumber);
                  this.state.cargoInfoTotal.saleNumber=(this.state.cargoInfoTotal.saleNumber)+(item.saleNumber);
                  this.state.cargoInfoTotal.balanceNumber=(this.state.cargoInfoTotal.balanceNumber)+(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
                  this.state.cargoInfoTotal.sendWeight=(this.state.cargoInfoTotal.sendWeight).add(item.sendWeight);
                  this.state.cargoInfoTotal.transferWeight=(this.state.cargoInfoTotal.transferWeight).add(item.transferWeight);
                  this.state.cargoInfoTotal.processWeight=(this.state.cargoInfoTotal.processWeight).add(item.processWeight);
                  this.state.cargoInfoTotal.purchaseWeight=(this.state.cargoInfoTotal.purchaseWeight).add(item.purchaseWeight);
                  this.state.cargoInfoTotal.stockInOutWeight=(this.state.cargoInfoTotal.stockInOutWeight).add(item.stockInOutWeight);
                  this.state.cargoInfoTotal.saleWeight=(this.state.cargoInfoTotal.saleWeight).add(item.saleWeight);
                  this.state.cargoInfoTotal.balanceWeight=(this.state.cargoInfoTotal.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1));
                  this.state.cargoInfoTotal.purchaseGoodsMoney=(this.state.cargoInfoTotal.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
                  this.state.cargoInfoTotal.saleGoodsMoney=(this.state.cargoInfoTotal.saleGoodsMoney).add(item.saleGoodsMoney.sub(item.diffGoodsMoney));
                  return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                    this.state.paramsInfo={cargoId:this.state.currCargoItem.id,currBatchCommodityItem:JSON.stringify(item)};
                    this.setState({addShowPopIndex:this.state.addShowPopIndex+1,cargoComEditShowPop:!this.state.cargoComEditShowPop});
                  }}>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#52A056',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.saleNumber:arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                    {/* <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(arithUtil.fenToYuan(item.saleGoodsMoney.sub(item.diffGoodsMoney))).div(item.saleNumber,2):(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</span> */}
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(item.saleGoodsMoney.sub(item.diffGoodsMoney))}</span>
                    {this.state.currCargoItem.diffGoodsMoney!=0?<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(item.diffGoodsMoney)}</span>:""}
                    {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                      let moneyTemp=0;
                      for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
                        if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
                      }
                      this.state.cargoInfoTotal['costs'+itemb.costClauseId]=(this.state.cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
                      if(moneyTemp)return(<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>{arithUtil.fenToYuan(moneyTemp)}</span>);
                      else return(<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>0</span>);
                    }):''}
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.sendNumber:arithUtil.keToWeight(item.sendWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</span>
                  </div>);
                })}
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{stringUtil.language(890)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.saleNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.saleWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}></span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.saleGoodsMoney)}</span>
                  {this.state.currCargoItem.diffGoodsMoney!=0?<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.currCargoItem.diffGoodsMoney)}</span>:""}
                  {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                    return(<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}} key={keyb}>{arithUtil.fenToYuan(this.state.cargoInfoTotal['costs'+itemb.costClauseId])}</span>);
                  }):''}
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.stockInOutNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.stockInOutWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.sendNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.sendWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.transferNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.transferWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.balanceNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.balanceWeight,this.state.weightMode)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'17%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <div style={{width:'83%',display:'flex',flexDirection:'row'}}>
                  <span style={{width:'23%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                  <span style={{width:'11%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                  <span style={{width:'14%',color:'#808080',textAlign:'center'}}>{stringUtil.language(962)}</span>
                  <span style={{width:'22%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.detailed?this.state.currCargoItem.detailed.map((item,key)=>{
                  let totalItemInfo={number:0,weight:0,money:0};let totalItemInfoAvg={number:0,weight:0,moneyNumber:0,moneyWeight:0};
                  if(item.items==null) item.items=[];
                  for(let i=0;i<item.items.length;i++){
                    totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
                    totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
                    item.items[i].money = item.items[i].goodsMoney;
                    for(let j=0;j<item.items[i].costs.length;j++){
                      item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
                    }
                    totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
                    if(item.items[i].mode==1){
                      totalItemInfoAvg.number = (totalItemInfoAvg.number).add(item.items[i].number);
                      totalItemInfoAvg.moneyNumber = (totalItemInfoAvg.moneyNumber).add(item.items[i].goodsMoney);
                    }
                    if(item.items[i].mode==0){
                      totalItemInfoAvg.weight = (totalItemInfoAvg.weight).add(item.items[i].weight);
                      totalItemInfoAvg.moneyWeight = (totalItemInfoAvg.moneyWeight).add(item.items[i].goodsMoney);
                    }
                  }
                  return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                    <span style={{width:'17%',color:'#000',fontSize:16,paddingTop:7}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <div style={{width:'83%',display:'flex',flexDirection:'column'}}>
                      <span style={{height:30,color:'#52A056',fontSize:16,fontWeight:'bold',paddingTop:7}}>
                        {/* ，{arithUtil.fenToYuan(totalItemInfo.money.div(totalItemInfo.number))}{stringUtil.currency(1)}/stringUtil.language(1173) */}
                        {stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money)}{stringUtil.currency(1)}
                        {totalItemInfoAvg.number?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyNumber).div(totalItemInfoAvg.number,2)+stringUtil.currency(1)+'/'+stringUtil.language(1173):''}{totalItemInfoAvg.weight?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyWeight).div(arithUtil.keToWeight(totalItemInfoAvg.weight,this.state.weightMode),2)+stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):''}
                        {totalItemInfo.number?'，'+arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode).div(totalItemInfo.number,2)+(this.state.weightMode?stringUtil.language(1172)+'/'+stringUtil.language(1173):stringUtil.language(1023)+'/'+stringUtil.language(1173)):''}
                      </span>
                      {item.items.map((itemb,keyb)=>{
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                          <span style={{width:'23%',color:'#808080',fontSize:16}}>{itemb.customerName}</span>
                          <span style={{width:'11%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.number}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                          <span style={{width:'14%',color:'#808080',fontSize:16,textAlign:'center'}}>{(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</span>
                          <span style={{width:'22%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                        </div>);
                      })}
                    </div>
                  </div>)
                }):''}
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                {this.state.currCargoItem.advanceExpends?this.state.currCargoItem.advanceExpends.map((item,key)=>{
                    return(<span style={{width:'33%',color:item.unPay>0?'#F00':'#696969',fontSize:16,cursor:'pointer'}} key={key} onClick={()=>{
                      this.state.keyTagVal = key;
                      this.setState({editExpendShowPop:true});
                    }}>{item.clauseName+'：'+arithUtil.fenToYuan(item.money)}{stringUtil.currency(1)}</span>);
                }):''}
              </div>
              {this.state.currCargoItem.receiveDescribe?<span style={{fontSize:12}}>{stringUtil.language(429)}:{this.state.currCargoItem.receiveDescribe}</span>:''}
            </div>
            <div style={{height:'8%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
              {this.state.currCargoItem.deliveryMode?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoSonsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1240),navigatePage:'CargoSonsActivity',paramsPage:{currCargoItem:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}}>&#xe6c6;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1240)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020102")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.state.currBatchItem=JSON.parse(JSON.stringify(this.state.currCargoItem));
                this.setState({cargoCommodityPriceShowPop:!this.state.cargoCommodityPriceShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe657;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(738)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020103")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoAddCommodityActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(739),navigatePage:'CargoAddCommodityActivity',paramsPage:{currCargoItem:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe601;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(739)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020104")!=null&&this.state.currCargoItem.transferId!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({quashTransferShowPop:!this.state.quashTransferShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#0000FF'}}>&#xe643;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(740)}</span>
              </div>:''}
              {this.state.currCargoItem.deliveryMode?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoSettlesActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(418),navigatePage:'CargoSettlesActivity',paramsPage:{currCargoItem:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}}>&#xe6c6;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1241)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020103")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                stringUtil.httpRequest(urlUtil.SALE_CARGO_DISCOUNT_URL,{targetId:this.state.currCargoItem.targetId,targetType:this.state.currCargoItem.cargoMode},['key','get',1],(successResponse)=>{
                  if (successResponse.status==200) {
                    this.state.cargoFinishItem={};
                    this.state.cargoFinishItem.discountMoney = successResponse.data;
                    if(this.state.cargoFinishItem.disburdenMode!=null&&this.state.cargoFinishItem.disburdenUnitPrice!=null){
                      if(this.state.cargoFinishItem.disburdenMode==0)this.state.cargoFinishItem.disburdenMoney=(this.state.cargoFinishItem.disburdenUnitPrice).round();
                      else if(this.state.cargoFinishItem.disburdenMode==1)this.state.cargoFinishItem.disburdenMoney=(this.state.cargoFinishItem.disburdenUnitPrice).mul(this.state.currCargoItem.saleNumber).round();
                      else if(this.state.cargoFinishItem.disburdenMode==2)this.state.cargoFinishItem.disburdenMoney=(this.state.cargoFinishItem.disburdenUnitPrice).mul(arithUtil.keToWeight(this.state.currCargoItem.saleWeight,0)).round();
                    }
                    if(this.state.cargoFinishItem.entrustMode!=null&&this.state.cargoFinishItem.entrustUnitPrice!=null){
                      if(this.state.cargoFinishItem.entrustMode==0)this.state.cargoFinishItem.entrustMoney=(this.state.cargoFinishItem.entrustUnitPrice).round();
                      else if(this.state.cargoFinishItem.entrustMode==1)this.state.cargoFinishItem.entrustMoney=(this.state.cargoFinishItem.entrustUnitPrice).mul(this.state.currCargoItem.saleNumber).round();
                      else if(this.state.cargoFinishItem.entrustMode==2)this.state.cargoFinishItem.entrustMoney=(this.state.cargoFinishItem.entrustUnitPrice).mul(arithUtil.keToWeight(this.state.currCargoItem.saleWeight,0)).round();
                      else if(this.state.cargoFinishItem.entrustMode==3){
                        let saleTotalMoney=this.state.currCargoItem.saleGoodsMoney;
                        if(this.state.currCargoItem.saleCosts){
                          for(let i=0;i<this.state.currCargoItem.saleCosts.length;i++){
                            saleTotalMoney=saleTotalMoney.add(this.state.currCargoItem.saleCosts[i].money);
                          }
                        }
                        this.state.cargoFinishItem.entrustMoney=saleTotalMoney.mul((this.state.cargoFinishItem.entrustUnitPrice).div(100)).round();
                      }
                    }
                    this.setState({finishShowPopIndex:this.state.finishShowPopIndex+1,cargoFinishShowPop:!this.state.cargoFinishShowPop});
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe60c;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(741)}</span>
              </div>:<div/>}
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.getExcel(this.state.currCargoItem);
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe644;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1071)}Excel</span>
              </div>
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({cargoInfoPrint:this.state.currCargoItem},()=>{
                  const printArea = document.getElementById('printArea');
                  const iframe = document.createElement('IFRAME');
                  let doc = null;
                  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                  document.body.appendChild(iframe);
                  doc = iframe.contentWindow.document;
                  doc.write(printArea.innerHTML);
                  doc.close();
                  iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                  iframe.contentWindow.print();
                  if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                  this.setState({cargoInfoPrint:null});
                });
                //this.props.context.props.history.push({pathname:"/CargoSettleActivity",params:{cargoInfo:{id:this.state.currCargoItem.id}}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#8194AA'}}>&#xe867;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(745)}</span>
              </div>
              {otherUtil.authorityFindCode("020104")!=null?<div style={{width:70,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({delShowPop:!this.state.delShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00'}}>&#xe612;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1113)}</span>
              </div>:<div/>}
            </div>
          </div>:<div/>}
          {(this.state.rightShowIndex==1&&this.state.currCargoItem.state==1)?<div style={{width:'100%',height:'100%'}}>
            <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(856)}{stringUtil.language(426)}{this.state.currCargoItem.targetName?'_'+this.state.currCargoItem.targetName:''} {stringUtil.language(1175)}{this.state.currCargoItem.cargoNo}{stringUtil.language(1176)}</span>
                </div>
                <span style={{color:'#FF8C00'}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.receiveTime),'Y-M-D h:m:s')}</span>
              </div>
              {this.state.currCargoItem.carNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <span style={{fontSize:11}}>{stringUtil.language(857)}{this.state.currCargoItem.carNumber}</span>
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(430)}{this.state.currCargoItem.workerId==0?stringUtil.language(431):this.state.currCargoItem.workerName}</span>
                  {(()=>{if(this.state.currCargoItem.detailed==null&&this.state.nWmode==2)this.state.nWmode=0;})()}
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:0});
                  }}>{this.state.nWmode==0?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1021)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1021)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:1});
                  }}>{this.state.nWmode==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1022)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1022)}</i>}</div>
                  {this.state.currCargoItem.detailed?<div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:2});
                  }}>{this.state.nWmode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(432)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(432)}</i>}</div>:''}
                </div>
                <span>{this.state.currCargoItem.warehouseName}</span>
              </div>
            </div>
            <div style={{height:'82%',display:'flex',flexDirection:'column'}}>
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{flexGrow:1,overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}}>
                {(()=>{this.state.cargoInfoTotal={sendNumber:0,transferNumber:0,processNumber:0,purchaseNumber:0,stockInOutNumber:0,saleNumber:0,balanceNumber:0,sendWeight:0,transferWeight:0,processWeight:0,
                  purchaseWeight:0,stockInOutWeight:0,saleWeight:0,balanceWeight:0,purchaseGoodsMoney:0,saleGoodsMoney:0,zongchenben:0,saleTotalMoney:0,saleChenben:0,maoli:0,balanceChenben:0}})()}
                <div><div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                  {otherUtil.authorityFindCode("020104")!=null?<div style={{width:25}}/>:''}
                  <span style={{width:100,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1020)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(742)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(743)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(889)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(600)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1148)}</span>
                  {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((item,key)=>{
                    this.state.cargoInfoTotal['costs'+item.costClauseId]=0;
                    return(<span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={key}>{stringUtil.subStr(item.costClauseName,5)}</span>);
                  }):''}
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1056)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1057)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1055)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1044)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(947)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(559)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1149)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(744)}</span>
                </div>
                {this.state.currCargoItem.commodities.map((item,key)=>{
                  let zongchenben=(item.transferMoney).add(item.purchaseGoodsMoney).add(item.processNumber>0?item.processMoney:0);
                  if(item.purchaseCosts){
                    for(let i=0;i<item.purchaseCosts.length;i++){
                      zongchenben=zongchenben.add(item.purchaseCosts[i].money);
                    }
                  }
                  let saleTotalMoney=item.saleGoodsMoney;
                  if(item.saleCosts){
                    for(let i=0;i<item.saleCosts.length;i++){
                      saleTotalMoney=saleTotalMoney.add(item.saleCosts[i].money);
                    }
                  }
                  let danweichenbenN=(zongchenben).div(item.transferNumber+item.purchaseNumber+(item.processNumber>0?item.processNumber:0));//stringUtil.language(1021)的{stringUtil.language(559)}
                  zongchenben=zongchenben.add(item.processNumber<0?(item.processNumber).mul(danweichenbenN):0);
                  let yushu=item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber;
                  this.state.cargoInfoTotal.transferNumber = (this.state.cargoInfoTotal.transferNumber)+item.transferNumber;
                  this.state.cargoInfoTotal.transferWeight = (this.state.cargoInfoTotal.transferWeight)+item.transferWeight;
                  this.state.cargoInfoTotal.processNumber = (this.state.cargoInfoTotal.processNumber)+item.processNumber;
                  this.state.cargoInfoTotal.processWeight = (this.state.cargoInfoTotal.processWeight)+item.processWeight;
                  this.state.cargoInfoTotal.purchaseNumber = (this.state.cargoInfoTotal.purchaseNumber)+item.purchaseNumber;
                  this.state.cargoInfoTotal.purchaseWeight = (this.state.cargoInfoTotal.purchaseWeight)+item.purchaseWeight;
                  this.state.cargoInfoTotal.stockInOutNumber = (this.state.cargoInfoTotal.stockInOutNumber)+item.stockInOutNumber;
                  this.state.cargoInfoTotal.stockInOutWeight = (this.state.cargoInfoTotal.stockInOutWeight)+item.stockInOutWeight;
                  this.state.cargoInfoTotal.sendNumber = (this.state.cargoInfoTotal.sendNumber)+item.sendNumber;
                  this.state.cargoInfoTotal.saleNumber = (this.state.cargoInfoTotal.saleNumber)+item.saleNumber;
                  this.state.cargoInfoTotal.sendWeight = (this.state.cargoInfoTotal.sendWeight).add(item.sendWeight);
                  this.state.cargoInfoTotal.saleWeight = (this.state.cargoInfoTotal.saleWeight).add(item.saleWeight);
                  this.state.cargoInfoTotal.purchaseGoodsMoney = (this.state.cargoInfoTotal.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
                  this.state.cargoInfoTotal.saleGoodsMoney = (this.state.cargoInfoTotal.saleGoodsMoney).add(item.saleGoodsMoney);
                  this.state.cargoInfoTotal.zongchenben = (this.state.cargoInfoTotal.zongchenben).add(zongchenben);
                  this.state.cargoInfoTotal.saleTotalMoney = (this.state.cargoInfoTotal.saleTotalMoney).add(saleTotalMoney);
                  this.state.cargoInfoTotal.saleChenben = (this.state.cargoInfoTotal.saleChenben).add(danweichenbenN.mul(item.saleNumber));
                  this.state.cargoInfoTotal.maoli = (this.state.cargoInfoTotal.maoli).add(saleTotalMoney.sub(danweichenbenN.mul(item.saleNumber)));
                  this.state.cargoInfoTotal.balanceNumber = (this.state.cargoInfoTotal.balanceNumber)+(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
                  this.state.cargoInfoTotal.balanceWeight = (this.state.cargoInfoTotal.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1));
                  this.state.cargoInfoTotal.balanceChenben = (this.state.cargoInfoTotal.balanceChenben).add(danweichenbenN.mul(yushu));
                  return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    {otherUtil.authorityFindCode("020104")!=null?<i className="iconfontIndexCss" style={{width:25,fontSize:15,color:'#F00',textAlign:'center',cursor:'pointer'}} onClick={()=>{
                      this.state.paramsInfo={cargoId:this.state.currCargoItem.id,currBatchCommodityItem:JSON.stringify(item)};
                      this.setState({delSelfComShowPop:true});//stringUtil.language(1051){stringUtil.language(529)}
                    }}>&#xe612;</i>:''}
                    <span style={{width:100,height:'100%',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{item.saleNumber}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                    {/* <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(arithUtil.fenToYuan(item.saleGoodsMoney)).div(item.saleNumber,2):(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</span> */}
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(saleTotalMoney)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(danweichenbenN.mul(item.saleNumber))}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(saleTotalMoney.sub(danweichenbenN.mul(item.saleNumber)))}</span>
                    {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                      let moneyTemp=0;
                      for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
                        if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
                      }
                      this.state.cargoInfoTotal['costs'+itemb.costClauseId]=(this.state.cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
                      if(moneyTemp)return(<span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>{arithUtil.fenToYuan(moneyTemp)}</span>);
                      else return(<span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>0</span>);
                    }):''}
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.processNumber:arithUtil.keToWeight(item.processWeight,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.purchaseNumber:arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(zongchenben)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(danweichenbenN)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(yushu):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(danweichenbenN.mul(yushu))}</span>
                  </div>)
                })}
                <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                  {otherUtil.authorityFindCode("020104")!=null?<div style={{width:25}}/>:''}
                  <span style={{width:100,color:'#FFA500',fontSize:16,textAlign:'center'}}>{stringUtil.language(890)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.cargoInfoTotal.saleNumber}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(this.state.cargoInfoTotal.saleWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}></span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.saleTotalMoney)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.saleChenben)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.maoli)}</span>
                  {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                    return(<span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}} key={keyb}>{arithUtil.fenToYuan(this.state.cargoInfoTotal['costs'+itemb.costClauseId])}</span>);
                  }):''}
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.processNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.processWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.stockInOutNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.stockInOutWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.transferNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.transferWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.purchaseNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.purchaseWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.zongchenben)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}></span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.balanceNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.balanceWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.balanceChenben)}</span>
                </div></div>
              </div>:''}
              {this.state.nWmode==2?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'17%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <div style={{width:'83%',display:'flex',flexDirection:'row'}}>
                  <span style={{width:'23%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                  <span style={{width:'11%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                  <span style={{width:'14%',color:'#808080',textAlign:'center'}}>{stringUtil.language(962)}</span>
                  <span style={{width:'22%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.detailed?this.state.currCargoItem.detailed.map((item,key)=>{
                  let totalItemInfo={number:0,weight:0,money:0};let totalItemInfoAvg={number:0,weight:0,moneyNumber:0,moneyWeight:0};
                  if(item.items==null) item.items=[];
                  for(let i=0;i<item.items.length;i++){
                    totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
                    totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
                    item.items[i].money = item.items[i].goodsMoney;
                    for(let j=0;j<item.items[i].costs.length;j++){
                      item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
                    }
                    totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
                    if(item.items[i].mode==1){
                      totalItemInfoAvg.number = (totalItemInfoAvg.number).add(item.items[i].number);
                      totalItemInfoAvg.moneyNumber = (totalItemInfoAvg.moneyNumber).add(item.items[i].goodsMoney);
                    }
                    if(item.items[i].mode==0){
                      totalItemInfoAvg.weight = (totalItemInfoAvg.weight).add(item.items[i].weight);
                      totalItemInfoAvg.moneyWeight = (totalItemInfoAvg.moneyWeight).add(item.items[i].goodsMoney);
                    }
                  }
                  return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                    <span style={{width:'17%',color:'#000',fontSize:16,paddingTop:7}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <div style={{width:'83%',display:'flex',flexDirection:'column'}}>
                      <span style={{height:30,color:'#52A056',fontSize:16,fontWeight:'bold',paddingTop:7}}>
                        {/* ，{arithUtil.fenToYuan(totalItemInfo.money.div(totalItemInfo.number))}{stringUtil.currency(1)}/stringUtil.language(1173) */}
                        {stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money)}{stringUtil.currency(1)}
                        {totalItemInfoAvg.number?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyNumber).div(totalItemInfoAvg.number,2)+stringUtil.currency(1)+'/'+stringUtil.language(1173):''}{totalItemInfoAvg.weight?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyWeight).div(arithUtil.keToWeight(totalItemInfoAvg.weight,this.state.weightMode),2)+stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):''}
                        {totalItemInfo.number?'，'+arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode).div(totalItemInfo.number,2)+(this.state.weightMode?stringUtil.language(1172)+'/'+stringUtil.language(1173):stringUtil.language(1023)+'/'+stringUtil.language(1173)):''}
                      </span>
                      {item.items.map((itemb,keyb)=>{
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                          <span style={{width:'23%',color:'#808080',fontSize:16}}>{itemb.customerName}</span>
                          <span style={{width:'11%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.number}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                          <span style={{width:'14%',color:'#808080',fontSize:16,textAlign:'center'}}>{(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</span>
                          <span style={{width:'22%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                        </div>);
                      })}
                    </div>
                  </div>)
                }):''}
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                {this.state.currCargoItem.advanceExpends?this.state.currCargoItem.advanceExpends.map((item,key)=>{
                    return(<span style={{width:'33%',color:item.unPay>0?'#F00':'#696969',fontSize:16,cursor:'pointer'}} key={key} onClick={()=>{
                      this.state.keyTagVal = key;
                      this.setState({editExpendShowPop:true});
                    }}>{item.clauseName+'：'+arithUtil.fenToYuan(item.money)}{stringUtil.currency(1)}</span>);
                }):''}
              </div>
              {this.state.currCargoItem.receiveDescribe?<span style={{fontSize:12}}>{stringUtil.language(429)}:{this.state.currCargoItem.receiveDescribe}</span>:''}
            </div>
            <div style={{height:'8%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
              {otherUtil.authorityFindCode("020102")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.state.currBatchItem=JSON.parse(JSON.stringify(this.state.currCargoItem));
                this.setState({cargoCommodityPriceShowPop:!this.state.cargoCommodityPriceShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe657;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(738)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020103")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoAddCommodityActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(739),navigatePage:'CargoAddCommodityActivity',paramsPage:{currCargoItem:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe601;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(739)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020201")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcureOrdersActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(735),navigatePage:'ProcureOrdersActivity',paramsPage:{pageIndex:1,cargoItemInfo:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe613;</i>
                <span style={{color:'#24984D'}}>{ stringUtil.language(735)}</span>
              </div>:<div style={{position:'absolute'}}/>}
              {otherUtil.authorityFindCode("020104")!=null&&this.state.currCargoItem.transferId!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({quashTransferShowPop:!this.state.quashTransferShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#0000FF'}}>&#xe643;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(740)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020103")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                stringUtil.httpRequest(urlUtil.SALE_CARGO_DISCOUNT_URL,{targetId:this.state.currCargoItem.targetId,targetType:this.state.currCargoItem.cargoMode},['key','get',1],(successResponse)=>{
                  if (successResponse.status==200) {
                    this.state.cargoFinishItem={};
                    this.state.cargoFinishItem.discountMoney = successResponse.data;
                    this.setState({finishShowPopIndex:this.state.finishShowPopIndex+1,cargoFinishShowPop:!this.state.cargoFinishShowPop});
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe60c;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(741)}</span>
              </div>:<div/>}
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.getExcel(this.state.currCargoItem);
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe644;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1071)}Excel</span>
              </div>
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({cargoInfoPrint:this.state.currCargoItem},()=>{
                  const printArea = document.getElementById('printArea');
                  const iframe = document.createElement('IFRAME');
                  let doc = null;
                  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                  document.body.appendChild(iframe);
                  doc = iframe.contentWindow.document;
                  doc.write(printArea.innerHTML);
                  doc.close();
                  iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                  iframe.contentWindow.print();
                  if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                  this.setState({cargoInfoPrint:null});
                });
                //this.props.context.props.history.push({pathname:"/CargoSettleActivity",params:{cargoInfo:{id:this.state.currCargoItem.id}}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#8194AA'}}>&#xe867;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(745)}</span>
              </div>
              {otherUtil.authorityFindCode("020104")!=null?<div style={{width:arithUtil.pxToDp(70),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({delShowPop:!this.state.delShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00'}}>&#xe612;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1113)}</span>
              </div>:<div/>}
            </div>
          </div>:<div/>}
          {(this.state.rightShowIndex==2&&this.state.currCargoItem.state==1)?<div style={{width:'100%',height:'100%'}}>
            <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(856)}{this.state.currCargoItem.targetName} {stringUtil.language(1175)}{this.state.currCargoItem.cargoNo}{stringUtil.language(1176)}</span>
                </div>
                <span style={{color:'#FF8C00'}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.receiveTime),'Y-M-D h:m:s')}</span>
              </div>
              {this.state.currCargoItem.carNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <span style={{fontSize:11}}>{stringUtil.language(857)}{this.state.currCargoItem.carNumber}</span>
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(430)}{this.state.currCargoItem.workerId==0?stringUtil.language(431):this.state.currCargoItem.workerName}</span>
                  {(()=>{if(this.state.currCargoItem.detailed==null&&this.state.nWmode==2)this.state.nWmode=0;})()}
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:0});
                  }}>{this.state.nWmode==0?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1021)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1021)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:1});
                  }}>{this.state.nWmode==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1022)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1022)}</i>}</div>
                  {this.state.currCargoItem.detailed?<div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:2});
                  }}>{this.state.nWmode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(432)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(432)}</i>}</div>:''}
                </div>
                <span>{this.state.currCargoItem.warehouseName}</span>
              </div>
            </div>
            <div style={{height:'82%',display:'flex',flexDirection:'column'}}>
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                {(()=>{this.state.cargoInfoTotal={sendNumber:0,transferNumber:0,processNumber:0,purchaseNumber:0,stockInOutNumber:0,saleNumber:0,balanceNumber:0,
                  sendWeight:0,transferWeight:0,processWeight:0,purchaseWeight:0,stockInOutWeight:0,saleWeight:0,balanceWeight:0,purchaseGoodsMoney:0,saleGoodsMoney:0,ownerOther:0}})()}
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1020)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1059)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1068)}</span>
                {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((item,key)=>{
                  this.state.cargoInfoTotal['costs'+item.costClauseId]=0;
                  return(<span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={key}>{stringUtil.subStr(item.costClauseName,5)}</span>);
                }):''}
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1056)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1057)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1055)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1044)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1058)}</span>
              </div>:''}
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.commodities.map((item,key)=>{
                  this.state.cargoInfoTotal.sendNumber=(this.state.cargoInfoTotal.sendNumber)+(item.sendNumber);
                  this.state.cargoInfoTotal.transferNumber=(this.state.cargoInfoTotal.transferNumber)+(item.transferNumber);
                  this.state.cargoInfoTotal.processNumber=(this.state.cargoInfoTotal.processNumber)+(item.processNumber);
                  this.state.cargoInfoTotal.purchaseNumber=(this.state.cargoInfoTotal.purchaseNumber)+(item.purchaseNumber);
                  this.state.cargoInfoTotal.stockInOutNumber=(this.state.cargoInfoTotal.stockInOutNumber)+(item.stockInOutNumber);
                  this.state.cargoInfoTotal.saleNumber=(this.state.cargoInfoTotal.saleNumber)+(item.saleNumber);
                  this.state.cargoInfoTotal.balanceNumber=(this.state.cargoInfoTotal.balanceNumber)+(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
                  this.state.cargoInfoTotal.sendWeight=(this.state.cargoInfoTotal.sendWeight).add(item.sendWeight);
                  this.state.cargoInfoTotal.transferWeight=(this.state.cargoInfoTotal.transferWeight).add(item.transferWeight);
                  this.state.cargoInfoTotal.processWeight=(this.state.cargoInfoTotal.processWeight).add(item.processWeight);
                  this.state.cargoInfoTotal.purchaseWeight=(this.state.cargoInfoTotal.purchaseWeight).add(item.purchaseWeight);
                  this.state.cargoInfoTotal.stockInOutWeight=(this.state.cargoInfoTotal.stockInOutWeight).add(item.stockInOutWeight);
                  this.state.cargoInfoTotal.saleWeight=(this.state.cargoInfoTotal.saleWeight).add(item.saleWeight);
                  this.state.cargoInfoTotal.balanceWeight=(this.state.cargoInfoTotal.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1));
                  this.state.cargoInfoTotal.purchaseGoodsMoney=(this.state.cargoInfoTotal.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
                  this.state.cargoInfoTotal.saleGoodsMoney=(this.state.cargoInfoTotal.saleGoodsMoney).add(item.saleGoodsMoney);
                  return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.saleNumber:arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                    {/* <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(arithUtil.fenToYuan(item.saleGoodsMoney)).div(item.saleNumber,2):(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</span> */}
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(item.saleGoodsMoney)}</span>
                    {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                      let moneyTemp=0;
                      for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
                        if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
                      }
                      this.state.cargoInfoTotal['costs'+itemb.costClauseId]=(this.state.cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
                      if(moneyTemp)return(<span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>{arithUtil.fenToYuan(moneyTemp)}</span>);
                      else return(<span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>0</span>);
                    }):''}
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.processNumber:arithUtil.keToWeight(item.processWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.purchaseNumber:arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</span>
                  </div>)
                })}
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{stringUtil.language(890)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.saleNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.saleWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}></span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.saleGoodsMoney)}</span>
                  {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                    return(<span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}} key={keyb}>{arithUtil.fenToYuan(this.state.cargoInfoTotal['costs'+itemb.costClauseId])}</span>);
                  }):''}
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.processNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.processWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.stockInOutNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.stockInOutWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.transferNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.transferWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.purchaseNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.purchaseWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.balanceNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.balanceWeight,this.state.weightMode)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'17%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <div style={{width:'83%',display:'flex',flexDirection:'row'}}>
                  <span style={{width:'23%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                  <span style={{width:'11%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                  <span style={{width:'14%',color:'#808080',textAlign:'center'}}>{stringUtil.language(962)}</span>
                  <span style={{width:'22%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.detailed?this.state.currCargoItem.detailed.map((item,key)=>{
                  let totalItemInfo={number:0,weight:0,money:0};let totalItemInfoAvg={number:0,weight:0,moneyNumber:0,moneyWeight:0};
                  if(item.items==null) item.items=[];
                  for(let i=0;i<item.items.length;i++){
                    totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
                    totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
                    item.items[i].money = item.items[i].goodsMoney;
                    for(let j=0;j<item.items[i].costs.length;j++){
                      item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
                    }
                    totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
                    if(item.items[i].mode==1){
                      totalItemInfoAvg.number = (totalItemInfoAvg.number).add(item.items[i].number);
                      totalItemInfoAvg.moneyNumber = (totalItemInfoAvg.moneyNumber).add(item.items[i].goodsMoney);
                    }
                    if(item.items[i].mode==0){
                      totalItemInfoAvg.weight = (totalItemInfoAvg.weight).add(item.items[i].weight);
                      totalItemInfoAvg.moneyWeight = (totalItemInfoAvg.moneyWeight).add(item.items[i].goodsMoney);
                    }
                  }
                  return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                    <span style={{width:'17%',color:'#000',fontSize:16,paddingTop:7}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <div style={{width:'83%',display:'flex',flexDirection:'column'}}>
                      <span style={{height:30,color:'#52A056',fontSize:16,fontWeight:'bold',paddingTop:7}}>
                        {stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money)}{stringUtil.currency(1)}
                        {totalItemInfoAvg.number?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyNumber).div(totalItemInfoAvg.number,2)+stringUtil.currency(1)+'/'+stringUtil.language(1173):''}{totalItemInfoAvg.weight?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyWeight).div(arithUtil.keToWeight(totalItemInfoAvg.weight,this.state.weightMode),2)+stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):''}
                        {totalItemInfo.number?'，'+arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode).div(totalItemInfo.number,2)+(this.state.weightMode?stringUtil.language(1172)+'/'+stringUtil.language(1173):stringUtil.language(1023)+'/'+stringUtil.language(1173)):''}
                      </span>
                      {item.items.map((itemb,keyb)=>{
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                          <span style={{width:'23%',color:'#808080',fontSize:16}}>{itemb.customerName}</span>
                          <span style={{width:'11%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.number}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                          <span style={{width:'14%',color:'#808080',fontSize:16,textAlign:'center'}}>{(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</span>
                          <span style={{width:'22%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                        </div>);
                      })}
                    </div>
                  </div>)
                }):''}
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                {this.state.currCargoItem.advanceExpends?this.state.currCargoItem.advanceExpends.map((item,key)=>{
                    return(<span style={{width:'33%',color:item.unPay>0?'#F00':'#696969',fontSize:16,cursor:'pointer'}} key={key} onClick={()=>{
                      this.state.keyTagVal = key;
                      this.setState({editExpendShowPop:true});
                    }}>{item.clauseName+'：'+arithUtil.fenToYuan(item.money)}{stringUtil.currency(1)}</span>);
                }):''}
              </div>
              {this.state.currCargoItem.receiveDescribe?<span style={{fontSize:12}}>{stringUtil.language(429)}:{this.state.currCargoItem.receiveDescribe}</span>:''}
            </div>
            <div style={{height:'8%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
              {this.state.currCargoItem.deliveryMode?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoSonsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1240),navigatePage:'CargoSonsActivity',paramsPage:{currCargoItem:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}}>&#xe6c6;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1240)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020102")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.state.currBatchItem=JSON.parse(JSON.stringify(this.state.currCargoItem));
                this.setState({cargoCommodityPriceShowPop:!this.state.cargoCommodityPriceShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe657;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(738)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020201")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcureOrdersActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(735),navigatePage:'ProcureOrdersActivity',paramsPage:{pageIndex:2,cargoItemInfo:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe613;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(735)}</span>
              </div>:<div style={{position:'absolute'}}/>}
              {otherUtil.authorityFindCode("020104")!=null&&this.state.currCargoItem.transferId!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({quashTransferShowPop:!this.state.quashTransferShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#0000FF'}}>&#xe643;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(740)}</span>
              </div>:''}
              {this.state.currCargoItem.deliveryMode?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoSettlesActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(418),navigatePage:'CargoSettlesActivity',paramsPage:{currCargoItem:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}}>&#xe6c6;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1241)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020103")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                stringUtil.httpRequest(urlUtil.SALE_CARGO_DISCOUNT_URL,{targetId:this.state.currCargoItem.targetId,targetType:this.state.currCargoItem.cargoMode},['key','get',1],(successResponse)=>{
                  if (successResponse.status==200) {
                    this.state.cargoFinishItem={};
                    this.state.cargoFinishItem.discountMoney = successResponse.data;
                    this.setState({finishShowPopIndex:this.state.finishShowPopIndex+1,cargoFinishShowPop:!this.state.cargoFinishShowPop});
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe60c;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(741)}</span>
              </div>:<div/>}
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.getExcel(this.state.currCargoItem);
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe644;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1071)}Excel</span>
              </div>
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({cargoInfoPrint:this.state.currCargoItem},()=>{
                  const printArea = document.getElementById('printArea');
                  const iframe = document.createElement('IFRAME');
                  let doc = null;
                  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                  document.body.appendChild(iframe);
                  doc = iframe.contentWindow.document;
                  doc.write(printArea.innerHTML);
                  doc.close();
                  iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                  iframe.contentWindow.print();
                  if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                  this.setState({cargoInfoPrint:null});
                });
                //this.props.context.props.history.push({pathname:"/CargoSettleActivity",params:{cargoInfo:{id:this.state.currCargoItem.id}}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#8194AA'}}>&#xe867;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(745)}</span>
              </div>
              {otherUtil.authorityFindCode("020104")!=null?<div style={{width:arithUtil.pxToDp(70),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({delShowPop:!this.state.delShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00'}}>&#xe612;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1113)}</span>
              </div>:<div/>}
            </div>
          </div>:<div/>}
          {(this.state.rightShowIndex==0&&this.state.currCargoItem.state==2)?<div style={{width:'100%',height:'100%'}}>
            <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(856)}{this.state.currCargoItem.cargoMode==0?this.state.currCargoItem.targetName:''} {stringUtil.language(1175)}{this.state.currCargoItem.cargoNo}{stringUtil.language(1176)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{color:'#FF8C00'}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.receiveTime),'Y-M-D h:m:s')}{stringUtil.language(1052)}</span>
                <span style={{color:'#DAA520',marginLeft:5}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.settleTime),'Y-M-D h:m:s')}{stringUtil.language(1053)}</span></div>
              </div>
              {this.state.currCargoItem.carNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <span style={{fontSize:11}}>{stringUtil.language(857)}{this.state.currCargoItem.carNumber}</span>
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(430)}{this.state.currCargoItem.workerId==0?stringUtil.language(431):this.state.currCargoItem.workerName}</span>
                  {(()=>{if(this.state.currCargoItem.detailed==null&&this.state.nWmode==2)this.state.nWmode=0;})()}
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:0});
                  }}>{this.state.nWmode==0?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1021)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1021)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:1});
                  }}>{this.state.nWmode==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1022)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1022)}</i>}</div>
                  {this.state.currCargoItem.detailed?<div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:2});
                  }}>{this.state.nWmode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(432)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(432)}</i>}</div>:''}
                </div>
                <span>{this.state.currCargoItem.warehouseName}</span>
              </div>
            </div>
            <div style={{height:'82%',display:'flex',flexDirection:'column'}}>
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                {(()=>{this.state.cargoInfoTotal={sendNumber:0,transferNumber:0,processNumber:0,purchaseNumber:0,stockInOutNumber:0,saleNumber:0,balanceNumber:0,
                  sendWeight:0,transferWeight:0,processWeight:0,purchaseWeight:0,stockInOutWeight:0,saleWeight:0,balanceWeight:0,purchaseGoodsMoney:0,saleGoodsMoney:0}})()}
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1020)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1059)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1068)}</span>
                {this.state.currCargoItem.diffGoodsMoney!=0?<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(538)}</span>:""}
                {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((item,key)=>{
                  this.state.cargoInfoTotal['costs'+item.costClauseId]=0;
                  return(<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={key}>{stringUtil.subStr(item.costClauseName,5)}</span>);
                }):''}
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1057)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1054)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1055)}</span>
                <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1058)}</span>
              </div>:''}
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.commodities.map((item,key)=>{
                  this.state.cargoInfoTotal.sendNumber=(this.state.cargoInfoTotal.sendNumber)+(item.sendNumber);
                  this.state.cargoInfoTotal.transferNumber=(this.state.cargoInfoTotal.transferNumber)+(item.transferNumber);
                  this.state.cargoInfoTotal.processNumber=(this.state.cargoInfoTotal.processNumber)+(item.processNumber);
                  this.state.cargoInfoTotal.purchaseNumber=(this.state.cargoInfoTotal.purchaseNumber)+(item.purchaseNumber);
                  this.state.cargoInfoTotal.stockInOutNumber=(this.state.cargoInfoTotal.stockInOutNumber)+(item.stockInOutNumber);
                  this.state.cargoInfoTotal.saleNumber=(this.state.cargoInfoTotal.saleNumber)+(item.saleNumber);
                  this.state.cargoInfoTotal.balanceNumber=(this.state.cargoInfoTotal.balanceNumber)+(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
                  this.state.cargoInfoTotal.sendWeight=(this.state.cargoInfoTotal.sendWeight).add(item.sendWeight);
                  this.state.cargoInfoTotal.transferWeight=(this.state.cargoInfoTotal.transferWeight).add(item.transferWeight);
                  this.state.cargoInfoTotal.processWeight=(this.state.cargoInfoTotal.processWeight).add(item.processWeight);
                  this.state.cargoInfoTotal.purchaseWeight=(this.state.cargoInfoTotal.purchaseWeight).add(item.purchaseWeight);
                  this.state.cargoInfoTotal.stockInOutWeight=(this.state.cargoInfoTotal.stockInOutWeight).add(item.stockInOutWeight);
                  this.state.cargoInfoTotal.saleWeight=(this.state.cargoInfoTotal.saleWeight).add(item.saleWeight);
                  this.state.cargoInfoTotal.balanceWeight=(this.state.cargoInfoTotal.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1));
                  this.state.cargoInfoTotal.purchaseGoodsMoney=(this.state.cargoInfoTotal.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
                  this.state.cargoInfoTotal.saleGoodsMoney=(this.state.cargoInfoTotal.saleGoodsMoney).add(item.saleGoodsMoney.sub(item.diffGoodsMoney));
                  return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.saleNumber:arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                    {/* <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(arithUtil.fenToYuan(item.saleGoodsMoney.sub(item.diffGoodsMoney))).div(item.saleNumber,2):(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</span> */}
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(item.saleGoodsMoney.sub(item.diffGoodsMoney))}</span>
                    {this.state.currCargoItem.diffGoodsMoney!=0?<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(item.diffGoodsMoney)}</span>:""}
                    {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                      let moneyTemp=0;
                      for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
                        if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
                      }
                      this.state.cargoInfoTotal['costs'+itemb.costClauseId]=(this.state.cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
                      if(moneyTemp)return(<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>{arithUtil.fenToYuan(moneyTemp)}</span>);
                      else return(<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>0</span>);
                    }):''}
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.sendNumber:arithUtil.keToWeight(item.sendWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</span>
                  </div>);
                })}
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{stringUtil.language(890)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.saleNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.saleWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}></span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.saleGoodsMoney)}</span>
                  {this.state.currCargoItem.diffGoodsMoney!=0?<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.currCargoItem.diffGoodsMoney)}</span>:""}
                  {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                    return(<span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}} key={keyb}>{arithUtil.fenToYuan(this.state.cargoInfoTotal['costs'+itemb.costClauseId])}</span>);
                  }):''}
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.stockInOutNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.stockInOutWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.sendNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.sendWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.transferNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.transferWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(8,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.balanceNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.balanceWeight,this.state.weightMode)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'17%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <div style={{width:'83%',display:'flex',flexDirection:'row'}}>
                  <span style={{width:'23%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                  <span style={{width:'11%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                  <span style={{width:'14%',color:'#808080',textAlign:'center'}}>{stringUtil.language(962)}</span>
                  <span style={{width:'22%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.detailed?this.state.currCargoItem.detailed.map((item,key)=>{
                  let totalItemInfo={number:0,weight:0,money:0};let totalItemInfoAvg={number:0,weight:0,moneyNumber:0,moneyWeight:0};
                  if(item.items==null) item.items=[];
                  for(let i=0;i<item.items.length;i++){
                    totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
                    totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
                    item.items[i].money = item.items[i].goodsMoney;
                    for(let j=0;j<item.items[i].costs.length;j++){
                      item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
                    }
                    totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
                    if(item.items[i].mode==1){
                      totalItemInfoAvg.number = (totalItemInfoAvg.number).add(item.items[i].number);
                      totalItemInfoAvg.moneyNumber = (totalItemInfoAvg.moneyNumber).add(item.items[i].goodsMoney);
                    }
                    if(item.items[i].mode==0){
                      totalItemInfoAvg.weight = (totalItemInfoAvg.weight).add(item.items[i].weight);
                      totalItemInfoAvg.moneyWeight = (totalItemInfoAvg.moneyWeight).add(item.items[i].goodsMoney);
                    }
                  }
                  return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                    <span style={{width:'17%',color:'#000',fontSize:16,paddingTop:7}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <div style={{width:'83%',display:'flex',flexDirection:'column'}}>
                      <span style={{height:30,color:'#52A056',fontSize:16,fontWeight:'bold',paddingTop:7}}>
                        {/* ，{arithUtil.fenToYuan(totalItemInfo.money.div(totalItemInfo.number))}{stringUtil.currency(1)}/stringUtil.language(1173) */}
                        {stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money)}{stringUtil.currency(1)}
                        {totalItemInfoAvg.number?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyNumber).div(totalItemInfoAvg.number,2)+stringUtil.currency(1)+'/'+stringUtil.language(1173):''}{totalItemInfoAvg.weight?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyWeight).div(arithUtil.keToWeight(totalItemInfoAvg.weight,this.state.weightMode),2)+stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):''}
                        {totalItemInfo.number?'，'+arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode).div(totalItemInfo.number,2)+(this.state.weightMode?stringUtil.language(1172)+'/'+stringUtil.language(1173):stringUtil.language(1023)+'/'+stringUtil.language(1173)):''}
                      </span>
                      {item.items.map((itemb,keyb)=>{
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                          <span style={{width:'23%',color:'#808080',fontSize:16}}>{itemb.customerName}</span>
                          <span style={{width:'11%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.number}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                          <span style={{width:'14%',color:'#808080',fontSize:16,textAlign:'center'}}>{(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</span>
                          <span style={{width:'22%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                        </div>);
                      })}
                    </div>
                  </div>)
                }):''}
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                {(()=>{this.state.otherMoneyItems = [];
                this.state.docMoney = this.state.currCargoItem.saleGoodsMoney;
                this.state.ownerMoney = this.state.currCargoItem.saleGoodsMoney;
                this.state.ownerMoney = this.state.ownerMoney.sub(this.state.currCargoItem.diffGoodsMoney);
                if(this.state.currCargoItem.saleCosts){
                  for(let i=0;i<this.state.currCargoItem.saleCosts.length;i++){
                    this.state.docMoney = (this.state.docMoney).add(this.state.currCargoItem.saleCosts[i].money);
                    if(this.state.currCargoItem.cargoMode==0&&this.state.currCargoItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                      this.state.ownerMoney = (this.state.ownerMoney).add(this.state.currCargoItem.saleCosts[i].money);
                    }
                    this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.saleCosts[i].costClauseName,money:this.state.currCargoItem.saleCosts[i].money});
                  }
                }
                if(this.state.currCargoItem.cargoMode==0&&this.state.currCargoItem.diffGoodsMoney!=0){
                  this.state.otherMoneyItems.push({costClauseName:stringUtil.language(538),money:this.state.currCargoItem.diffGoodsMoney});
                }
                this.state.otherMoneyItems.push({costClauseName:stringUtil.language(746),money:this.state.docMoney});
                if(this.state.currCargoItem.cargoMode==0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(592),money:this.state.ownerMoney});
                if(this.state.currCargoItem.cargoMode==2)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(594),money:this.state.ownerMoney});
                if(this.state.currCargoItem.cargoMode==1||this.state.currCargoItem.cargoMode==2){
                  if(this.state.currCargoItem.purchaseCosts){
                    let caigouchenben=this.state.currCargoItem.purchaseGoodsMoney;
                    for(let i=0;i<this.state.currCargoItem.purchaseCosts.length;i++){
                      caigouchenben=caigouchenben.add(this.state.currCargoItem.purchaseCosts[i].money);
                      this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.purchaseCosts[i].costClauseName,money:this.state.currCargoItem.purchaseCosts[i].money});
                    }
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(610),money:caigouchenben});
                  }
                  if(this.state.currCargoItem.processCosts){
                    let jiagongfei=0;
                    for(let i=0;i<this.state.currCargoItem.processCosts.length;i++){
                      jiagongfei=jiagongfei.add(this.state.currCargoItem.processCosts[i].money);
                      this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.processCosts[i].costClauseName,money:this.state.currCargoItem.processCosts[i].money});
                    }
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(993),money:jiagongfei});
                  }
                  this.state.otherMoneyItems.push({costClauseName:stringUtil.language(747),money:this.state.currCargoItem.transferMoney});
                  this.state.otherMoneyItems.push({costClauseName:stringUtil.language(748),money:this.state.currCargoItem.transferToMoney});
                }
                if(this.state.currCargoItem.rebateMoney!=0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(1150),money:this.state.currCargoItem.rebateMoney});
                if(this.state.currCargoItem.disburdenMoney!=0)this.state.otherMoneyItems.push({costClauseName:JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,money:this.state.currCargoItem.disburdenMoney});
                if(this.state.currCargoItem.entrustMoney!=0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(941),money:this.state.currCargoItem.entrustMoney});
                if(this.state.currCargoItem.discountMoney!=0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(543),money:this.state.currCargoItem.discountMoney});
                if(this.state.currCargoItem.expends)for(let i=0;i<this.state.currCargoItem.expends.length;i++){
                  this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.expends[i].ownerExpendClauseName,money:this.state.currCargoItem.expends[i].money});
                }
                })()}
                {this.state.otherMoneyItems.map((item,key)=>{
                    return(<div style={{width:'33.3%',height:25,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                      <span style={{fontSize:13}}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</span>
                    </div>);
                })}
                <div style={{width:'33.3%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{fontSize:13}}>{stringUtil.language(749)}：</span><span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currCargoItem.balance)}</span>
                </div>
                {this.state.currCargoItem.receiveDescribe?<span style={{fontSize:12}}>{stringUtil.language(429)}:{this.state.currCargoItem.receiveDescribe}</span>:''}
                {this.state.currCargoItem.finishDescribe?<span style={{fontSize:12}}>{stringUtil.language(750)}:{this.state.currCargoItem.finishDescribe}</span>:''}
              </div>
            </div>
            <div style={{height:'8%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
              {this.state.currCargoItem.deliveryMode?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoSonsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1240),navigatePage:'CargoSonsActivity',paramsPage:{currCargoItem:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}}>&#xe6c6;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1240)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020102")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoTransferOwnerActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(751),navigatePage:'CargoTransferOwnerActivity',paramsPage:{cargoItemInfo:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#00F'}}>&#xe643;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(751)}</span>
              </div>:<div/>}
              {this.state.currCargoItem.deliveryMode?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoSettlesActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(418),navigatePage:'CargoSettlesActivity',paramsPage:{currCargoItem:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}}>&#xe6c6;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1241)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("020103")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({cargoNoFinishShowPop:!this.state.cargoNoFinishShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe60c;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(353)}</span>
              </div>:<div/>}
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.getExcel(this.state.currCargoItem);
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe644;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1071)}Excel</span>
              </div>
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({cargoInfoPrint:this.state.currCargoItem},()=>{
                  const printArea = document.getElementById('printArea');
                  const iframe = document.createElement('IFRAME');
                  let doc = null;
                  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                  document.body.appendChild(iframe);
                  doc = iframe.contentWindow.document;
                  doc.write(printArea.innerHTML);
                  doc.close();
                  iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                  iframe.contentWindow.print();
                  if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                  this.setState({cargoInfoPrint:null});
                });
                //this.props.context.props.history.push({pathname:"/CargoSettleActivity",params:{cargoInfo:{id:this.state.currCargoItem.id}}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#8194AA'}}>&#xe867;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(745)}</span>
              </div>
            </div>
          </div>:<div/>}
          {(this.state.rightShowIndex==1&&this.state.currCargoItem.state==2)?<div style={{width:'100%',height:'100%'}}>
            <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(856)}{stringUtil.language(426)}{this.state.currCargoItem.targetName?'_'+this.state.currCargoItem.targetName:''} {stringUtil.language(1175)}{this.state.currCargoItem.cargoNo}{stringUtil.language(1176)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{color:'#FF8C00'}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.receiveTime),'Y-M-D h:m:s')}{stringUtil.language(1052)}</span>
                <span style={{color:'#DAA520',marginLeft:5}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.settleTime),'Y-M-D h:m:s')}{stringUtil.language(1053)}</span></div>
              </div>
              {this.state.currCargoItem.carNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <span style={{fontSize:11}}>{stringUtil.language(857)}{this.state.currCargoItem.carNumber}</span>
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(430)}{this.state.currCargoItem.workerId==0?stringUtil.language(431):this.state.currCargoItem.workerName}</span>
                  {(()=>{if(this.state.currCargoItem.detailed==null&&this.state.nWmode==2)this.state.nWmode=0;})()}
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:0});
                  }}>{this.state.nWmode==0?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1021)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1021)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:1});
                  }}>{this.state.nWmode==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; stringUtil.language(1022)</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; stringUtil.language(1022)</i>}</div>
                  {this.state.currCargoItem.detailed?<div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:2});
                  }}>{this.state.nWmode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(432)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(432)}</i>}</div>:''}
                </div>
                <span>{this.state.currCargoItem.warehouseName}</span>
              </div>
            </div>
            <div style={{height:'82%',display:'flex',flexDirection:'column'}}>
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{flexGrow:1,overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}}>
                {(()=>{this.state.cargoInfoTotal={sendNumber:0,transferNumber:0,processNumber:0,purchaseNumber:0,stockInOutNumber:0,saleNumber:0,balanceNumber:0,sendWeight:0,transferWeight:0,processWeight:0,
                  purchaseWeight:0,stockInOutWeight:0,saleWeight:0,balanceWeight:0,purchaseGoodsMoney:0,saleGoodsMoney:0,zongchenben:0,saleTotalMoney:0,saleChenben:0,maoli:0,balanceChenben:0}})()}
                <div><div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{width:100,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1020)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(742)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(743)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(889)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(600)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1148)}</span>
                  {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((item,key)=>{
                    this.state.cargoInfoTotal['costs'+item.costClauseId]=0;
                    return(<span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={key}>{stringUtil.subStr(item.costClauseName,5)}</span>);
                  }):''}
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1056)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1057)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1055)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1044)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(947)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(559)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1149)}</span>
                  <span style={{width:85,height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(744)}</span>
                </div>
                {this.state.currCargoItem.commodities.map((item,key)=>{
                  let zongchenben=(item.transferMoney).add(item.purchaseGoodsMoney).add(item.processNumber>0?item.processMoney:0);
                  if(item.purchaseCosts){
                    for(let i=0;i<item.purchaseCosts.length;i++){
                      zongchenben=zongchenben.add(item.purchaseCosts[i].money);
                    }
                  }
                  let saleTotalMoney=item.saleGoodsMoney;
                  if(item.saleCosts){
                    for(let i=0;i<item.saleCosts.length;i++){
                      saleTotalMoney=saleTotalMoney.add(item.saleCosts[i].money);
                    }
                  }
                  let danweichenbenN=(zongchenben).div(item.transferNumber+item.purchaseNumber+(item.processNumber>0?item.processNumber:0));//stringUtil.language(1021)的{stringUtil.language(559)}
                  zongchenben=zongchenben.add(item.processNumber<0?(item.processNumber).mul(danweichenbenN):0);
                  let yushu=item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber;
                  this.state.cargoInfoTotal.transferNumber = (this.state.cargoInfoTotal.transferNumber)+item.transferNumber;
                  this.state.cargoInfoTotal.transferWeight = (this.state.cargoInfoTotal.transferWeight)+item.transferWeight;
                  this.state.cargoInfoTotal.processNumber = (this.state.cargoInfoTotal.processNumber)+item.processNumber;
                  this.state.cargoInfoTotal.processWeight = (this.state.cargoInfoTotal.processWeight)+item.processWeight;
                  this.state.cargoInfoTotal.purchaseNumber = (this.state.cargoInfoTotal.purchaseNumber)+item.purchaseNumber;
                  this.state.cargoInfoTotal.purchaseWeight = (this.state.cargoInfoTotal.purchaseWeight)+item.purchaseWeight;
                  this.state.cargoInfoTotal.stockInOutNumber = (this.state.cargoInfoTotal.stockInOutNumber)+item.stockInOutNumber;
                  this.state.cargoInfoTotal.stockInOutWeight = (this.state.cargoInfoTotal.stockInOutWeight)+item.stockInOutWeight;
                  this.state.cargoInfoTotal.sendNumber = (this.state.cargoInfoTotal.sendNumber)+item.sendNumber;
                  this.state.cargoInfoTotal.saleNumber = (this.state.cargoInfoTotal.saleNumber)+item.saleNumber;
                  this.state.cargoInfoTotal.sendWeight = (this.state.cargoInfoTotal.sendWeight).add(item.sendWeight);
                  this.state.cargoInfoTotal.saleWeight = (this.state.cargoInfoTotal.saleWeight).add(item.saleWeight);
                  this.state.cargoInfoTotal.purchaseGoodsMoney = (this.state.cargoInfoTotal.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
                  this.state.cargoInfoTotal.saleGoodsMoney = (this.state.cargoInfoTotal.saleGoodsMoney).add(item.saleGoodsMoney);
                  this.state.cargoInfoTotal.zongchenben = (this.state.cargoInfoTotal.zongchenben).add(zongchenben);
                  this.state.cargoInfoTotal.saleTotalMoney = (this.state.cargoInfoTotal.saleTotalMoney).add(saleTotalMoney);
                  this.state.cargoInfoTotal.saleChenben = (this.state.cargoInfoTotal.saleChenben).add(danweichenbenN.mul(item.saleNumber));
                  this.state.cargoInfoTotal.maoli = (this.state.cargoInfoTotal.maoli).add(saleTotalMoney.sub(danweichenbenN.mul(item.saleNumber)));
                  this.state.cargoInfoTotal.balanceNumber = (this.state.cargoInfoTotal.balanceNumber)+(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
                  this.state.cargoInfoTotal.balanceWeight = (this.state.cargoInfoTotal.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1));
                  this.state.cargoInfoTotal.balanceChenben = (this.state.cargoInfoTotal.balanceChenben).add(danweichenbenN.mul(yushu));
                  return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:100,height:'100%',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{item.saleNumber}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                    {/* <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(arithUtil.fenToYuan(item.saleGoodsMoney)).div(item.saleNumber,2):(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</span> */}
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(saleTotalMoney)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(danweichenbenN.mul(item.saleNumber))}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(saleTotalMoney.sub(danweichenbenN.mul(item.saleNumber)))}</span>
                    {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                      let moneyTemp=0;
                      for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
                        if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
                      }
                      this.state.cargoInfoTotal['costs'+itemb.costClauseId]=(this.state.cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
                      if(moneyTemp)return(<span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>{arithUtil.fenToYuan(moneyTemp)}</span>);
                      else return(<span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>0</span>);
                    }):''}
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.processNumber:arithUtil.keToWeight(item.processWeight,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.purchaseNumber:arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(zongchenben)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(danweichenbenN)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(yushu):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</span>
                    <span style={{width:85,height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(danweichenbenN.mul(yushu))}</span>
                  </div>)
                })}
                <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{width:100,color:'#FFA500',fontSize:16,textAlign:'center'}}>{stringUtil.language(890)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.cargoInfoTotal.saleNumber}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(this.state.cargoInfoTotal.saleWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}></span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.saleTotalMoney)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.saleChenben)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.maoli)}</span>
                  {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                    return(<span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}} key={keyb}>{arithUtil.fenToYuan(this.state.cargoInfoTotal['costs'+itemb.costClauseId])}</span>);
                  }):''}
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.processNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.processWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.stockInOutNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.stockInOutWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.transferNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.transferWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.purchaseNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.purchaseWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.zongchenben)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}></span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.balanceNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.balanceWeight,this.state.weightMode)}</span>
                  <span style={{width:85,color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.balanceChenben)}</span>
                </div></div>
              </div>:''}
              {this.state.nWmode==2?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'17%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <div style={{width:'83%',display:'flex',flexDirection:'row'}}>
                  <span style={{width:'23%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                  <span style={{width:'11%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                  <span style={{width:'14%',color:'#808080',textAlign:'center'}}>{stringUtil.language(962)}</span>
                  <span style={{width:'22%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.detailed?this.state.currCargoItem.detailed.map((item,key)=>{
                  let totalItemInfo={number:0,weight:0,money:0};let totalItemInfoAvg={number:0,weight:0,moneyNumber:0,moneyWeight:0};
                  if(item.items==null) item.items=[];
                  for(let i=0;i<item.items.length;i++){
                    totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
                    totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
                    item.items[i].money = item.items[i].goodsMoney.sub(item.items[i].diffGoodsMoney);
                    for(let j=0;j<item.items[i].costs.length;j++){
                      item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
                    }
                    totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
                    if(item.items[i].mode==1){
                      totalItemInfoAvg.number = (totalItemInfoAvg.number).add(item.items[i].number);
                      totalItemInfoAvg.moneyNumber = (totalItemInfoAvg.moneyNumber).add(item.items[i].goodsMoney);
                    }
                    if(item.items[i].mode==0){
                      totalItemInfoAvg.weight = (totalItemInfoAvg.weight).add(item.items[i].weight);
                      totalItemInfoAvg.moneyWeight = (totalItemInfoAvg.moneyWeight).add(item.items[i].goodsMoney);
                    }
                  }
                  return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                    <span style={{width:'17%',color:'#000',fontSize:16,paddingTop:7}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <div style={{width:'83%',display:'flex',flexDirection:'column'}}>
                      <span style={{height:30,color:'#52A056',fontSize:16,fontWeight:'bold',paddingTop:7}}>
                        {/* ，{arithUtil.fenToYuan(totalItemInfo.money.div(totalItemInfo.number))}{stringUtil.currency(1)}/stringUtil.language(1173) */}
                        {stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money)}{stringUtil.currency(1)}
                        {totalItemInfoAvg.number?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyNumber).div(totalItemInfoAvg.number,2)+stringUtil.currency(1)+'/stringUtil.language(1173)':''}{totalItemInfoAvg.weight?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyWeight).div(arithUtil.keToWeight(totalItemInfoAvg.weight,this.state.weightMode),2)+stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):''}
                        {totalItemInfo.number?'，'+arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode).div(totalItemInfo.number,2)+(this.state.weightMode?stringUtil.language(1172)+'/'+stringUtil.language(1173):stringUtil.language(1023)+'/'+stringUtil.language(1173)):''}
                      </span>
                      {item.items.map((itemb,keyb)=>{
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                          <span style={{width:'23%',color:'#808080',fontSize:16}}>{itemb.customerName}</span>
                          <span style={{width:'11%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.number}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                          <span style={{width:'14%',color:'#808080',fontSize:16,textAlign:'center'}}>{(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</span>
                          <span style={{width:'22%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                        </div>);
                      })}
                    </div>
                  </div>)
                }):''}
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                {(()=>{this.state.otherMoneyItems = [];
                this.state.docMoney = this.state.currCargoItem.saleGoodsMoney;
                this.state.ownerMoney = this.state.currCargoItem.saleGoodsMoney;
                if(this.state.currCargoItem.saleCosts){
                  for(let i=0;i<this.state.currCargoItem.saleCosts.length;i++){
                    this.state.docMoney = (this.state.docMoney).add(this.state.currCargoItem.saleCosts[i].money);
                    if(this.state.currCargoItem.cargoMode==0&&this.state.currCargoItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                      this.state.ownerMoney = (this.state.ownerMoney).add(this.state.currCargoItem.saleCosts[i].money);
                    }
                    this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.saleCosts[i].costClauseName,money:this.state.currCargoItem.saleCosts[i].money});
                  }
                }
                this.state.otherMoneyItems.push({costClauseName:stringUtil.language(746),money:this.state.docMoney});
                if(this.state.currCargoItem.cargoMode==0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(592),money:this.state.ownerMoney});
                if(this.state.currCargoItem.cargoMode==2)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(594),money:this.state.ownerMoney});
                if(this.state.currCargoItem.cargoMode==1||this.state.currCargoItem.cargoMode==2){
                  if(this.state.currCargoItem.purchaseCosts){
                    let caigouchenben=this.state.currCargoItem.purchaseGoodsMoney;
                    for(let i=0;i<this.state.currCargoItem.purchaseCosts.length;i++){
                      caigouchenben=caigouchenben.add(this.state.currCargoItem.purchaseCosts[i].money);
                      this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.purchaseCosts[i].costClauseName,money:this.state.currCargoItem.purchaseCosts[i].money});
                    }
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(610),money:caigouchenben});
                  }
                  if(this.state.currCargoItem.processCosts){
                    let jiagongfei=0;
                    for(let i=0;i<this.state.currCargoItem.processCosts.length;i++){
                      jiagongfei=jiagongfei.add(this.state.currCargoItem.processCosts[i].money);
                      this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.processCosts[i].costClauseName,money:this.state.currCargoItem.processCosts[i].money});
                    }
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(993),money:jiagongfei});
                  }
                  this.state.otherMoneyItems.push({costClauseName:stringUtil.language(747),money:this.state.currCargoItem.transferMoney});
                  this.state.otherMoneyItems.push({costClauseName:stringUtil.language(748),money:this.state.currCargoItem.transferToMoney});
                }
                if(this.state.currCargoItem.rebateMoney!=0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(1150),money:this.state.currCargoItem.rebateMoney});
                if(this.state.currCargoItem.disburdenMoney!=0)this.state.otherMoneyItems.push({costClauseName:JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,money:this.state.currCargoItem.disburdenMoney});
                if(this.state.currCargoItem.entrustMoney!=0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(941),money:this.state.currCargoItem.entrustMoney});
                if(this.state.currCargoItem.discountMoney!=0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(543),money:this.state.currCargoItem.discountMoney});
                if(this.state.currCargoItem.expends)for(let i=0;i<this.state.currCargoItem.expends.length;i++){
                  this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.expends[i].ownerExpendClauseName,money:this.state.currCargoItem.expends[i].money});
                }
                })()}
                {this.state.otherMoneyItems.map((item,key)=>{
                    return(<div style={{width:'33.3%',height:25,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                      <span style={{fontSize:13}}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</span>
                    </div>);
                })}
                <div style={{width:'33.3%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{fontSize:13}}>{stringUtil.language(749)}：</span><span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currCargoItem.balance)}</span>
                </div>
                {this.state.currCargoItem.receiveDescribe?<span style={{fontSize:12}}>{stringUtil.language(429)}:{this.state.currCargoItem.receiveDescribe}</span>:''}
                {this.state.currCargoItem.finishDescribe?<span style={{fontSize:12}}>{stringUtil.language(750)}:{this.state.currCargoItem.finishDescribe}</span>:''}
              </div>
            </div>
            <div style={{height:'8%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
              {otherUtil.authorityFindCode("020201")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcureOrdersActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(735),navigatePage:'ProcureOrdersActivity',paramsPage:{pageIndex:1,cargoItemInfo:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe613;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(735)}</span>
              </div>:<div style={{position:'absolute'}}/>}
              {otherUtil.authorityFindCode("020102")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoTransferSelfActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(751),navigatePage:'CargoTransferSelfActivity',paramsPage:{cargoItemInfo:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#00F'}}>&#xe643;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(751)}</span>
              </div>:<div/>}
              {otherUtil.authorityFindCode("020103")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({cargoNoFinishShowPop:!this.state.cargoNoFinishShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe60c;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(353)}</span>
              </div>:<div/>}
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.getExcel(this.state.currCargoItem);
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe644;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1071)}Excel</span>
              </div>
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({cargoInfoPrint:this.state.currCargoItem},()=>{
                  const printArea = document.getElementById('printArea');
                  const iframe = document.createElement('IFRAME');
                  let doc = null;
                  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                  document.body.appendChild(iframe);
                  doc = iframe.contentWindow.document;
                  doc.write(printArea.innerHTML);
                  doc.close();
                  iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                  iframe.contentWindow.print();
                  if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                  this.setState({cargoInfoPrint:null});
                });
                //this.props.context.props.history.push({pathname:"/CargoSettleActivity",params:{cargoInfo:{id:this.state.currCargoItem.id}}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#8194AA'}}>&#xe867;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(745)}</span>
              </div>
            </div>
          </div>:<div/>}
          {(this.state.rightShowIndex==2&&this.state.currCargoItem.state==2)?<div style={{width:'100%',height:'100%'}}>
            <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(856)}{this.state.currCargoItem.targetName} {stringUtil.language(1175)}{this.state.currCargoItem.cargoNo}{stringUtil.language(1176)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{color:'#FF8C00'}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.receiveTime),'Y-M-D h:m:s')}{stringUtil.language(1052)}</span>
                <span style={{color:'#DAA520',marginLeft:5}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.settleTime),'Y-M-D h:m:s')}{stringUtil.language(1053)}</span></div>
              </div>
              {this.state.currCargoItem.carNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <span style={{fontSize:11}}>{stringUtil.language(857)}{this.state.currCargoItem.carNumber}</span>
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(430)}{this.state.currCargoItem.workerId==0?stringUtil.language(431):this.state.currCargoItem.workerName}</span>
                  {(()=>{if(this.state.currCargoItem.detailed==null&&this.state.nWmode==2)this.state.nWmode=0;})()}
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:0});
                  }}>{this.state.nWmode==0?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1021)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1021)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:1});
                  }}>{this.state.nWmode==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1022)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1022)}</i>}</div>
                  {this.state.currCargoItem.detailed?<div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.setState({nWmode:2});
                  }}>{this.state.nWmode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(432)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(432)}</i>}</div>:''}
                </div>
                <span>{this.state.currCargoItem.warehouseName}</span>
              </div>
            </div>
            <div style={{height:'82%',display:'flex',flexDirection:'column'}}>
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                {(()=>{this.state.cargoInfoTotal={sendNumber:0,transferNumber:0,processNumber:0,purchaseNumber:0,stockInOutNumber:0,saleNumber:0,balanceNumber:0,
                  sendWeight:0,transferWeight:0,processWeight:0,purchaseWeight:0,stockInOutWeight:0,saleWeight:0,balanceWeight:0,purchaseGoodsMoney:0,saleGoodsMoney:0,ownerOther:0}})()}
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1020)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1059)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1068)}</span>
                {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((item,key)=>{
                  this.state.cargoInfoTotal['costs'+item.costClauseId]=0;
                  return(<span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={key}>{stringUtil.subStr(item.costClauseName,5)}</span>);
                }):''}
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1056)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1057)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1055)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1044)}</span>
                <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',color:'#808080',fontSize:11,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{stringUtil.language(1058)}</span>
              </div>:''}
              {this.state.nWmode==0||this.state.nWmode==1?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.commodities.map((item,key)=>{
                  this.state.cargoInfoTotal.sendNumber=(this.state.cargoInfoTotal.sendNumber)+(item.sendNumber);
                  this.state.cargoInfoTotal.transferNumber=(this.state.cargoInfoTotal.transferNumber)+(item.transferNumber);
                  this.state.cargoInfoTotal.processNumber=(this.state.cargoInfoTotal.processNumber)+(item.processNumber);
                  this.state.cargoInfoTotal.purchaseNumber=(this.state.cargoInfoTotal.purchaseNumber)+(item.purchaseNumber);
                  this.state.cargoInfoTotal.stockInOutNumber=(this.state.cargoInfoTotal.stockInOutNumber)+(item.stockInOutNumber);
                  this.state.cargoInfoTotal.saleNumber=(this.state.cargoInfoTotal.saleNumber)+(item.saleNumber);
                  this.state.cargoInfoTotal.balanceNumber=(this.state.cargoInfoTotal.balanceNumber)+(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
                  this.state.cargoInfoTotal.sendWeight=(this.state.cargoInfoTotal.sendWeight).add(item.sendWeight);
                  this.state.cargoInfoTotal.transferWeight=(this.state.cargoInfoTotal.transferWeight).add(item.transferWeight);
                  this.state.cargoInfoTotal.processWeight=(this.state.cargoInfoTotal.processWeight).add(item.processWeight);
                  this.state.cargoInfoTotal.purchaseWeight=(this.state.cargoInfoTotal.purchaseWeight).add(item.purchaseWeight);
                  this.state.cargoInfoTotal.stockInOutWeight=(this.state.cargoInfoTotal.stockInOutWeight).add(item.stockInOutWeight);
                  this.state.cargoInfoTotal.saleWeight=(this.state.cargoInfoTotal.saleWeight).add(item.saleWeight);
                  this.state.cargoInfoTotal.balanceWeight=(this.state.cargoInfoTotal.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1));
                  this.state.cargoInfoTotal.purchaseGoodsMoney=(this.state.cargoInfoTotal.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
                  this.state.cargoInfoTotal.saleGoodsMoney=(this.state.cargoInfoTotal.saleGoodsMoney).add(item.saleGoodsMoney);
                  return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,borderStyle:'solid',borderWidth:0.5,borderColor:'#808080',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.saleNumber:arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                    {/* <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(arithUtil.fenToYuan(item.saleGoodsMoney)).div(item.saleNumber,2):(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</span> */}
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{arithUtil.fenToYuan(item.saleGoodsMoney)}</span>
                    {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                      let moneyTemp=0;
                      for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
                        if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
                      }
                      this.state.cargoInfoTotal['costs'+itemb.costClauseId]=(this.state.cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
                      if(moneyTemp)return(<span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>{arithUtil.fenToYuan(moneyTemp)}</span>);
                      else return(<span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}} key={keyb}>0</span>);
                    }):''}
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.processNumber:arithUtil.keToWeight(item.processWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?item.purchaseNumber:arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode)}</span>
                    <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),height:'100%',fontSize:16,textAlign:'center',borderStyle:'solid',borderWidth:0.5,borderColor:'#808080'}}>{this.state.nWmode==0?(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</span>
                  </div>)
                })}
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{stringUtil.language(890)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.saleNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.saleWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}></span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.cargoInfoTotal.saleGoodsMoney)}</span>
                  {this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts.map((itemb,keyb)=>{
                    return(<span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}} key={keyb}>{arithUtil.fenToYuan(this.state.cargoInfoTotal['costs'+itemb.costClauseId])}</span>);
                  }):''}
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.processNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.processWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.stockInOutNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.stockInOutWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.transferNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.transferWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.purchaseNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.purchaseWeight,this.state.weightMode)}</span>
                  <span style={{width:this.getWidthSize(9,this.state.currCargoItem.saleCosts),color:'#FFA500',fontSize:16,textAlign:'center'}}>{this.state.nWmode==0?this.state.cargoInfoTotal.balanceNumber:arithUtil.keToWeight(this.state.cargoInfoTotal.balanceWeight,this.state.weightMode)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{height:25,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'17%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <div style={{width:'83%',display:'flex',flexDirection:'row'}}>
                  <span style={{width:'23%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                  <span style={{width:'11%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                  <span style={{width:'14%',color:'#808080',textAlign:'center'}}>{stringUtil.language(962)}</span>
                  <span style={{width:'22%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                  <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                </div>
              </div>:''}
              {this.state.nWmode==2?<div style={{flexGrow:1,overflow:'auto'}}>
                {this.state.currCargoItem.detailed?this.state.currCargoItem.detailed.map((item,key)=>{
                  let totalItemInfo={number:0,weight:0,money:0};let totalItemInfoAvg={number:0,weight:0,moneyNumber:0,moneyWeight:0};
                  if(item.items==null) item.items=[];
                  for(let i=0;i<item.items.length;i++){
                    totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
                    totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
                    item.items[i].money = item.items[i].goodsMoney;
                    for(let j=0;j<item.items[i].costs.length;j++){
                       item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
                    }
                    totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
                    if(item.items[i].mode==1){
                      totalItemInfoAvg.number = (totalItemInfoAvg.number).add(item.items[i].number);
                      totalItemInfoAvg.moneyNumber = (totalItemInfoAvg.moneyNumber).add(item.items[i].goodsMoney);
                    }
                    if(item.items[i].mode==0){
                      totalItemInfoAvg.weight = (totalItemInfoAvg.weight).add(item.items[i].weight);
                      totalItemInfoAvg.moneyWeight = (totalItemInfoAvg.moneyWeight).add(item.items[i].goodsMoney);
                    }
                  }
                  return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                    <span style={{width:'17%',color:'#000',fontSize:16,paddingTop:7}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                    <div style={{width:'83%',display:'flex',flexDirection:'column'}}>
                      <span style={{height:30,color:'#52A056',fontSize:16,fontWeight:'bold',paddingTop:7}}>
                        {/* ，{arithUtil.fenToYuan(totalItemInfo.money.div(totalItemInfo.number))}{stringUtil.currency(1)}/stringUtil.language(1173) */}
                        {stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money)}{stringUtil.currency(1)}
                        {totalItemInfoAvg.number?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyNumber).div(totalItemInfoAvg.number,2)+stringUtil.currency(1)+'/'+stringUtil.language(1173):''}{totalItemInfoAvg.weight?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyWeight).div(arithUtil.keToWeight(totalItemInfoAvg.weight,this.state.weightMode),2)+stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):''}
                        {totalItemInfo.number?'，'+arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode).div(totalItemInfo.number,2)+(this.state.weightMode?stringUtil.language(1172)+'/'+stringUtil.language(1173):stringUtil.language(1023)+'/'+stringUtil.language(1173)):''}
                      </span>
                      {item.items.map((itemb,keyb)=>{
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                          <span style={{width:'23%',color:'#808080',fontSize:16}}>{itemb.customerName}</span>
                          <span style={{width:'11%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.number}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                          <span style={{width:'14%',color:'#808080',fontSize:16,textAlign:'center'}}>{(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</span>
                          <span style={{width:'22%',color:'#808080',fontSize:16,textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                        </div>);
                      })}
                    </div>
                  </div>)
                }):''}
              </div>:''}
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                {(()=>{this.state.otherMoneyItems = [];
                this.state.docMoney = this.state.currCargoItem.saleGoodsMoney;
                this.state.ownerMoney = this.state.currCargoItem.saleGoodsMoney;
                if(this.state.currCargoItem.saleCosts){
                  for(let i=0;i<this.state.currCargoItem.saleCosts.length;i++){
                    this.state.docMoney = (this.state.docMoney).add(this.state.currCargoItem.saleCosts[i].money);
                    if(this.state.currCargoItem.cargoMode==0&&this.state.currCargoItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                      this.state.ownerMoney = (this.state.ownerMoney).add(this.state.currCargoItem.saleCosts[i].money);
                    }
                    this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.saleCosts[i].costClauseName,money:this.state.currCargoItem.saleCosts[i].money});
                  }
                }
                this.state.otherMoneyItems.push({costClauseName:stringUtil.language(746),money:this.state.docMoney});
                if(this.state.currCargoItem.cargoMode==0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(592),money:this.state.ownerMoney});
                if(this.state.currCargoItem.cargoMode==2)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(594),money:this.state.ownerMoney});
                if(this.state.currCargoItem.cargoMode==1||this.state.currCargoItem.cargoMode==2){
                  if(this.state.currCargoItem.purchaseCosts){
                    let caigouchenben=this.state.currCargoItem.purchaseGoodsMoney;
                    for(let i=0;i<this.state.currCargoItem.purchaseCosts.length;i++){
                      caigouchenben=caigouchenben.add(this.state.currCargoItem.purchaseCosts[i].money);
                      this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.purchaseCosts[i].costClauseName,money:this.state.currCargoItem.purchaseCosts[i].money});
                    }
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(610),money:caigouchenben});
                  }
                  if(this.state.currCargoItem.processCosts){
                    let jiagongfei=0;
                    for(let i=0;i<this.state.currCargoItem.processCosts.length;i++){
                      jiagongfei=jiagongfei.add(this.state.currCargoItem.processCosts[i].money);
                      this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.processCosts[i].costClauseName,money:this.state.currCargoItem.processCosts[i].money});
                    }
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(993),money:jiagongfei});
                  }
                  this.state.otherMoneyItems.push({costClauseName:stringUtil.language(747),money:this.state.currCargoItem.transferMoney});
                  this.state.otherMoneyItems.push({costClauseName:stringUtil.language(748),money:this.state.currCargoItem.transferToMoney});
                }
                if(this.state.currCargoItem.rebateMoney!=0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(1150),money:this.state.currCargoItem.rebateMoney});
                if(this.state.currCargoItem.disburdenMoney!=0)this.state.otherMoneyItems.push({costClauseName:JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,money:this.state.currCargoItem.disburdenMoney});
                if(this.state.currCargoItem.entrustMoney!=0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(941),money:this.state.currCargoItem.entrustMoney});
                if(this.state.currCargoItem.discountMoney!=0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(543),money:this.state.currCargoItem.discountMoney});
                if(this.state.currCargoItem.expends)for(let i=0;i<this.state.currCargoItem.expends.length;i++){
                  this.state.otherMoneyItems.push({costClauseName:this.state.currCargoItem.expends[i].ownerExpendClauseName,money:this.state.currCargoItem.expends[i].money});
                }
                })()}
                {this.state.otherMoneyItems.map((item,key)=>{
                    return(<div style={{width:'33.3%',height:25,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                      <span style={{fontSize:13}}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</span>
                    </div>);
                })}
                <div style={{width:'33.3%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{fontSize:13}}>{stringUtil.language(749)}：</span><span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currCargoItem.balance)}</span>
                </div>
                {this.state.currCargoItem.receiveDescribe?<span style={{fontSize:12}}>{stringUtil.language(429)}:{this.state.currCargoItem.receiveDescribe}</span>:''}
                {this.state.currCargoItem.finishDescribe?<span style={{fontSize:12}}>{stringUtil.language(750)}:{this.state.currCargoItem.finishDescribe}</span>:''}
              </div>
            </div>
            <div style={{height:'8%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
              {otherUtil.authorityFindCode("020201")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcureOrdersActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(735),navigatePage:'ProcureOrdersActivity',paramsPage:{pageIndex:2,cargoItemInfo:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#53B8DB'}}>&#xe613;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(735)}</span>
              </div>:<div/>}
              {otherUtil.authorityFindCode("020102")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoTransferQuarrelActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(751),navigatePage:'CargoTransferQuarrelActivity',paramsPage:{cargoItemInfo:this.state.currCargoItem}}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#00F'}}>&#xe643;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(751)}</span>
              </div>:<div/>}
              {otherUtil.authorityFindCode("020103")!=null?<div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({cargoNoFinishShowPop:!this.state.cargoNoFinishShowPop});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe60c;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(353)}</span>
              </div>:<div/>}
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.getExcel(this.state.currCargoItem);
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe644;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(1071)}Excel</span>
              </div>
              <div style={{width:100,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.setState({cargoInfoPrint:this.state.currCargoItem},()=>{
                  const printArea = document.getElementById('printArea');
                  const iframe = document.createElement('IFRAME');
                  let doc = null;
                  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                  document.body.appendChild(iframe);
                  doc = iframe.contentWindow.document;
                  doc.write(printArea.innerHTML);
                  doc.close();
                  iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                  iframe.contentWindow.print();
                  if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                  this.setState({cargoInfoPrint:null});
                });
                //this.props.context.props.history.push({pathname:"/CargoSettleActivity",params:{cargoInfo:{id:this.state.currCargoItem.id}}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:20,color:'#8194AA'}}>&#xe867;</i>
                <span style={{color:'#24984D'}}>{stringUtil.language(745)}</span>
              </div>
            </div>
          </div>:<div/>}
        </div>
        <MaskModal visible={this.state.delSelfComShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                {stringUtil.language(104)}
            </div>}
            okBnEvents={(event) => {
                this.setState({delSelfComShowPop: false});
                stringUtil.httpRequest(urlUtil.SALE_CARGO_COMMODITY_URL+'/'+this.state.paramsInfo.cargoId,{cargoCommodityId:JSON.parse(this.state.paramsInfo.currBatchCommodityItem).id},['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(530));
                        this.state.delSelfComShowPop = false;
                        stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+this.state.paramsInfo.cargoId,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            this.state.currCargoItem = successResponse.data;
                            this.setState({rightShowIndex:this.state.currCargoItem.cargoMode});//-1库存 0代销 1stringUtil.language(1051) 2炒货
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delSelfComShowPop: false})}}/>
        <MaskModal title={(this.state.currCargoItem.cargoMode==1?stringUtil.language(1051)+(this.state.currCargoItem.targetName?'_'+stringUtil.subStr(this.state.currCargoItem.targetName,7):''):stringUtil.subStr(this.state.currCargoItem.targetName,7))+' '+this.state.currCargoItem.cargoNo+stringUtil.language(1176)} visible={this.state.cargoCommodityPriceShowPop} width={500} height={arithUtil.ProportionTo(document.body.clientHeight,0.65)}
            content={<div style={{width:'100%',height:'100%',overflow:'auto'}} key={this.state.currCargoItem.id}>
              {this.state.currBatchItem!=null&&this.state.currBatchItem.commodities!=null?this.state.currBatchItem.commodities.map((item,key)=>{
                return(<div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                  <span>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <input style={{width:90,height:30,color:'#F00',fontWeight:'bold',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',borderRadius:2,textAlign:'center',display:'flex',flexGrow:1}} type='number' placeholder='0' defaultValue={item.numMakePrice?arithUtil.fenToYuan(item.numMakePrice):''} onChange={(event)=>{
                      this.state.currBatchItem.commodities[key].numMakePrice = arithUtil.yuanToFen(event.target.value);
                    }}/><span> /{stringUtil.language(1173)}&nbsp;&nbsp;&nbsp;</span>
                    <input style={{width:90,height:30,color:'#F00',fontWeight:'bold',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',borderRadius:2,textAlign:'center',display:'flex',flexGrow:1}} type='number' placeholder='0' defaultValue={item.weightMakePrice?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightMakePrice),0,this.state.weightMode):''} onChange={(event)=>{
                      this.state.currBatchItem.commodities[key].weightMakePrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                    }}/><span> /{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
                  </div>
                </div>);
              }):''}
            </div>}
            okBnEvents={(event) => {
              this.setState({cargoCommodityPriceShowPop:false});
              let cargoMakePriceVO = [];
              for(let i=0;i<this.state.currBatchItem.commodities.length;i++){
                if(this.state.currBatchItem.commodities[i].numMakePrice==null)this.state.currBatchItem.commodities[i].numMakePrice=0;
                if(this.state.currBatchItem.commodities[i].weightMakePrice==null)this.state.currBatchItem.commodities[i].weightMakePrice=0;
                if(this.state.currBatchItem.commodities[i].numMakePrice>0||this.state.currBatchItem.commodities[i].weightMakePrice>0){
                  cargoMakePriceVO.push({
                    cargoCommodityId:this.state.currBatchItem.commodities[i].id,
                    numMakePrice:this.state.currBatchItem.commodities[i].numMakePrice,
                    weightMakePrice:this.state.currBatchItem.commodities[i].weightMakePrice,
                  });
                }
              }
              stringUtil.httpRequest(urlUtil.SALE_CARGO_MAKE_PRICE_URL+'/'+this.state.currBatchItem.id,cargoMakePriceVO,['json','put',1],(successResponse)=>{
                if (successResponse.status==200) {
                  ToastBox.success(stringUtil.language(388));
                  stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+this.state.currBatchItem.id,null,['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        this.state.currCargoItem = successResponse.data;
                        this.setState({rightShowIndex:this.state.currCargoItem.cargoMode});//-1库存 0代销 1stringUtil.language(1051) 2炒货
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
              },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({cargoCommodityPriceShowPop:false})}}/>
        <MaskModal visible={this.state.quashTransferShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                {stringUtil.language(46)}？
              </div>}
            okBnEvents={(event) => {
                this.setState({quashTransferShowPop: false});
                stringUtil.httpRequest(urlUtil.SALE_CARGO_TRANSFER_URL+'/'+this.state.currCargoItem.id,null,['key','delete',1],(successResponse)=>{
                  if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(388));
                    this.initCargoTotalInfo();
                    this.initWarehouseCommodityItemData();
                    this.getNowDataList();
                    this.getFinishDataList();
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({quashTransferShowPop: false})}}/>
        <MaskModal title={this.state.paramsInfo?JSON.parse(this.state.paramsInfo.currBatchCommodityItem).commodityName:stringUtil.language(247)} visible={this.state.cargoComEditShowPop} width={750} height={arithUtil.ProportionTo(document.body.clientHeight,0.75)}
            content={<div style={{width:'100%',height:'100%',overflow:'auto'}} key={this.state.addShowPopIndex}>
                <OwnerCargoCommodityEditView paramsInfo={this.state.paramsInfo?this.state.paramsInfo:{}} successEvent={(result1)=>{
                  if(result1==-1){
                    this.state.cargoComEditShowPop = false;
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+this.state.paramsInfo.cargoId,null,['key','get',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          this.state.currCargoItem = successResponse.data;
                          this.setState({rightShowIndex:this.state.currCargoItem.cargoMode});//-1库存 0代销 1stringUtil.language(1051) 2炒货
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }else{
                    if(this.state.paramsInfo==null)this.state.paramsInfo={};
                    this.state.paramsInfo.currBatchCommodityItem = JSON.stringify(result1);
                  }
                }} context={this}/>
              </div>}
              okBnEvents={(event) => {
                if(this.state.paramsInfo==null||this.state.paramsInfo.currBatchCommodityItem==null||JSON.parse(this.state.paramsInfo.currBatchCommodityItem).sendNumber==null||JSON.parse(this.state.paramsInfo.currBatchCommodityItem).sendNumber==0){
                  ToastBox.error(stringUtil.language(166)+'！');return;
                }
                let resCurrBatchCommodityItem = JSON.parse(this.state.paramsInfo.currBatchCommodityItem);
                let cargoUpdateCommodityVO=[{
                    cargoCommodityId:resCurrBatchCommodityItem.id,
                    sendNumber:resCurrBatchCommodityItem.sendNumber,
                    sendWeight:resCurrBatchCommodityItem.sendWeight?resCurrBatchCommodityItem.sendWeight:0,
                    standard:resCurrBatchCommodityItem.standard,
                    reduce:resCurrBatchCommodityItem.reduce,
                    costs:[]
                }];
                if(resCurrBatchCommodityItem.costs){
                    for(let i=0;i<resCurrBatchCommodityItem.costs.length;i++){
                        cargoUpdateCommodityVO[0].costs.push({
                          costClauseId:resCurrBatchCommodityItem.costs[i].costClauseId,
                          money:resCurrBatchCommodityItem.costs[i].money
                        });
                    }
                }
                stringUtil.httpRequest(urlUtil.SALE_CARGO_COMMODITY_UPDATE_URL+'/'+this.state.paramsInfo.cargoId,cargoUpdateCommodityVO,['json','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                      this.state.cargoComEditShowPop = false;
                      ToastBox.success(stringUtil.language(388));
                      stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+this.state.paramsInfo.cargoId,null,['key','get',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.state.currCargoItem = successResponse.data;
                            this.setState({rightShowIndex:this.state.currCargoItem.cargoMode});//-1库存 0代销 1stringUtil.language(1051) 2炒货
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }} closeBnEvents={() => {this.setState({cargoComEditShowPop:false})}}/>
        <MaskModal visible={this.state.cargoNoFinishShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {stringUtil.language(122)}？
              </div>}
            okBnEvents={(event) => {
                this.setState({cargoNoFinishShowPop: false});
                stringUtil.httpRequest(urlUtil.SALE_CARGO_FINISH_CANCEL_URL+'/'+this.state.currCargoItem.id,null,['key','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.initCargoTotalInfo();
                        this.initWarehouseCommodityItemData();
                        this.getNowDataList();
                        this.getFinishDataList();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({cargoNoFinishShowPop: false})}}/>
        <MaskModal visible={this.state.cargoFinishShowPop} title={(this.state.currCargoItem.cargoMode==1?stringUtil.language(1051)+(this.state.currCargoItem.targetName?'_'+this.state.currCargoItem.targetName:''):this.state.currCargoItem.targetName)+'/'+stringUtil.subStr(this.state.currCargoItem.cargoNo,10)+stringUtil.language(741)} width={arithUtil.pxToDp(400)} height={arithUtil.ProportionTo(document.body.clientHeight,0.65)}
            content={this.state.rightShowIndex==0?<div style={{width:'100%',height:'100%',paddingLeft:10,paddingRight:10}} key={this.state.finishShowPopIndex}>
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    {(()=>{
                      this.state.ownerMoney = this.state.currCargoItem.saleGoodsMoney;
                      for(let i=0;i<(this.state.currCargoItem.saleCosts?this.state.currCargoItem.saleCosts:[]).length;i++){
                        if(this.state.currCargoItem.cargoMode==0&&this.state.currCargoItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                          this.state.ownerMoney = (this.state.ownerMoney).add(this.state.currCargoItem.saleCosts[i].money);
                        }
                      }
                    })()}
                    <span style={{fontWeight:'bold'}}>{stringUtil.language(592)}：{arithUtil.fenToYuan(this.state.ownerMoney)} {stringUtil.currency(1)}</span>
                </div>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span> + {stringUtil.language(752)}：</span>
                    <input style={arithUtil.stylesAdd(styles.inputText,{width:120,height:30})} type='number' defaultValue={this.state.cargoFinishItem.rebateMoney!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.rebateMoney):''} placeholder='0' onChange={(event)=>{
                      this.state.cargoFinishItem.rebateMoney = arithUtil.yuanToFen(event.target.value);
                      this.refs.finishBalanceShishiRef.innerHTML=arithUtil.fenToYuan((this.state.currCargoItem.balance).add(this.state.cargoFinishItem.rebateMoney?this.state.cargoFinishItem.rebateMoney:0).sub(this.state.cargoFinishItem.entrustMoney?this.state.cargoFinishItem.entrustMoney:0).sub(this.state.cargoFinishItem.discountMoney?this.state.cargoFinishItem.discountMoney:0).sub(this.state.cargoFinishItem.disburdenMoney?this.state.cargoFinishItem.disburdenMoney:0));
                    }}/><span> {stringUtil.currency(1)}</span>
                </div>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span> -&nbsp;&nbsp;{stringUtil.language(753)}：</span>
                    <input style={arithUtil.stylesAdd(styles.inputText,{width:120,height:30})} type='number' defaultValue={this.state.cargoFinishItem.entrustMoney!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustMoney):''} placeholder='0' onChange={(event)=>{
                      this.state.cargoFinishItem.entrustMoney = arithUtil.yuanToFen(event.target.value);
                      this.refs.finishBalanceShishiRef.innerHTML=arithUtil.fenToYuan((this.state.currCargoItem.balance).add(this.state.cargoFinishItem.rebateMoney?this.state.cargoFinishItem.rebateMoney:0).sub(this.state.cargoFinishItem.entrustMoney?this.state.cargoFinishItem.entrustMoney:0).sub(this.state.cargoFinishItem.discountMoney?this.state.cargoFinishItem.discountMoney:0).sub(this.state.cargoFinishItem.disburdenMoney?this.state.cargoFinishItem.disburdenMoney:0));
                    }}/><span> {stringUtil.currency(1)}</span>
                    <span style={{color:'#00F',borderBottomStyle:'solid',borderBottomColor:'#00F',borderBottomWidth:1,fontSize:15,marginLeft:10,cursor:'pointer'}} onClick={()=>{this.setState({entrustMoneyShowPop:true});}}>{stringUtil.language(1151)}</span>
                </div>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span> -&nbsp;&nbsp;{stringUtil.language(543)}：</span>
                    <input style={arithUtil.stylesAdd(styles.inputText,{width:120,height:30})} type='number' defaultValue={this.state.cargoFinishItem.discountMoney?arithUtil.fenToYuan(this.state.cargoFinishItem.discountMoney):''} placeholder='0' onChange={(event)=>{
                      this.state.cargoFinishItem.discountMoney = arithUtil.yuanToFen(event.target.value);
                      this.refs.finishBalanceShishiRef.innerHTML=arithUtil.fenToYuan((this.state.currCargoItem.balance).add(this.state.cargoFinishItem.rebateMoney?this.state.cargoFinishItem.rebateMoney:0).sub(this.state.cargoFinishItem.entrustMoney?this.state.cargoFinishItem.entrustMoney:0).sub(this.state.cargoFinishItem.discountMoney?this.state.cargoFinishItem.discountMoney:0).sub(this.state.cargoFinishItem.disburdenMoney?this.state.cargoFinishItem.disburdenMoney:0));
                    }}/><span> {stringUtil.currency(1)}</span>
                </div>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span> -&nbsp;&nbsp;{JSON.parse(localStorage.getItem("currentStallItem")).disburdenText}：</span>
                    <input style={arithUtil.stylesAdd(styles.inputText,{width:120,height:30})} type='number' defaultValue={this.state.cargoFinishItem.disburdenMoney!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.disburdenMoney):''} placeholder='0' onChange={(event)=>{
                      this.state.cargoFinishItem.disburdenMoney = arithUtil.yuanToFen(event.target.value);
                      this.refs.finishBalanceShishiRef.innerHTML=arithUtil.fenToYuan((this.state.currCargoItem.balance).add(this.state.cargoFinishItem.rebateMoney?this.state.cargoFinishItem.rebateMoney:0).sub(this.state.cargoFinishItem.entrustMoney?this.state.cargoFinishItem.entrustMoney:0).sub(this.state.cargoFinishItem.discountMoney?this.state.cargoFinishItem.discountMoney:0).sub(this.state.cargoFinishItem.disburdenMoney?this.state.cargoFinishItem.disburdenMoney:0));
                    }}/><span> {stringUtil.currency(1)}</span>
                    <span style={{color:'#00F',borderBottomStyle:'solid',borderBottomColor:'#00F',borderBottomWidth:1,fontSize:15,marginLeft:10,cursor:'pointer'}} onClick={()=>{this.setState({disburdenMoneyShowPop:true});}}>{stringUtil.language(1151)}</span>
                </div>
                <div style={{width:'100%',position:'absolute',left:0,top:190,right:0,bottom:30,overflow:'auto'}}>
                  {this.state.currCargoItem.expends?this.state.currCargoItem.expends.map((item,key)=>{
                    if(item.money!=0){
                      return(<div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingRight:10}} key={key}>
                        <span> &nbsp;&nbsp;-&nbsp;&nbsp;{stringUtil.subStr(item.ownerExpendClauseName,6)}：{arithUtil.fenToYuan(item.money)} {stringUtil.currency(1)}</span>
                        <span style={{color:'#FF8C00',fontSize:13}}>{stringUtil.language(994)}</span>
                      </div>);
                    }else{return(<div style={{position:'absolute'}} key={key}/>)}
                  }):''}
                  {this.state.currCargoItem.advanceExpends?this.state.currCargoItem.advanceExpends.map((item,key)=>{
                    if(item.unPay>0){
                      return(<div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingRight:10,cursor:'pointer'}} onClick={()=>{
                        if(item.unPay>0){//还stringUtil.language(1087)完
                          this.state.ownerExpendInfo={
                            clauseId:item.clauseId,
                            clauseName:item.clauseName,
                            cargoId:this.state.currCargoItem.id,
                            targetType:this.state.currCargoItem.cargoMode,
                            targetId:this.state.currCargoItem.targetId,
                            targetName:this.state.currCargoItem.targetName,
                            cargoNo:this.state.currCargoItem.cargoNo,
                            money:item.unPay
                          };
                          this.setState({cargoFinishShowPop: false,ownerExpendAddShowPop:true});
                        }
                      }} key={key}>
                        <span>{stringUtil.subStr(item.clauseName,6)}：{arithUtil.fenToYuan(item.money)} {stringUtil.currency(1)}</span>
                        <span style={{color:item.unPay>0?'#00F':'#FF8C00',borderBottomStyle:'solid',borderBottomColor:'#00F',borderBottomWidth:item.unPay>0?1:0,fontSize:13}}>{item.unPay>0?stringUtil.language(995):stringUtil.language(994)}</span>
                      </div>);
                    }else{return(<div style={{position:'absolute'}} key={key}/>);}
                  }):''}
                  <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingRight:10,cursor:'pointer'}} onClick={()=>{
                    this.state.ownerExpendInfo={
                      clauseId:null,
                      clauseName:null,
                      cargoId:this.state.currCargoItem.id,
                      targetType:this.state.currCargoItem.cargoMode,
                      targetId:this.state.currCargoItem.targetId,
                      targetName:this.state.currCargoItem.targetName,
                      cargoNo:this.state.currCargoItem.cargoNo,
                      money:null
                    };
                    this.setState({cargoFinishShowPop: false,ownerExpendAddShowPop:true});
                  }}><span style={{color:'#00F',borderBottomStyle:'solid',borderBottomColor:'#00F',borderBottomWidth:1,fontSize:13}}>{stringUtil.language(9)}</span>
                  </div>
                </div>
                <div style={{width:'95%',height:30,position:'absolute',bottom:0,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                  <input style={arithUtil.stylesAdd(styles.textInput,{display:'flex',flexGrow:1,height:25,fontSize:10,padding:0,marginRight:10,borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',textAlign:'left'})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} onChange={(event)=>{
                    this.state.cargoFinishItem.finishDescribe = event.target.value;
                  }}/>
                  <span>{stringUtil.language(754)}:</span><span style={{color:'#FF8C00',fontSize:20,fontWeight:'bold'}} ref='finishBalanceShishiRef'>{arithUtil.fenToYuan((this.state.currCargoItem.balance).add(this.state.cargoFinishItem.rebateMoney?this.state.cargoFinishItem.rebateMoney:0).sub(this.state.cargoFinishItem.entrustMoney?this.state.cargoFinishItem.entrustMoney:0).sub(this.state.cargoFinishItem.discountMoney?this.state.cargoFinishItem.discountMoney:0).sub(this.state.cargoFinishItem.disburdenMoney?this.state.cargoFinishItem.disburdenMoney:0))}</span><span>{stringUtil.currency(1)}</span>
                </div>
            </div>:this.state.rightShowIndex==1||this.state.rightShowIndex==2?<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
              <span style={{fontWeight:'bold'}}>{stringUtil.language(749)}：{arithUtil.fenToYuan(this.state.currCargoItem.balance)} {stringUtil.currency(1)}</span>
              <span>{stringUtil.language(167)}？</span>
            </div>:''}
            okBnEvents={(event) => {
              this.setState({cargoFinishShowPop: false});
              stringUtil.httpRequest(urlUtil.SALE_CARGO_FINISH_URL+'/'+this.state.currCargoItem.id,{
                rebateMoney:(this.state.cargoFinishItem.rebateMoney!=null&&!isNaN(this.state.cargoFinishItem.rebateMoney))?this.state.cargoFinishItem.rebateMoney:0,
                entrustMoney:(this.state.cargoFinishItem.entrustMoney!=null&&!isNaN(this.state.cargoFinishItem.entrustMoney))?this.state.cargoFinishItem.entrustMoney:0,
                discountMoney:(this.state.cargoFinishItem.discountMoney!=null&&!isNaN(this.state.cargoFinishItem.discountMoney))?this.state.cargoFinishItem.discountMoney:0,
                disburdenMoney:(this.state.cargoFinishItem.disburdenMoney!=null&&!isNaN(this.state.cargoFinishItem.disburdenMoney))?this.state.cargoFinishItem.disburdenMoney:0,
                entrustMode:this.state.cargoFinishItem.entrustMode!=null?this.state.cargoFinishItem.entrustMode:0,
                entrustUnitPrice:this.state.cargoFinishItem.entrustUnitPrice!=null?this.state.cargoFinishItem.entrustUnitPrice:0,
                disburdenMode:this.state.cargoFinishItem.disburdenMode!=null?this.state.cargoFinishItem.disburdenMode:0,
                disburdenUnitPrice:this.state.cargoFinishItem.disburdenUnitPrice!=null?this.state.cargoFinishItem.disburdenUnitPrice:0,
                finishDescribe:this.state.cargoFinishItem.finishDescribe
              },['key','put',1],(successResponse)=>{
                  if (successResponse.status==200) {
                      ToastBox.success(stringUtil.language(388));
                      this.initCargoTotalInfo();
                      this.initWarehouseCommodityItemData();
                      this.getNowDataList();
                      this.getFinishDataList();
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
              },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({cargoFinishShowPop: false})}}/>
          <MaskModal visible={this.state.entrustMoneyShowPop} title={stringUtil.language(354)} width={400} height={250} context={this}
              content={<div style={{height:'100%',paddingLeft:10}} key={this.state.currCargoItem.id}>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(1152)}：</span><div style={{justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.entrustMode=0;
                      this.state.cargoFinishItem.entrustUnitPrice=0;
                      this.refs.moneyDangkouRef.value=this.state.cargoFinishItem.entrustUnitPrice!=null?(this.state.cargoFinishItem.entrustMode!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPrice):this.state.cargoFinishItem.entrustUnitPrice):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{!this.state.cargoFinishItem.entrustMode?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1029)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1029)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.entrustMode=1;
                      this.state.cargoFinishItem.entrustUnitPrice=0;
                      this.refs.moneyDangkouRef.value=this.state.cargoFinishItem.entrustUnitPrice!=null?(this.state.cargoFinishItem.entrustMode!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPrice):this.state.cargoFinishItem.entrustUnitPrice):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.entrustMode==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1115)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1115)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.entrustMode=2;
                      this.state.cargoFinishItem.entrustUnitPrice=0;
                      this.refs.moneyDangkouRef.value=this.state.cargoFinishItem.entrustUnitPrice!=null?(this.state.cargoFinishItem.entrustMode!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPrice):this.state.cargoFinishItem.entrustUnitPrice):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.entrustMode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1114)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1114)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.entrustMode=3;
                      this.state.cargoFinishItem.entrustUnitPrice=0;
                      this.refs.moneyDangkouRef.value=this.state.cargoFinishItem.entrustUnitPrice!=null?(this.state.cargoFinishItem.entrustMode!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPrice):this.state.cargoFinishItem.entrustUnitPrice):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.entrustMode==3?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(355)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(355)}</i>}</div>
                </div>
                {this.state.cargoFinishItem.entrustMode?<span style={{height:30,color:'#F00',textAlignVertical:'center'}}>{this.state.cargoFinishItem.entrustMode==1?stringUtil.language(861)+this.state.currCargoItem.saleNumber+stringUtil.language(1173):this.state.cargoFinishItem.entrustMode==2?stringUtil.language(862)+arithUtil.keToWeight(this.state.currCargoItem.saleWeight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):((()=>{
                  let saleTotalMoney=this.state.currCargoItem.saleGoodsMoney;
                  if(this.state.currCargoItem.saleCosts){
                    for(let i=0;i<this.state.currCargoItem.saleCosts.length;i++){
                      if(this.state.currCargoItem.cargoMode==0&&this.state.currCargoItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                        saleTotalMoney=saleTotalMoney.add(this.state.currCargoItem.saleCosts[i].money);
                      }
                    }
                  }return stringUtil.language(883)+arithUtil.fenToYuan(saleTotalMoney)+stringUtil.currency(1);
                })())}</span>:''}
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(1050)}：</span><input style={{height:30,color:'#F00',fontWeight:'bold',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',borderRadius:2,textAlign:'center',display:'flex',flexGrow:1}} type='number' placeholder='0' defaultValue={this.state.cargoFinishItem.entrustUnitPrice!=null?(this.state.cargoFinishItem.entrustMode!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPrice):this.state.cargoFinishItem.entrustUnitPrice):''} ref='moneyDangkouRef' onChange={(event)=>{
                    this.state.cargoFinishItem.entrustUnitPrice = this.state.cargoFinishItem.entrustMode!=3?arithUtil.yuanToFen(event.target.value):parseFloat(event.target.value);
                    let finishStallMoney=0;
                    if(this.state.cargoFinishItem.entrustMode==1){
                      finishStallMoney=(this.state.cargoFinishItem.entrustUnitPrice).mul(this.state.currCargoItem.saleNumber).round();
                    }else if(this.state.cargoFinishItem.entrustMode==2){
                      finishStallMoney=(this.state.cargoFinishItem.entrustUnitPrice).mul(arithUtil.keToWeight(this.state.currCargoItem.saleWeight,this.state.weightMode)).round();
                    }else if(this.state.cargoFinishItem.entrustMode==3){
                      let saleTotalMoney=this.state.currCargoItem.saleGoodsMoney;
                      if(this.state.currCargoItem.saleCosts){
                        for(let i=0;i<this.state.currCargoItem.saleCosts.length;i++){
                          if(this.state.currCargoItem.cargoMode==0&&this.state.currCargoItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                            saleTotalMoney=saleTotalMoney.add(this.state.currCargoItem.saleCosts[i].money);
                          }
                        }
                      }
                      finishStallMoney=saleTotalMoney.mul((this.state.cargoFinishItem.entrustUnitPrice).div(100)).round();
                    }
                    this.refs.finishStallMoney.innerHTML=stringUtil.language(1153)+'：'+arithUtil.fenToYuan(finishStallMoney)+stringUtil.currency(1);
                  }}/><span> {!this.state.cargoFinishItem.entrustMode?stringUtil.currency(1):this.state.cargoFinishItem.entrustMode==1?stringUtil.currency(1)+'/'+stringUtil.language(1173):this.state.cargoFinishItem.entrustMode==2?stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):'%'}</span>
                </div>
                {this.state.cargoFinishItem.entrustMode?<span ref='finishStallMoney' style={{height:30,color:'#F00',fontWeight:'bold',textAlignVertical:'center'}}>{stringUtil.language(1153)}：0{stringUtil.currency(1)}</span>:''}
              </div>}
              okBnEvents={(event) => {
                this.setState({entrustMoneyShowPop:false});
                if(this.state.cargoFinishItem.entrustMode==null){this.state.cargoFinishItem.entrustMode=0;}
                if(this.state.cargoFinishItem.entrustUnitPrice==null){this.state.cargoFinishItem.entrustUnitPrice=0;}
                if(this.state.cargoFinishItem.entrustMode==0)this.state.cargoFinishItem.entrustMoney=(this.state.cargoFinishItem.entrustUnitPrice).round();
                else if(this.state.cargoFinishItem.entrustMode==1)this.state.cargoFinishItem.entrustMoney=(this.state.cargoFinishItem.entrustUnitPrice).mul(this.state.currCargoItem.saleNumber).round();
                else if(this.state.cargoFinishItem.entrustMode==2)this.state.cargoFinishItem.entrustMoney=(this.state.cargoFinishItem.entrustUnitPrice).mul(arithUtil.keToWeight(this.state.currCargoItem.saleWeight,this.state.weightMode)).round();
                else if(this.state.cargoFinishItem.entrustMode==3){
                  let saleTotalMoney=this.state.currCargoItem.saleGoodsMoney;
                  if(this.state.currCargoItem.saleCosts){
                    for(let i=0;i<this.state.currCargoItem.saleCosts.length;i++){
                      if(this.state.currCargoItem.cargoMode==0&&this.state.currCargoItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                        saleTotalMoney=saleTotalMoney.add(this.state.currCargoItem.saleCosts[i].money);
                      }
                    }
                  }
                  this.state.cargoFinishItem.entrustMoney=saleTotalMoney.mul((this.state.cargoFinishItem.entrustUnitPrice).div(100)).round();
                }
                this.setState({cargoFinishItem:this.state.cargoFinishItem});
              }} closeBnEvents={() => {this.setState({entrustMoneyShowPop: false});}}/>
          <MaskModal visible={this.state.disburdenMoneyShowPop} title={JSON.parse(localStorage.getItem("currentStallItem")).disburdenText+stringUtil.language(1151)} width={400} height={250} context={this}
              content={<div style={{height:'100%',paddingLeft:10}} key={this.state.currCargoItem.id}>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(1152)}:</span><div style={{justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.disburdenMode=0;
                      this.state.cargoFinishItem.disburdenUnitPrice=0;
                      this.refs.moneyXiehuoRef.value=this.state.cargoFinishItem.disburdenUnitPrice!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.disburdenUnitPrice):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{!this.state.cargoFinishItem.disburdenMode?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1029)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1029)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.disburdenMode=1;
                      this.state.cargoFinishItem.disburdenUnitPrice=0;
                      this.refs.moneyXiehuoRef.value=this.state.cargoFinishItem.disburdenUnitPrice!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.disburdenUnitPrice):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.disburdenMode==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1115)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1115)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.disburdenMode=2;
                      this.state.cargoFinishItem.disburdenUnitPrice=0;
                      this.refs.moneyXiehuoRef.value=this.state.cargoFinishItem.disburdenUnitPrice!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.disburdenUnitPrice):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.disburdenMode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1114)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1114)}</i>}</div>
                </div>
                {this.state.cargoFinishItem.disburdenMode?<span style={{height:30,color:'#F00',textAlignVertical:'center'}}>{this.state.cargoFinishItem.disburdenMode==1?stringUtil.language(861)+this.state.currCargoItem.saleNumber+stringUtil.language(1173):stringUtil.language(862)+(arithUtil.keToWeight(this.state.currCargoItem.saleWeight,0).div(1000,3))+stringUtil.language(1197)}</span>:''}
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(1050)}:</span><input style={{height:30,color:'#F00',fontWeight:'bold',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',borderRadius:2,textAlign:'center',display:'flex',flexGrow:1}} type='number' placeholder='0' defaultValue={this.state.cargoFinishItem.disburdenUnitPrice!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.disburdenUnitPrice):''} ref='moneyXiehuoRef' onChange={(event)=>{
                    this.state.cargoFinishItem.disburdenUnitPrice = arithUtil.yuanToFen(event.target.value);
                    let finishDisburdenMoney=0;
                    if(this.state.cargoFinishItem.disburdenMode==1){
                      finishDisburdenMoney=(this.state.cargoFinishItem.disburdenUnitPrice).mul(this.state.currCargoItem.saleNumber).round();
                    }else if(this.state.cargoFinishItem.disburdenMode==2){
                      finishDisburdenMoney=(this.state.cargoFinishItem.disburdenUnitPrice).mul((this.state.currCargoItem.saleWeight).div(1000000)).round();
                    }
                    this.refs.finishDisburdenMoney.innerHTML=stringUtil.language(1153)+'：'+arithUtil.fenToYuan(finishDisburdenMoney)+stringUtil.currency(1);
                  }}/><span> {!this.state.cargoFinishItem.disburdenMode?stringUtil.currency(1):this.state.cargoFinishItem.disburdenMode==1?stringUtil.currency(1)+'/'+stringUtil.language(1173):stringUtil.currency(1)+'/'+stringUtil.language(1197)}</span>
                </div>
                {this.state.cargoFinishItem.disburdenMode?<span ref='finishDisburdenMoney' style={{height:30,color:'#F00',fontWeight:'bold',textAlignVertical:'center'}}>stringUtil.language(1153)：0{stringUtil.currency(1)}</span>:''}
              </div>}
              okBnEvents={(event) => {
                this.setState({disburdenMoneyShowPop:false});
                if(this.state.cargoFinishItem.disburdenMode==null){this.state.cargoFinishItem.disburdenMode=0;}
                if(this.state.cargoFinishItem.disburdenUnitPrice==null){this.state.cargoFinishItem.disburdenUnitPrice=0;}
                if(this.state.cargoFinishItem.disburdenMode==0)this.state.cargoFinishItem.disburdenMoney=(this.state.cargoFinishItem.disburdenUnitPrice).round();
                else if(this.state.cargoFinishItem.disburdenMode==1)this.state.cargoFinishItem.disburdenMoney=(this.state.cargoFinishItem.disburdenUnitPrice).mul(this.state.currCargoItem.saleNumber).round();
                else if(this.state.cargoFinishItem.disburdenMode==2)this.state.cargoFinishItem.disburdenMoney=(this.state.cargoFinishItem.disburdenUnitPrice).mul((this.state.currCargoItem.saleWeight).div(1000000)).round();
                this.setState({cargoFinishItem:this.state.cargoFinishItem});
              }} closeBnEvents={() => {this.setState({disburdenMoneyShowPop: false});}}/>
          <MaskModal visible={this.state.editExpendShowPop} title={stringUtil.language(1092)} height={200} context={this}
              content={<div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10,paddingRight:10}} key={this.state.keyTagVal!=null&&this.state.currCargoItem.advanceExpends[this.state.keyTagVal]!=null?this.state.currCargoItem.advanceExpends[this.state.keyTagVal].clauseId:''}>
                <span>{this.state.keyTagVal!=null&&this.state.currCargoItem.advanceExpends[this.state.keyTagVal]!=null?this.state.currCargoItem.advanceExpends[this.state.keyTagVal].clauseName:''}：</span>
                <input style={{height:30,color:'#F00',fontWeight:'bold',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',borderRadius:2,display:'flex',flexGrow:1}} type='number' placeholder='0' defaultValue={this.state.keyTagVal!=null&&this.state.currCargoItem.advanceExpends[this.state.keyTagVal]!=null&&this.state.currCargoItem.advanceExpends[this.state.keyTagVal].money!=0?arithUtil.fenToYuan(this.state.currCargoItem.advanceExpends[this.state.keyTagVal].money):''} onChange={(event)=>{
                  this.state.currCargoItem.advanceExpends[this.state.keyTagVal].money = arithUtil.yuanToFen(event.target.value);
                }}/>
              </div>}
              okBnEvents={(event) => {
                  let results=[];
                  for(let i=0;i<this.state.currCargoItem.advanceExpends.length;i++){
                    results.push({clauseId:this.state.currCargoItem.advanceExpends[i].clauseId,money:this.state.currCargoItem.advanceExpends[i].money});
                  }
                  stringUtil.httpRequest(urlUtil.SALE_CARGO_ADVANCE_EXPEND_URL+'/'+this.state.currCargoItem.id,results,['json','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                      ToastBox.success(stringUtil.language(388));
                      stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+this.state.currCargoItem.id,null,['key','get',1],(successResponse)=>{
                        if (successResponse.status==200) {
                          this.state.currCargoItem = JSON.parse(JSON.stringify(successResponse.data));
                          this.state.editExpendShowPop = false;
                          this.setState({rightShowIndex:this.state.currCargoItem.cargoMode});//-1库存 0代销 1stringUtil.language(1051) 2炒货
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }} closeBnEvents={() => {this.setState({editExpendShowPop: false});}}/>
        <MaskModal title={stringUtil.language(248)} visible={this.state.ownerExpendAddShowPop} width={580} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
            content={<div style={{width:'100%',height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.addShowPopIndex}>
                  <div style={{width:'100%',height:'100%',overflow:'auto'}}>
                      <OwnerExpendAddView context={this} paramsInfo={{ownerExpendInfo:JSON.stringify(this.state.ownerExpendInfo?this.state.ownerExpendInfo:{})}} successEvent={(result1,result2)=>{
                          this.state.ownerExpendInsertItem = result1;
                      }}/>
                  </div>
              </div>}
              okBnEvents={(event) => {
                  if (this.state.ownerExpendInsertItem==null||this.state.ownerExpendInsertItem.clauseId==null) {
                    ToastBox.error(stringUtil.language(116));return;
                  }
                  if (this.state.ownerExpendInsertItem.cargoId==null) {
                    ToastBox.error(stringUtil.language(117));return;
                  }
                  if (!this.state.ownerExpendInsertItem.payState&&this.state.ownerExpendInsertItem.accountId==null) {
                    ToastBox.error(stringUtil.language(95));return;
                  }
                  if (this.state.ownerExpendInsertItem.money==null||this.state.ownerExpendInsertItem.money==0) {
                    ToastBox.error(stringUtil.language(118));return;
                  }
                  if(this.state.ownerExpendInsertItem.payState&&!this.state.ownerExpendInsertItem.companyId){
                    ToastBox.error(stringUtil.language(119));return;
                  }
                  let ownerExpendInsertVo = {
                      time:arithUtil.timeToNumber(this.state.ownerExpendInsertItem.time,2),
                      clauseId:this.state.ownerExpendInsertItem.clauseId,
                      targetType:this.state.ownerExpendInsertItem.targetType,
                      targetId:this.state.ownerExpendInsertItem.targetId,
                      cargoId:this.state.ownerExpendInsertItem.cargoId,
                      accountId:this.state.ownerExpendInsertItem.accountId,
                      money:this.state.ownerExpendInsertItem.money,
                      companyId:this.state.ownerExpendInsertItem.companyId,
                      payState:this.state.ownerExpendInsertItem.payState,
                      describe:this.state.ownerExpendInsertItem.describe!=null?this.state.ownerExpendInsertItem.describe:'',
                  };
                  stringUtil.httpRequest(urlUtil.FINANCE_OWNER_EXPEND_URL,ownerExpendInsertVo,['json','post',1],(successResponse)=>{
                    if (successResponse.status==200) {
                      stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+this.state.currCargoItem.id,null,['key','get',1],(successResponse)=>{
                        if (successResponse.status==200) {
                          this.state.currCargoItem = JSON.parse(JSON.stringify(successResponse.data));
                          this.state.ownerExpendAddShowPop=false;
                          this.state.cargoFinishShowPop=true;
                          this.setState({rightShowIndex:this.state.currCargoItem.cargoMode});//-1库存 0代销 1stringUtil.language(1051) 2炒货
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }} closeBnEvents={() => {this.setState({ownerExpendAddShowPop:false})}}/>
        <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                          {stringUtil.language(173)}？
                      </div>}
              okBnEvents={(event) => {
                  this.setState({delShowPop: false});
                  stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+this.state.currCargoItem.id,null,['key','delete',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          ToastBox.success(stringUtil.language(388));
                          this.initCargoTotalInfo();
                          this.initWarehouseCommodityItemData();
                          this.getNowDataList();
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
        {this.state.cargoInfoPrint?<div style={{width:'100%'}} id='printArea'>
            {(this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
               <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                <span style={{fontSize:20,fontWeight:'bold'}}>{stringUtil.language(249)}</span>
                <span style={{fontSize:20,fontWeight:'bold',marginLeft:"50px"}}><span style={{fontSize:20}}>{(this.state.cargoInfoPrint.cargoMode==0||this.state.cargoInfoPrint.cargoMode==2?stringUtil.subStr(this.state.cargoInfoPrint.targetName,10):(this.state.cargoInfoPrint.targetName?stringUtil.language(1051)+'_'+stringUtil.subStr(this.state.cargoInfoPrint.targetName,10):stringUtil.language(1051)))+' ' +stringUtil.language(1175)+this.state.cargoInfoPrint.cargoNo+stringUtil.language(1176)}</span></span>
                </div> 
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                  <span style={{fontSize:18}}>{this.state.cargoInfoPrint.marketName}</span>
                  <span style={{fontSize:18,marginLeft:"25px"}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}</span>
                  <span style={{fontSize:18,marginLeft:"25px"}}>{this.state.cargoInfoPrint.shopMobile}</span>
                </div>
            </div>:<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(249)}（{this.state.nWmode==0?stringUtil.language(1021):this.state.nWmode==1?stringUtil.language(1022):stringUtil.language(432)}）</span>
                <span style={{fontSize:15}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}</span>
                <span style={{fontSize:15,fontWeight:'bold'}}><span style={{fontSize:17}}>{(this.state.cargoInfoPrint.cargoMode==0||this.state.cargoInfoPrint.cargoMode==2?stringUtil.subStr(this.state.cargoInfoPrint.targetName,10):(this.state.cargoInfoPrint.targetName?stringUtil.language(1051)+'_'+stringUtil.subStr(this.state.cargoInfoPrint.targetName,10):stringUtil.language(1051)))+' '+stringUtil.language(1175)+this.state.cargoInfoPrint.cargoNo+stringUtil.language(1176)}</span></span>
            </div>}
            <div style={{height:25,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                <span style={{fontSize:13}}>{stringUtil.language(1052)}：{this.state.cargoInfoPrint.workerId==0?stringUtil.language(431):this.state.cargoInfoPrint.workerName}</span>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{fontSize:12}}>{arithUtil.formatToTimeStr(new Date(this.state.cargoInfoPrint.receiveTime),'Y-M-D h:m')}{stringUtil.language(1052)}</span>
                  {this.state.cargoInfoPrint.state==2?<span style={{fontSize:12}}>，{arithUtil.formatToTimeStr(new Date(this.state.cargoInfoPrint.settleTime),'Y-M-D h:m')}{stringUtil.language(1053)}</span>:''}
                </div>
            </div>
            <div style={{height:25,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                <span style={{fontSize:10}}>{this.state.cargoInfoPrint.warehouseName}</span>
                <span style={{fontSize:10}}>{this.state.cargoInfoPrint.carNumber?stringUtil.language(857)+this.state.cargoInfoPrint.carNumber+'；':''}{stringUtil.language(914)}{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}、{stringUtil.currency(1)}</span>
            </div>
            {
              (this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?<table style={{width:'100%',borderCollapse:'collapse',cellSpacing:0}}>
                 <tr style={{height:30,flexDirection:'row',alignItems:'center'}}>
                <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1020)}</td>
                <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(535)}</td>
                <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(536)}</td>
                <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(966)}</td>
                <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(961)}</td>
                <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1068)}</td>
                <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1069)}</td>
                <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(755)}</td>
                <td colspan="2" style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1138)}</td>
                <td colspan="2" style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1121)}</td>
                <td colspan="2" style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1122)}</td>
                <td colspan="2" style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1057)}</td>
                <td colspan="2" style={{borderWidth:1,borderStyle:'solid',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1154)}</td>
                </tr>
                {(()=>{this.state.totalInfo={sendNumber:0,sendWeight:0,transferNumber:0,transferWeight:0,processNumber:0,processWeight:0,purchaseNumber:0,purchaseWeight:0,
                stockInOutNumber:0,stockInOutWeight:0,zongchenben:0,saleNumber:0,saleWeight:0,balanceNumber:0,balanceWeight:0,purchaseGoodsMoney:0,saleGoodsMoney:0,saleTotalMoney:0,saleChenben:0,maoli:0,balanceChenben:0,ownerOtherMoney:0}})()}
                {this.state.cargoInfoPrint.commodities!=null?this.state.cargoInfoPrint.commodities.map((item,key)=>{
                    this.state.totalInfo.sendNumber = (this.state.totalInfo.sendNumber).add(item.sendNumber);
                    this.state.totalInfo.sendWeight = (this.state.totalInfo.sendWeight).add(item.sendWeight);
                    this.state.totalInfo.transferNumber = (this.state.totalInfo.transferNumber).add(item.transferNumber);
                    this.state.totalInfo.transferWeight = (this.state.totalInfo.transferWeight).add(item.transferWeight);
                    this.state.totalInfo.processNumber = (this.state.totalInfo.processNumber).add(item.processNumber);
                    this.state.totalInfo.processWeight = (this.state.totalInfo.processWeight).add(item.processWeight);
                    this.state.totalInfo.purchaseNumber = (this.state.totalInfo.purchaseNumber).add(item.purchaseNumber);
                    this.state.totalInfo.purchaseWeight = (this.state.totalInfo.purchaseWeight).add(item.purchaseWeight);
                    this.state.totalInfo.stockInOutNumber = (this.state.totalInfo.stockInOutNumber).add(item.stockInOutNumber);
                    this.state.totalInfo.stockInOutWeight = (this.state.totalInfo.stockInOutWeight).add(item.stockInOutWeight);
                    this.state.totalInfo.saleNumber = (this.state.totalInfo.saleNumber).add(item.saleNumber);
                    this.state.totalInfo.saleWeight = (this.state.totalInfo.saleWeight).add(item.saleWeight);
                    this.state.totalInfo.balanceNumber = (this.state.totalInfo.balanceNumber).add(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
                    this.state.totalInfo.balanceWeight = (this.state.totalInfo.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight));
                    this.state.totalInfo.purchaseGoodsMoney = (this.state.totalInfo.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
                    this.state.totalInfo.saleGoodsMoney = (this.state.totalInfo.saleGoodsMoney).add(item.saleGoodsMoney.sub(item.diffGoodsMoney));
                    let send = this.state.nWmode==0?item.sendNumber:arithUtil.keToWeight(item.sendWeight,this.state.weightMode);
                    let transfer = this.state.nWmode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode);
                    let process = this.state.nWmode==0?item.processNumber:arithUtil.keToWeight(item.processWeight,this.state.weightMode);
                    let purchase = this.state.nWmode==0?item.purchaseNumber:arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode);
                    let stockInOut = this.state.nWmode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode);
                    let zongchenben=(item.transferMoney).add(item.purchaseGoodsMoney).add(item.processNumber>0?item.processMoney:0);
                    if(item.purchaseCosts){
                      for(let i=0;i<item.purchaseCosts.length;i++){
                        zongchenben=zongchenben.add(item.purchaseCosts[i].money);
                      }
                    }
                    let saleTotalMoney=item.saleGoodsMoney;
                    let ownerOtherMoney = 0;
                    if(item.saleCosts){
                      for(let i=0;i<item.saleCosts.length;i++){
                        saleTotalMoney=saleTotalMoney.add(item.saleCosts[i].money);
                        if(item.saleCosts[i].payee==0){
                          ownerOtherMoney = ownerOtherMoney.add(item.saleCosts[i].money);
                        }
                      }
                    }
                    this.state.totalInfo.ownerOtherMoney = (this.state.totalInfo.ownerOtherMoney).add(ownerOtherMoney);
                    let danweichenbenN=(zongchenben).div(item.transferNumber+item.purchaseNumber+(item.processNumber>0?item.processNumber:0));//stringUtil.language(1021)的{stringUtil.language(559)}
                    zongchenben=zongchenben.add(item.processNumber<0?(item.processNumber).mul(danweichenbenN):0);
                    let yushu=item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber;
                    this.state.totalInfo.zongchenben = (this.state.totalInfo.zongchenben).add(zongchenben);
                    this.state.totalInfo.saleTotalMoney = (this.state.totalInfo.saleTotalMoney).add(saleTotalMoney);
                    let sale = this.state.nWmode==0?item.saleNumber:arithUtil.keToWeight(item.saleWeight,this.state.weightMode);
                    let saleChenben = danweichenbenN.mul(item.saleNumber);
                    this.state.totalInfo.saleChenben = (this.state.totalInfo.saleChenben).add(saleChenben);
                    let maoli = (saleTotalMoney).sub(saleChenben);
                    this.state.totalInfo.maoli = (this.state.totalInfo.maoli).add(maoli);
                    let balance = this.state.nWmode==0?(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight),this.state.weightMode);
                    let balanceChenben = danweichenbenN.mul(yushu);
                    this.state.totalInfo.balanceChenben = (this.state.totalInfo.balanceChenben).add(balanceChenben);
                    return(<tr style={{height:30,alignItems:'center'}} key={key}>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:15}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?stringUtil.subStr(item.commodityName,5):''}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{item.saleNumber}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</td>
                        {/* <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{(arithUtil.fenToYuan(item.saleGoodsMoney)).div(item.saleNumber,2)}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</td> */}
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(item.numJJia)}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleGoodsMoney.sub(item.diffGoodsMoney))}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(ownerOtherMoney)}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(ownerOtherMoney.add(item.saleGoodsMoney.sub(item.diffGoodsMoney)))}</td>
                        
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{item.transferNumber}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{item.sendNumber}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(item.sendWeight,this.state.weightMode)}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{item.saleNumber}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{item.stockInOutNumber}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber)}</td>
                        <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight),this.state.weightMode)}</td>
                    </tr>);
                }):''}
                {(()=>{this.state.send = this.state.nWmode==0?this.state.totalInfo.sendNumber:arithUtil.keToWeight(this.state.totalInfo.sendWeight,this.state.weightMode);
                    this.state.transfer = this.state.nWmode==0?this.state.totalInfo.transferNumber:arithUtil.keToWeight(this.state.totalInfo.transferWeight,this.state.weightMode);
                    this.state.process = this.state.nWmode==0?this.state.totalInfo.processNumber:arithUtil.keToWeight(this.state.totalInfo.processWeight,this.state.weightMode);
                    this.state.purchase = this.state.nWmode==0?this.state.totalInfo.purchaseNumber:arithUtil.keToWeight(this.state.totalInfo.purchaseWeight,this.state.weightMode);
                    this.state.stockInOut = this.state.nWmode==0?this.state.totalInfo.stockInOutNumber:arithUtil.keToWeight(this.state.totalInfo.stockInOutWeight,this.state.weightMode);
                    this.state.sale = this.state.nWmode==0?this.state.totalInfo.saleNumber:arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode);
                    this.state.balance = this.state.nWmode==0?this.state.totalInfo.balanceNumber:arithUtil.keToWeight(this.state.totalInfo.balanceWeight,this.state.weightMode);
                    this.state.totalNWmodeInfo={number:0,weight:0,money:0};
                    if(this.state.nWmode!=2)this.state.totalNWmodeInfo={number:this.state.totalInfo.saleNumber,weight:this.state.totalInfo.saleWeight,money:this.state.totalInfo.saleGoodsMoney};})()}
              <tr style={{height:30,alignItems:'center'}}>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:15}}>{stringUtil.language(890)}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{this.state.totalInfo.saleNumber}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode)}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}></td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}></td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleGoodsMoney)}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.ownerOtherMoney)}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan((this.state.totalInfo.ownerOtherMoney).add(this.state.totalInfo.saleGoodsMoney))}</td>
                    
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{this.state.totalInfo.transferNumber}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.transferWeight,this.state.weightMode)}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{this.state.totalInfo.sendNumber}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.sendWeight,this.state.weightMode)}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{this.state.totalInfo.saleNumber}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode)}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{this.state.totalInfo.stockInOutNumber}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.stockInOutWeight,this.state.weightMode)}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{this.state.totalInfo.balanceNumber}</td>
                    <td style={{borderWidth:1,borderStyle:'solid',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.balanceWeight,this.state.weightMode)}</td>
                </tr>
              </table>:""
            }
            {(this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?"":this.state.nWmode!=2?(this.state.cargoInfoPrint.cargoMode==0? (this.state.nWmode==0?<div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1054)}</span>
                <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1055)}</span>
                <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1057)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1059)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1068)}</span>
                <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1058)}</span>
            </div>: <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(535)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(536)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1068)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1069)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(755)}</span>
                <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1054)}</span>
                <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1055)}</span>
                <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1057)}</span>
                <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1058)}</span>
            </div>):this.state.cargoInfoPrint.cargoMode==1?<div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'8%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1056)}</span>
                <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1057)}</span>
                <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1055)}</span>
                <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1044)}</span>
                <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(947)}</span>
                <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(559)}</span>
                <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(742)}</span>
                <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(743)}</span>
                <span style={{width:'8%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(889)}</span>
                <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(600)}</span>
                <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1148)}</span>
                <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1149)}</span>
                <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(744)}</span>
            </div>:<div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1055)}</span>
                <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1056)}</span>
                <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1044)}</span>
                <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1057)}</span>
                <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1059)}</span>
                <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{this.state.nWmode==0?stringUtil.language(1173):stringUtil.language(1196)}{stringUtil.language(1116)}</span>
                <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1068)}</span>
                <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1058)}</span>
            </div>):<div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center',borderTopStyle:'solid',borderTopWidth:1,borderBottomStyle:'solid',borderBottomWidth:1}}>
              <div style={{width:'50%',display:'flex',flexDirection:'row'}}>
                <span style={{width:'29%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                <span style={{width:'15%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                <span style={{width:'17%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                <span style={{width:'23%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                <span style={{width:'16%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1041)}</span>
              </div>
              <div style={{width:1,height:'100%',borderLeftStyle:'solid',borderLeftColor:'#C0C0C0',borderLeftWidth:1}}/>
              <div style={{width:'50%',display:'flex',flexDirection:'row'}}>
                <span style={{width:'29%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                <span style={{width:'15%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                <span style={{width:'17%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                <span style={{width:'23%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                <span style={{width:'16%',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1041)}</span>
              </div>
            </div>}
           <div style={{width:'100%'}}>
                { (this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?"":(this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?"":(()=>{this.state.totalInfo={sendNumber:0,sendWeight:0,transferNumber:0,transferWeight:0,processNumber:0,processWeight:0,purchaseNumber:0,purchaseWeight:0,
                stockInOutNumber:0,stockInOutWeight:0,zongchenben:0,saleNumber:0,saleWeight:0,balanceNumber:0,balanceWeight:0,purchaseGoodsMoney:0,saleGoodsMoney:0,saleTotalMoney:0,saleChenben:0,maoli:0,balanceChenben:0,ownerOtherMoney:0}})()}
                { (this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?"":this.state.cargoInfoPrint.commodities!=null?this.state.cargoInfoPrint.commodities.map((item,key)=>{
                    this.state.totalInfo.sendNumber = (this.state.totalInfo.sendNumber).add(item.sendNumber);
                    this.state.totalInfo.sendWeight = (this.state.totalInfo.sendWeight).add(item.sendWeight);
                    this.state.totalInfo.transferNumber = (this.state.totalInfo.transferNumber).add(item.transferNumber);
                    this.state.totalInfo.transferWeight = (this.state.totalInfo.transferWeight).add(item.transferWeight);
                    this.state.totalInfo.processNumber = (this.state.totalInfo.processNumber).add(item.processNumber);
                    this.state.totalInfo.processWeight = (this.state.totalInfo.processWeight).add(item.processWeight);
                    this.state.totalInfo.purchaseNumber = (this.state.totalInfo.purchaseNumber).add(item.purchaseNumber);
                    this.state.totalInfo.purchaseWeight = (this.state.totalInfo.purchaseWeight).add(item.purchaseWeight);
                    this.state.totalInfo.stockInOutNumber = (this.state.totalInfo.stockInOutNumber).add(item.stockInOutNumber);
                    this.state.totalInfo.stockInOutWeight = (this.state.totalInfo.stockInOutWeight).add(item.stockInOutWeight);
                    this.state.totalInfo.saleNumber = (this.state.totalInfo.saleNumber).add(item.saleNumber);
                    this.state.totalInfo.saleWeight = (this.state.totalInfo.saleWeight).add(item.saleWeight);
                    this.state.totalInfo.balanceNumber = (this.state.totalInfo.balanceNumber).add(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
                    this.state.totalInfo.balanceWeight = (this.state.totalInfo.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight));
                    this.state.totalInfo.purchaseGoodsMoney = (this.state.totalInfo.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
                    this.state.totalInfo.saleGoodsMoney = (this.state.totalInfo.saleGoodsMoney).add(item.saleGoodsMoney.sub(item.diffGoodsMoney));
                    let send = this.state.nWmode==0?item.sendNumber:arithUtil.keToWeight(item.sendWeight,this.state.weightMode);
                    let transfer = this.state.nWmode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode);
                    let process = this.state.nWmode==0?item.processNumber:arithUtil.keToWeight(item.processWeight,this.state.weightMode);
                    let purchase = this.state.nWmode==0?item.purchaseNumber:arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode);
                    let stockInOut = this.state.nWmode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode);
                    let zongchenben=(item.transferMoney).add(item.purchaseGoodsMoney).add(item.processNumber>0?item.processMoney:0);
                    if(item.purchaseCosts){
                      for(let i=0;i<item.purchaseCosts.length;i++){
                        zongchenben=zongchenben.add(item.purchaseCosts[i].money);
                      }
                    }
                    let saleTotalMoney=item.saleGoodsMoney.sub(item.diffGoodsMoney);
                    let ownerOtherMoney = 0;
                    if(item.saleCosts){
                      for(let i=0;i<item.saleCosts.length;i++){
                        saleTotalMoney=saleTotalMoney.add(item.saleCosts[i].money);
                        if(item.saleCosts[i].payee==0){
                          ownerOtherMoney.add(item.saleCosts[i].money);
                        }
                      }
                    }
                    this.state.totalInfo.ownerOtherMoney = (this.state.totalInfo.ownerOtherMoney).add(ownerOtherMoney);
                    let danweichenbenN=(zongchenben).div(item.transferNumber+item.purchaseNumber+(item.processNumber>0?item.processNumber:0));//stringUtil.language(1021)的{stringUtil.language(559)}
                    zongchenben=zongchenben.add(item.processNumber<0?(item.processNumber).mul(danweichenbenN):0);
                    let yushu=item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber;
                    this.state.totalInfo.zongchenben = (this.state.totalInfo.zongchenben).add(zongchenben);
                    this.state.totalInfo.saleTotalMoney = (this.state.totalInfo.saleTotalMoney).add(saleTotalMoney);
                    let sale = this.state.nWmode==0?item.saleNumber:arithUtil.keToWeight(item.saleWeight,this.state.weightMode);
                    let saleChenben = danweichenbenN.mul(item.saleNumber);
                    this.state.totalInfo.saleChenben = (this.state.totalInfo.saleChenben).add(saleChenben);
                    let maoli = (saleTotalMoney).sub(saleChenben);
                    this.state.totalInfo.maoli = (this.state.totalInfo.maoli).add(maoli);
                    let balance = this.state.nWmode==0?(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight),this.state.weightMode);
                    let balanceChenben = danweichenbenN.mul(yushu);
                    this.state.totalInfo.balanceChenben = (this.state.totalInfo.balanceChenben).add(balanceChenben);
                    if(this.state.nWmode!=2)return(this.state.cargoInfoPrint.cargoMode==0?(this.state.nWmode==0?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{send}</span>
                        <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{transfer}</span>
                        <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stockInOut}</span>
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{sale}</span>
                        {/* <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{(arithUtil.fenToYuan(item.saleGoodsMoney.sub(item.diffGoodsMoney))).div(sale,2)}</span> */}
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleGoodsMoney.sub(item.diffGoodsMoney))}</span>
                        <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{balance}</span>
                    </div>:<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{item.saleNumber}</span>
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                        {/* <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{(arithUtil.fenToYuan(item.saleGoodsMoney)).div(sale,2)}</span> */}
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleGoodsMoney)}</span>
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(ownerOtherMoney)}</span>
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(ownerOtherMoney.add(item.saleGoodsMoney))}</span>
                        <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{send}</span>
                        <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{transfer}</span>
                        <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stockInOut}</span>
                        <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{balance}</span>
                    </div>):this.state.cargoInfoPrint.cargoMode==1?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'8%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                        <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{process}</span>
                        <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stockInOut}</span>
                        <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{transfer}</span>
                        <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{purchase}</span>
                        <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{arithUtil.fenToYuan(zongchenben)}</span>
                        <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{arithUtil.fenToYuan(danweichenbenN)}</span>
                        <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{item.saleNumber}</span>
                        <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                        {/* <span style={{width:'8%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{(arithUtil.fenToYuan(item.saleGoodsMoney)).div(sale,2)}</span> */}
                        <span style={{width:'8%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                        <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{arithUtil.fenToYuan(saleTotalMoney)}</span>
                        <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{arithUtil.fenToYuan(saleChenben)}</span>
                        <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{arithUtil.fenToYuan(maoli)}</span>
                        <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{balance}</span>
                        <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:11,textAlign:'center'}}>{arithUtil.fenToYuan(balanceChenben)}</span>
                    </div>:<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                        <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{transfer}</span>
                        <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{process}</span>
                        <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{purchase}</span>
                        <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stockInOut}</span>
                        <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{sale}</span>
                        {/* <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{(arithUtil.fenToYuan(item.saleGoodsMoney)).div(sale,2)}</span> */}
                        <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.nWmode==0?arithUtil.fenToYuan(item.numJJia):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</span>
                        <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleGoodsMoney)}</span>
                        <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{balance}</span>
                    </div>);
                }):''}
                { (this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?"":(()=>{this.state.send = this.state.nWmode==0?this.state.totalInfo.sendNumber:arithUtil.keToWeight(this.state.totalInfo.sendWeight,this.state.weightMode);
                    this.state.transfer = this.state.nWmode==0?this.state.totalInfo.transferNumber:arithUtil.keToWeight(this.state.totalInfo.transferWeight,this.state.weightMode);
                    this.state.process = this.state.nWmode==0?this.state.totalInfo.processNumber:arithUtil.keToWeight(this.state.totalInfo.processWeight,this.state.weightMode);
                    this.state.purchase = this.state.nWmode==0?this.state.totalInfo.purchaseNumber:arithUtil.keToWeight(this.state.totalInfo.purchaseWeight,this.state.weightMode);
                    this.state.stockInOut = this.state.nWmode==0?this.state.totalInfo.stockInOutNumber:arithUtil.keToWeight(this.state.totalInfo.stockInOutWeight,this.state.weightMode);
                    this.state.sale = this.state.nWmode==0?this.state.totalInfo.saleNumber:arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode);
                    this.state.balance = this.state.nWmode==0?this.state.totalInfo.balanceNumber:arithUtil.keToWeight(this.state.totalInfo.balanceWeight,this.state.weightMode);
                    this.state.totalNWmodeInfo={number:0,weight:0,money:0};
                    if(this.state.nWmode!=2)this.state.totalNWmodeInfo={number:this.state.totalInfo.saleNumber,weight:this.state.totalInfo.saleWeight,money:this.state.totalInfo.saleGoodsMoney};})()}
                { (this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?"":this.state.nWmode!=2?(this.state.cargoInfoPrint.cargoMode==0?(this.state.nWmode==0?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12}}>{stringUtil.language(890)}</span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.send}</span>
                    <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.transfer}</span>
                    <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.stockInOut}</span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.sale}</span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}></span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleGoodsMoney)}</span>
                    <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.balance}</span>
                </div>:<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12}}>{stringUtil.language(890)}</span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.totalInfo.saleNumber}</span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode)}</span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}></span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleGoodsMoney)}</span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.ownerOtherMoney)}</span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan((this.state.totalInfo.ownerOtherMoney).add(this.state.totalInfo.ownerOtherMoney))}</span>
                    <span style={{width:'13%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.send}</span>
                    <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.transfer}</span>
                    <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.stockInOut}</span>
                    <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.balance}</span>
                </div>):this.state.cargoInfoPrint.cargoMode==1?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'8%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12}}>{stringUtil.language(890)}</span>
                    <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.process}</span>
                    <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.stockInOut}</span>
                    <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.transfer}</span>
                    <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.purchase}</span>
                    <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.zongchenben)}</span>
                    <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}></span>
                    <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.totalInfo.saleNumber}</span>
                    <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode)}</span>
                    <span style={{width:'8%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}></span>
                    <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleTotalMoney)}</span>
                    <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleChenben)}</span>
                    <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.maoli)}</span>
                    <span style={{width:'6%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.balance}</span>
                    <span style={{width:'7%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.balanceChenben)}</span>
                </div>:<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12}}>{stringUtil.language(890)}</span>
                    <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.transfer}</span>
                    <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.process}</span>
                    <span style={{width:'12%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.purchase}</span>
                    <span style={{width:'10%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.stockInOut}</span>
                    <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.sale}</span>
                    <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}></span>
                    <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleGoodsMoney)}</span>
                    <span style={{width:'11%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{this.state.balance}</span>
                </div>):''}
                {this.state.nWmode==2&&this.state.cargoInfoPrint.detailed!=null?this.state.cargoInfoPrint.detailed.map((item,key)=>{
                    let totalItemInfo={number:0,weight:0,money:0};let totalItemInfoAvg={number:0,weight:0,moneyNumber:0,moneyWeight:0};
                    if(item.items==null) item.items=[];
                    for(let i=0;i<item.items.length;i++){
                      totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
                      totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
                      item.items[i].money = item.items[i].goodsMoney.sub(item.items[i].diffGoodsMoney);
                      // for(let j=0;j<item.items[i].costs.length;j++){
                      //     item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
                      // }4{stringUtil.language(1178)}7{stringUtil.language(1179)}16:05stringUtil.language(1027)
                      totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
                      if(item.items[i].mode==1){
                        totalItemInfoAvg.number = (totalItemInfoAvg.number).add(item.items[i].number);
                        totalItemInfoAvg.moneyNumber = (totalItemInfoAvg.moneyNumber).add(item.items[i].goodsMoney.sub(item.items[i].diffGoodsMoney));
                      }
                      if(item.items[i].mode==0){
                        totalItemInfoAvg.weight = (totalItemInfoAvg.weight).add(item.items[i].weight);
                        totalItemInfoAvg.moneyWeight = (totalItemInfoAvg.moneyWeight).add(item.items[i].goodsMoney.sub(item.items[i].diffGoodsMoney));
                      }
                    }
                    this.state.totalNWmodeInfo.number=(this.state.totalNWmodeInfo.number).add(totalItemInfo.number);
                    this.state.totalNWmodeInfo.weight=(this.state.totalNWmodeInfo.weight).add(totalItemInfo.weight);
                    this.state.totalNWmodeInfo.money=(this.state.totalNWmodeInfo.money).add(totalItemInfo.money);
                    return(<div style={{width:'100%',display:'flex',flexDirection:'column',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}} key={key}>
                      <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                        <span style={{fontSize:17,fontWeight:'bold'}}>{(key+1)+'、'}{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                        <span style={{fontSize:14,fontWeight:'bold',marginLeft:20}}>
                          {/* ，{arithUtil.fenToYuan(totalItemInfo.money.div(totalItemInfo.number))}{stringUtil.currency(1)}/stringUtil.language(1173) */}
                          {stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money,2)}{stringUtil.currency(1)}
                          {totalItemInfoAvg.number?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyNumber).div(totalItemInfoAvg.number,2)+stringUtil.currency(1)+'/'+stringUtil.language(1173):''}{totalItemInfoAvg.weight?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyWeight).div(arithUtil.keToWeight(totalItemInfoAvg.weight,this.state.weightMode),2)+stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):''}
                          {totalItemInfo.number?'，'+arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode).div(totalItemInfo.number,2)+(this.state.weightMode?stringUtil.language(1172)+'/'+stringUtil.language(1173):stringUtil.language(1023)+'/'+stringUtil.language(1173)):''}
                        </span>
                      </div>
                      <div style={{width:'100%',display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                        {item.items.map((itemb,keyb)=>{
                          return(<div style={{width:'49.8%',height:20,display:'flex',flexDirection:'row',alignItems:'center',borderRightStyle:'solid',borderRightWidth:keyb%2==0?1:0,borderRightColor:'#C0C0C0'}} key={keyb}>
                            <span style={{width:'29%',fontSize:13,paddingLeft:7}}>{itemb.customerName}</span>
                            <span style={{width:'15%',fontSize:13,textAlign:'center'}}>{itemb.number}</span>
                            <span style={{width:'17%',fontSize:13,textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                            <span style={{width:'23%',fontSize:13,textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                            <span style={{width:'16%',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                          </div>);
                        })}
                      </div>
                    </div>);
                }):''}
                <br/>
                {(this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?"":<span style={{height:25,fontSize:17,fontWeight:'bold'}}>{stringUtil.language(890)}{stringUtil.language(1122)}{this.state.totalNWmodeInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(this.state.totalNWmodeInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(this.state.totalNWmodeInfo.money)}{stringUtil.currency(1)}</span>}
                {(()=>{this.state.otherMoneyItems = [];
                   this.state.addMoneyItems = [];
                   this.state.subMoneyItems = [];
                   this.state.subTotalMoney = 0;
                this.state.docMoney = (this.state.currCargoItem.saleGoodsMoney).sub(this.state.currCargoItem.diffGoodsMoney);
                this.state.ownerMoney = (this.state.currCargoItem.saleGoodsMoney).sub(this.state.currCargoItem.diffGoodsMoney);
                if(this.state.cargoInfoPrint.saleCosts){
                  for(let i=0;i<this.state.cargoInfoPrint.saleCosts.length;i++){
                    this.state.docMoney = (this.state.docMoney).add(this.state.cargoInfoPrint.saleCosts[i].money);
                    if((this.state.cargoInfoPrint.cargoMode==0&&this.state.cargoInfoPrint.saleCosts[i].payee==0)||(this.state.cargoInfoPrint.cargoMode==2&&this.state.cargoInfoPrint.saleCosts[i].payee==2)){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                      this.state.ownerMoney = (this.state.ownerMoney).add(this.state.cargoInfoPrint.saleCosts[i].money);
                      this.state.otherMoneyItems.push({costClauseName:'+'+this.state.cargoInfoPrint.saleCosts[i].costClauseName,money:this.state.cargoInfoPrint.saleCosts[i].money});
                      if(this.state.cargoInfoPrint.cargoMode==0){
                        this.state.addMoneyItems.push({costClauseName:this.state.cargoInfoPrint.saleCosts[i].costClauseName,money:this.state.cargoInfoPrint.saleCosts[i].money}); 
                      }
                    }
                  }
                }
                //this.state.otherMoneyItems.push({costClauseName:'stringUtil.language(746)',money:this.state.docMoney});
                if(this.state.cargoInfoPrint.cargoMode==0)this.state.otherMoneyItems.push({costClauseName:'='+stringUtil.language(592),money:this.state.ownerMoney});
                if(this.state.cargoInfoPrint.cargoMode==2)this.state.otherMoneyItems.push({costClauseName:'='+stringUtil.language(594),money:this.state.ownerMoney});
                if(this.state.cargoInfoPrint.cargoMode==1||this.state.cargoInfoPrint.cargoMode==2){
                  if(this.state.cargoInfoPrint.purchaseCosts){
                    let caigouchenben=this.state.currCargoItem.purchaseGoodsMoney;
                    for(let i=0;i<this.state.cargoInfoPrint.purchaseCosts.length;i++){
                      caigouchenben=caigouchenben.add(this.state.cargoInfoPrint.purchaseCosts[i].money);
                      this.state.otherMoneyItems.push({costClauseName:(this.state.cargoInfoPrint.cargoMode==1?'-':'+')+this.state.cargoInfoPrint.purchaseCosts[i].costClauseName,money:this.state.cargoInfoPrint.purchaseCosts[i].money});
                      // if((this.state.cargoInfoPrint.cargoMode==0&&this.state.cargoInfoPrint.purchaseCosts[i].payee==0)||(this.state.cargoInfoPrint.cargoMode==2&&this.state.cargoInfoPrint.purchaseCosts[i].payee==2)){}
                    }
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(610),money:caigouchenben});
                  }
                  if(this.state.cargoInfoPrint.processCosts){
                    let jiagongfei=0;
                    for(let i=0;i<this.state.cargoInfoPrint.processCosts.length;i++){
                      if((this.state.cargoInfoPrint.cargoMode==0&&this.state.cargoInfoPrint.processCosts[i].payee==0)||(this.state.cargoInfoPrint.cargoMode==2&&this.state.cargoInfoPrint.processCosts[i].payee==2)){
                        jiagongfei=jiagongfei.add(this.state.cargoInfoPrint.processCosts[i].money);
                        this.state.otherMoneyItems.push({costClauseName:'+'+this.state.cargoInfoPrint.processCosts[i].costClauseName,money:this.state.cargoInfoPrint.processCosts[i].money});
                      }
                    }
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(993),money:jiagongfei});
                  }
                  this.state.otherMoneyItems.push({costClauseName:stringUtil.language(747),money:this.state.cargoInfoPrint.transferMoney});
                  this.state.otherMoneyItems.push({costClauseName:stringUtil.language(748),money:this.state.cargoInfoPrint.transferToMoney});
                }
                if(this.state.cargoInfoPrint.rebateMoney!=0){
                  this.state.otherMoneyItems.push({costClauseName:'+'+stringUtil.language(1150),money:this.state.cargoInfoPrint.rebateMoney});
                  this.state.addMoneyItems.push({costClauseName:stringUtil.language(1150),money:this.state.cargoInfoPrint.rebateMoney});
                }
                if(this.state.cargoInfoPrint.disburdenMoney!=0){
                  this.state.otherMoneyItems.push({costClauseName:'-'+JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,money:this.state.cargoInfoPrint.disburdenMoney});
                  this.state.subMoneyItems.push({costClauseName:'-'+JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,money:this.state.cargoInfoPrint.disburdenMoney});
                }
                if(this.state.cargoInfoPrint.entrustMoney!=0){
                  this.state.otherMoneyItems.push({costClauseName:'-'+stringUtil.language(941),money:this.state.cargoInfoPrint.entrustMoney});
                  this.state.subMoneyItems.push({costClauseName:'-'+stringUtil.language(941),money:this.state.cargoInfoPrint.entrustMoney})
                }
                if(this.state.cargoInfoPrint.discountMoney!=0){
                  this.state.otherMoneyItems.push({costClauseName:'-'+stringUtil.language(543),money:this.state.cargoInfoPrint.discountMoney});
                  this.state.subMoneyItems.push({costClauseName:'-'+stringUtil.language(543),money:this.state.cargoInfoPrint.discountMoney});
                }
                if(this.state.cargoInfoPrint.expends!=null)for(let i=0;i<this.state.cargoInfoPrint.expends.length;i++){
                  this.state.otherMoneyItems.push({costClauseName:'-'+this.state.cargoInfoPrint.expends[i].ownerExpendClauseName,money:this.state.cargoInfoPrint.expends[i].money});
                  if(this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0){
                    this.state.subMoneyItems.push({costClauseName:'-'+this.state.cargoInfoPrint.expends[i].ownerExpendClauseName,money:this.state.cargoInfoPrint.expends[i].money});
                  }
                }
                })()}
                {(this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?<div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                <div style={{width:'25%',display:'flex',flexDirection:'column',padding:'5px',borderStyle:'solid',borderWidth:'1px'}}>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',fontSize:17}}>{stringUtil.language(1068)+':'+arithUtil.fenToYuan(this.state.totalInfo.saleGoodsMoney)}</div>
                  {this.state.addMoneyItems.map((item,key)=>{
                      return(<div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',fontSize:17}} key={key}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</div>);
                  })}
                  <div style={{width:'100%',textAlign:'center',fontSize:17,flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                    <div>{stringUtil.language(890)}{arithUtil.fenToYuan(this.state.ownerMoney)}</div></div>
                  </div>
                  <div style={{marginLeft:'3%',width:'25%',display:'flex',padding:'5px',flexDirection:'column',borderStyle:'solid',borderWidth:'1px'}}>
                  {
                  this.state.subMoneyItems.map((item,key)=>{
                    this.state.subTotalMoney=this.state.subTotalMoney.add(item.money);
                      return(<div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',fontSize:17}} key={key}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</div>);
                  })}
                  <div style={{width:'100%',textAlign:'center',fontSize:17,flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                    <div>{stringUtil.language(890)}{arithUtil.fenToYuan(this.state.subTotalMoney)}</div></div>
                  </div>
                  <div style={{alignSelf:'flex-end',flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'flex-end',alignItems:'center',color:'#F00',fontSize:25,fontWeight:'bold',paddingRight:10}}>{(this.state.cargoInfoPrint.cargoMode!=0?stringUtil.language(756)+'：':stringUtil.language(754)+'：')+arithUtil.fenToYuan(this.state.cargoInfoPrint.balance)}</div>
                </div>:<div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                  {this.state.otherMoneyItems.map((item,key)=>{
                      return(<div style={{height:25,width:'33.3%',display:'flex',flexDirection:'row',alignItems:'center',fontSize:17}} key={key}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</div>);
                  })}
                </div>}
                {this.state.cargoInfoPrint.receiveDescribe?<div><span style={{fontSize:14}}>{stringUtil.language(429)}:{this.state.cargoInfoPrint.receiveDescribe}</span></div>:''}
                {this.state.cargoInfoPrint.finishDescribe?<span style={{fontSize:14}}>{stringUtil.language(750)}:{this.state.cargoInfoPrint.finishDescribe}</span>:''}
                {(this.state.nWmode==1&&this.state.cargoInfoPrint.cargoMode==0)?"": <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center',color:'#F00',fontSize:25,fontWeight:'bold',paddingRight:10}}>{(this.state.cargoInfoPrint.cargoMode!=0?stringUtil.language(756)+'：':stringUtil.language(754)+'：')+arithUtil.fenToYuan(this.state.cargoInfoPrint.balance)}</div>}
            </div>
        </div>:''}
      </div>)
  }
  getExcel(cargoInfo){
    let resArr=new Array();let colspans=0;
    if(cargoInfo.cargoMode==0)colspans=8+(cargoInfo.saleCosts?cargoInfo.saleCosts.length:0);
    else if(cargoInfo.cargoMode==1)colspans=15+(cargoInfo.saleCosts?cargoInfo.saleCosts.length:0);
    else if(cargoInfo.cargoMode==2)colspans=9+(cargoInfo.saleCosts?cargoInfo.saleCosts.length:0);
    resArr.push(`<tr style="height: 50px"><td align="center" colspan="${colspans}"><b><font size="3">${cargoInfo.cargoMode==1?stringUtil.language(426)+(cargoInfo.targetName?'_'+cargoInfo.targetName:''):cargoInfo.targetName} ${stringUtil.language(1175)}${cargoInfo.cargoNo}${stringUtil.language(1176)}</font></b></td></tr>
    <tr><td colspan="3"><b><font size="3">${stringUtil.language(430)}${cargoInfo.workerId==0?stringUtil.language(431):cargoInfo.workerName}</font></b></td>
    <td align="right" colspan="${colspans-3}"><b><font size="3">${arithUtil.formatToTimeStr(new Date(cargoInfo.receiveTime),'Y-M-D h:m:s')}${stringUtil.language(1052)}${cargoInfo.state==2?'；'+arithUtil.formatToTimeStr(new Date(cargoInfo.settleTime),'Y-M-D h:m:s')+'stringUtil.language(1053)':''}</font></b></td></tr>
    <tr><td colspan="3"><b><font size="3">${cargoInfo.carNumber?stringUtil.language(857)+cargoInfo.carNumber:''}</font></b></td>
    <td align="right" colspan="${colspans-3}"><b><font size="3">${stringUtil.language(914)}${stringUtil.currency(1)}、${this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</font></b></td></tr>
    <tr><td></td></tr>`);
    let cargoInfoTotal={sendNumber:0,transferNumber:0,processNumber:0,purchaseNumber:0,stockInOutNumber:0,saleNumber:0,balanceNumber:0,sendWeight:0,transferWeight:0,processWeight:0,purchaseWeight:0,
      stockInOutWeight:0,saleWeight:0,balanceWeight:0,purchaseGoodsMoney:0,saleGoodsMoney:0,ownerOther:0,zongchenben:0,saleTotalMoney:0,saleChenben:0,maoli:0,balanceChenben:0};
    if(cargoInfo.cargoMode==0){//代销
      resArr.push(`<tr><td align="center" colspan="${colspans}"><b><font size="3">${stringUtil.language(757)}</font></b></td></tr>`);
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1057)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1054)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1055)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1059)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(966)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1068)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1058)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){
        cargoInfoTotal['costs'+cargoInfo.saleCosts[key].costClauseId]=0;
        resArr.push(`<td align="left"><b><font size="3">${cargoInfo.saleCosts[key].costClauseName}</font></b></td>`);
      }resArr.push(`</tr>`);
      for(let key=0;key<cargoInfo.commodities.length;key++){let item=cargoInfo.commodities[key];
        cargoInfoTotal.sendNumber=(cargoInfoTotal.sendNumber)+(item.sendNumber);
        cargoInfoTotal.transferNumber=(cargoInfoTotal.transferNumber)+(item.transferNumber);
        cargoInfoTotal.processNumber=(cargoInfoTotal.processNumber)+(item.processNumber);
        cargoInfoTotal.purchaseNumber=(cargoInfoTotal.purchaseNumber)+(item.purchaseNumber);
        cargoInfoTotal.stockInOutNumber=(cargoInfoTotal.stockInOutNumber)+(item.stockInOutNumber);
        cargoInfoTotal.saleNumber=(cargoInfoTotal.saleNumber)+(item.saleNumber);
        cargoInfoTotal.balanceNumber=(cargoInfoTotal.balanceNumber)+(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
        cargoInfoTotal.sendWeight=(cargoInfoTotal.sendWeight).add(item.sendWeight);
        cargoInfoTotal.transferWeight=(cargoInfoTotal.transferWeight).add(item.transferWeight);
        cargoInfoTotal.processWeight=(cargoInfoTotal.processWeight).add(item.processWeight);
        cargoInfoTotal.purchaseWeight=(cargoInfoTotal.purchaseWeight).add(item.purchaseWeight);
        cargoInfoTotal.stockInOutWeight=(cargoInfoTotal.stockInOutWeight).add(item.stockInOutWeight);
        cargoInfoTotal.saleWeight=(cargoInfoTotal.saleWeight).add(item.saleWeight);
        cargoInfoTotal.balanceWeight=(cargoInfoTotal.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1));
        cargoInfoTotal.purchaseGoodsMoney=(cargoInfoTotal.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
        cargoInfoTotal.saleGoodsMoney=(cargoInfoTotal.saleGoodsMoney).add(item.saleGoodsMoney);
        //{stringUtil.language(1116)}<td align="left"><font size="3">${(arithUtil.fenToYuan(item.saleGoodsMoney)).div(item.saleNumber,2)}</font></td>
        resArr.push(`<tr><td align="left"><font size="3">${stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}${!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</font></td>
        <td align="left"><font size="3">${item.stockInOutNumber}</font></td>
        <td align="left"><font size="3">${item.sendNumber}</font></td>
        <td align="left"><font size="3">${item.transferNumber}</font></td>
        <td align="left"><font size="3">${item.saleNumber}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(item.numJJia)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(item.saleGoodsMoney)}</font></td>
        <td align="left"><font size="3">${item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber}</font></td>`);
        if(cargoInfo.saleCosts)for(let keyb=0;keyb<cargoInfo.saleCosts.length;keyb++){let itemb=cargoInfo.saleCosts[keyb];
          let moneyTemp=0;
          for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
            if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
          }
          cargoInfoTotal['costs'+itemb.costClauseId]=(cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
          if(moneyTemp)resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyTemp)}</font></td>`);
          else resArr.push(`<td align="left"><font size="3"></font></td>`);
        }resArr.push(`</tr>`);
      }
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(890)}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.stockInOutNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.sendNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.transferNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.saleNumber}</font></b></td>
      <td align="left"><b><font size="3"></font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.saleGoodsMoney)}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.balanceNumber}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){let itemb=cargoInfo.saleCosts[key];
        resArr.push(`<td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal['costs'+itemb.costClauseId])}</font></b></td>`);
      }resArr.push(`</tr><tr style="height: 50px"><td></td></tr>`);

      resArr.push(`<tr><td align="center" colspan="${colspans}"><b><font size="3">${stringUtil.language(758)}</font></b></td></tr>`);
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1057)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1054)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1055)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1059)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(961)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1068)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1058)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){
        cargoInfoTotal['costs'+cargoInfo.saleCosts[key].costClauseId]=0;
        resArr.push(`<td align="left"><b><font size="3">${cargoInfo.saleCosts[key].costClauseName}</font></b></td>`);
      }resArr.push(`</tr>`);
      for(let key=0;key<cargoInfo.commodities.length;key++){let item=cargoInfo.commodities[key];
        //{stringUtil.language(1116)}<td align="left"><font size="3">${(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</font></td>
        resArr.push(`<tr><td align="left"><font size="3">${stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}${!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.sendWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(item.saleGoodsMoney)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</font></td>`);
        if(cargoInfo.saleCosts)for(let keyb=0;keyb<cargoInfo.saleCosts.length;keyb++){let itemb=cargoInfo.saleCosts[keyb];
          let moneyTemp=0;
          for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
            if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
          }
          cargoInfoTotal['costs'+itemb.costClauseId]=(cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
          if(moneyTemp)resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyTemp)}</font></td>`);
          else resArr.push(`<td align="left"><font size="3"></font></td>`);
        }resArr.push(`</tr>`);
      }
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(890)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.stockInOutWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.sendWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.transferWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.saleWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3"></font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.saleGoodsMoney)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.balanceWeight,this.state.weightMode)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){let itemb=cargoInfo.saleCosts[key];
        resArr.push(`<td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal['costs'+itemb.costClauseId])}</font></b></td>`);
      }resArr.push(`</tr><tr style="height: 50px"><td></td></tr>`);
    }else if(cargoInfo.cargoMode==1){//stringUtil.language(1051)
      resArr.push(`<tr><td align="center" colspan="${colspans}"><b><font size="3">${stringUtil.language(757)}</font></b></td></tr>`);
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1056)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1057)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1055)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1044)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(947)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(559)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(742)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(743)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(966)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(889)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(600)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1148)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1149)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(744)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){
        cargoInfoTotal['costs'+cargoInfo.saleCosts[key].costClauseId]=0;
        resArr.push(`<td align="left"><b><font size="3">${cargoInfo.saleCosts[key].costClauseName}</font></b></td>`);
      }resArr.push(`</tr>`);
      for(let key=0;key<cargoInfo.commodities.length;key++){let item=cargoInfo.commodities[key];
        let zongchenben=(item.transferMoney).add(item.purchaseGoodsMoney).add(item.processNumber>0?item.processMoney:0);
        if(item.purchaseCosts){
          for(let i=0;i<item.purchaseCosts.length;i++){
            zongchenben=zongchenben.add(item.purchaseCosts[i].money);
          }
        }
        let saleTotalMoney=item.saleGoodsMoney;
        if(item.saleCosts){
          for(let i=0;i<item.saleCosts.length;i++){
            saleTotalMoney=saleTotalMoney.add(item.saleCosts[i].money);
          }
        }
        let danweichenbenN=(zongchenben).div(item.transferNumber+item.purchaseNumber+(item.processNumber>0?item.processNumber:0));//stringUtil.language(1021)的{stringUtil.language(559)}
        zongchenben=zongchenben.add(item.processNumber<0?(item.processNumber).mul(danweichenbenN):0);
        let yushu=item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber;
        cargoInfoTotal.transferNumber = (cargoInfoTotal.transferNumber)+item.transferNumber;
        cargoInfoTotal.transferWeight = (cargoInfoTotal.transferWeight)+item.transferWeight;
        cargoInfoTotal.processNumber = (cargoInfoTotal.processNumber)+item.processNumber;
        cargoInfoTotal.processWeight = (cargoInfoTotal.processWeight)+item.processWeight;
        cargoInfoTotal.purchaseNumber = (cargoInfoTotal.purchaseNumber)+item.purchaseNumber;
        cargoInfoTotal.purchaseWeight = (cargoInfoTotal.purchaseWeight)+item.purchaseWeight;
        cargoInfoTotal.stockInOutNumber = (cargoInfoTotal.stockInOutNumber)+item.stockInOutNumber;
        cargoInfoTotal.stockInOutWeight = (cargoInfoTotal.stockInOutWeight)+item.stockInOutWeight;
        cargoInfoTotal.sendNumber = (cargoInfoTotal.sendNumber)+item.sendNumber;
        cargoInfoTotal.saleNumber = (cargoInfoTotal.saleNumber)+item.saleNumber;
        cargoInfoTotal.sendWeight = (cargoInfoTotal.sendWeight).add(item.sendWeight);
        cargoInfoTotal.saleWeight = (cargoInfoTotal.saleWeight).add(item.saleWeight);
        cargoInfoTotal.purchaseGoodsMoney = (cargoInfoTotal.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
        cargoInfoTotal.saleGoodsMoney = (cargoInfoTotal.saleGoodsMoney).add(item.saleGoodsMoney);
        cargoInfoTotal.zongchenben = (cargoInfoTotal.zongchenben).add(zongchenben);
        cargoInfoTotal.saleTotalMoney = (cargoInfoTotal.saleTotalMoney).add(saleTotalMoney);
        cargoInfoTotal.saleChenben = (cargoInfoTotal.saleChenben).add(danweichenbenN.mul(item.saleNumber));
        cargoInfoTotal.maoli = (cargoInfoTotal.maoli).add(saleTotalMoney.sub(danweichenbenN.mul(item.saleNumber)));
        cargoInfoTotal.balanceNumber = (cargoInfoTotal.balanceNumber)+(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
        cargoInfoTotal.balanceWeight = (cargoInfoTotal.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1));
        cargoInfoTotal.balanceChenben = (cargoInfoTotal.balanceChenben).add(danweichenbenN.mul(yushu));
        //{stringUtil.language(1116)}<td align="left"><font size="3">${(arithUtil.fenToYuan(item.saleGoodsMoney)).div(item.saleNumber,2)}</font></td>
        resArr.push(`<tr><td align="left"><font size="3">${stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}${!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</font></td>
        <td align="left"><font size="3">${item.processNumber}</font></td>
        <td align="left"><font size="3">${item.stockInOutNumber}</font></td>
        <td align="left"><font size="3">${item.transferNumber}</font></td>
        <td align="left"><font size="3">${item.purchaseNumber}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(zongchenben)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(danweichenbenN)}</font></td>
        <td align="left"><font size="3">${item.saleNumber}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(item.numJJia)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(saleTotalMoney)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(danweichenbenN.mul(item.saleNumber))}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(saleTotalMoney.sub(danweichenbenN.mul(item.saleNumber)))}</font></td>
        <td align="left"><font size="3">${yushu}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(danweichenbenN.mul(yushu))}</font></td>`);
        if(cargoInfo.saleCosts)for(let keyb=0;keyb<cargoInfo.saleCosts.length;keyb++){let itemb=cargoInfo.saleCosts[keyb];
          let moneyTemp=0;
          for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
            if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
          }
          cargoInfoTotal['costs'+itemb.costClauseId]=(cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
          if(moneyTemp)resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyTemp)}</font></td>`);
          else resArr.push(`<td align="left"><font size="3"></font></td>`);
        }resArr.push(`</tr>`);
      }
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(890)}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.processNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.stockInOutNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.transferNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.purchaseNumber}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.zongchenben)}</font></b></td>
      <td align="left"><b><font size="3"></font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.saleNumber}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.saleWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3"></font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.saleTotalMoney)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.saleChenben)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.maoli)}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.balanceNumber}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.balanceChenben)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){let itemb=cargoInfo.saleCosts[key];
        resArr.push(`<td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal['costs'+itemb.costClauseId])}</font></b></td>`);
      }resArr.push(`</tr><tr style="height: 50px"><td></td></tr>`);

      resArr.push(`<tr><td align="center" colspan="${colspans}"><b><font size="3">${stringUtil.language(758)}</font></b></td></tr>`);
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1056)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1057)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1055)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1044)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(947)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(559)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(742)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(743)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(961)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(889)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(600)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1148)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1149)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(744)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){
        cargoInfoTotal['costs'+cargoInfo.saleCosts[key].costClauseId]=0;
        resArr.push(`<td align="left"><b><font size="3">${cargoInfo.saleCosts[key].costClauseName}</font></b></td>`);
      }resArr.push(`</tr>`);
      for(let key=0;key<cargoInfo.commodities.length;key++){let item=cargoInfo.commodities[key];
        let zongchenben=(item.transferMoney).add(item.purchaseGoodsMoney).add(item.processNumber>0?item.processMoney:0);
        if(item.purchaseCosts){
          for(let i=0;i<item.purchaseCosts.length;i++){
            zongchenben=zongchenben.add(item.purchaseCosts[i].money);
          }
        }
        let saleTotalMoney=item.saleGoodsMoney;
        if(item.saleCosts){
          for(let i=0;i<item.saleCosts.length;i++){
            saleTotalMoney=saleTotalMoney.add(item.saleCosts[i].money);
          }
        }
        let danweichenbenN=(zongchenben).div(item.transferNumber+item.purchaseNumber+(item.processNumber>0?item.processNumber:0));//stringUtil.language(1021)的{stringUtil.language(559)}
        zongchenben=zongchenben.add(item.processNumber<0?(item.processNumber).mul(danweichenbenN):0);
        let yushu=item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber;
        //{stringUtil.language(1116)}<td align="left"><font size="3">${(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</font></td>
        resArr.push(`<tr><td align="left"><font size="3">${stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}${!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.processWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(zongchenben)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(danweichenbenN)}</font></td>
        <td align="left"><font size="3">${item.saleNumber}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(saleTotalMoney)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(danweichenbenN.mul(item.saleNumber))}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(saleTotalMoney.sub(danweichenbenN.mul(item.saleNumber)))}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(danweichenbenN.mul(yushu))}</font></td>`);
        if(cargoInfo.saleCosts)for(let keyb=0;keyb<cargoInfo.saleCosts.length;keyb++){let itemb=cargoInfo.saleCosts[keyb];
          let moneyTemp=0;
          for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
            if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
          }
          cargoInfoTotal['costs'+itemb.costClauseId]=(cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
          if(moneyTemp)resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyTemp)}</font></td>`);
          else resArr.push(`<td align="left"><font size="3"></font></td>`);
        }resArr.push(`</tr>`);
      }
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(890)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.processWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.stockInOutWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.transferWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.purchaseWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.zongchenben)}</font></b></td>
      <td align="left"><b><font size="3"></font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.saleNumber}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.saleWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3"></font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.saleTotalMoney)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.saleChenben)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.maoli)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.balanceWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.balanceChenben)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){let itemb=cargoInfo.saleCosts[key];
        resArr.push(`<td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal['costs'+itemb.costClauseId])}</font></b></td>`);
      }resArr.push(`</tr><tr style="height: 50px"><td></td></tr>`);
    }else if(cargoInfo.cargoMode==2){//炒货
      resArr.push(`<tr><td align="center" colspan="${colspans}"><b><font size="3">${stringUtil.language(757)}</font></b></td></tr>`);
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1056)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1057)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1055)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1044)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1059)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(966)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1068)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1058)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){
        cargoInfoTotal['costs'+cargoInfo.saleCosts[key].costClauseId]=0;
        resArr.push(`<td align="left"><b><font size="3">${cargoInfo.saleCosts[key].costClauseName}</font></b></td>`);
      }resArr.push(`</tr>`);
      for(let key=0;key<cargoInfo.commodities.length;key++){let item=cargoInfo.commodities[key];
        cargoInfoTotal.sendNumber=(cargoInfoTotal.sendNumber)+(item.sendNumber);
        cargoInfoTotal.transferNumber=(cargoInfoTotal.transferNumber)+(item.transferNumber);
        cargoInfoTotal.processNumber=(cargoInfoTotal.processNumber)+(item.processNumber);
        cargoInfoTotal.purchaseNumber=(cargoInfoTotal.purchaseNumber)+(item.purchaseNumber);
        cargoInfoTotal.stockInOutNumber=(cargoInfoTotal.stockInOutNumber)+(item.stockInOutNumber);
        cargoInfoTotal.saleNumber=(cargoInfoTotal.saleNumber)+(item.saleNumber);
        cargoInfoTotal.balanceNumber=(cargoInfoTotal.balanceNumber)+(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
        cargoInfoTotal.sendWeight=(cargoInfoTotal.sendWeight).add(item.sendWeight);
        cargoInfoTotal.transferWeight=(cargoInfoTotal.transferWeight).add(item.transferWeight);
        cargoInfoTotal.processWeight=(cargoInfoTotal.processWeight).add(item.processWeight);
        cargoInfoTotal.purchaseWeight=(cargoInfoTotal.purchaseWeight).add(item.purchaseWeight);
        cargoInfoTotal.stockInOutWeight=(cargoInfoTotal.stockInOutWeight).add(item.stockInOutWeight);
        cargoInfoTotal.saleWeight=(cargoInfoTotal.saleWeight).add(item.saleWeight);
        cargoInfoTotal.balanceWeight=(cargoInfoTotal.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1));
        cargoInfoTotal.purchaseGoodsMoney=(cargoInfoTotal.purchaseGoodsMoney).add(item.purchaseGoodsMoney);
        cargoInfoTotal.saleGoodsMoney=(cargoInfoTotal.saleGoodsMoney).add(item.saleGoodsMoney);
        //{stringUtil.language(1116)}<td align="left"><font size="3">${(arithUtil.fenToYuan(item.saleGoodsMoney)).div(item.saleNumber,2)}</font></td>
        resArr.push(`<tr><td align="left"><font size="3">${stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}${!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</font></td>
        <td align="left"><font size="3">${item.processNumber}</font></td>
        <td align="left"><font size="3">${item.stockInOutNumber}</font></td>
        <td align="left"><font size="3">${item.transferNumber}</font></td>
        <td align="left"><font size="3">${item.purchaseNumber}</font></td>
        <td align="left"><font size="3">${item.saleNumber}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(item.numJJia)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(item.saleGoodsMoney)}</font></td>
        <td align="left"><font size="3">${item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber}</font></td>`);
        if(cargoInfo.saleCosts)for(let keyb=0;keyb<cargoInfo.saleCosts.length;keyb++){let itemb=cargoInfo.saleCosts[keyb];
          let moneyTemp=0;
          for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
            if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
          }
          cargoInfoTotal['costs'+itemb.costClauseId]=(cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
          if(moneyTemp)resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyTemp)}</font></td>`);
          else resArr.push(`<td align="left"><font size="3"></font></td>`);
        }resArr.push(`</tr>`);
      }
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(890)}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.processNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.stockInOutNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.transferNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.purchaseNumber}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.saleNumber}</font></b></td>
      <td align="left"><b><font size="3"></font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.saleGoodsMoney)}</font></b></td>
      <td align="left"><b><font size="3">${cargoInfoTotal.balanceNumber}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){let itemb=cargoInfo.saleCosts[key];
        resArr.push(`<td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal['costs'+itemb.costClauseId])}</font></b></td>`);
      }resArr.push(`</tr><tr style="height: 50px"><td></td></tr>`);

      resArr.push(`<tr><td align="center" colspan="${colspans}"><b><font size="3">${stringUtil.language(758)}</font></b></td></tr>`);
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1056)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1057)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1055)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1044)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1059)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(961)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1068)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1058)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){
        cargoInfoTotal['costs'+cargoInfo.saleCosts[key].costClauseId]=0;
        resArr.push(`<td align="left"><b><font size="3">${cargoInfo.saleCosts[key].costClauseName}</font></b></td>`);
      }resArr.push(`</tr>`);
      for(let key=0;key<cargoInfo.commodities.length;key++){let item=cargoInfo.commodities[key];
        //{stringUtil.language(1116)}<td align="left"><font size="3">${(arithUtil.fenToYuan(item.saleGoodsMoney)).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode),2)}</font></td>
        resArr.push(`<tr><td align="left"><font size="3">${stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}${!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.processWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.unitPriceTo(arithUtil.fenToYuan(item.weightJJia),0,this.state.weightMode)}</font></td>
        <td align="left"><font size="3">${arithUtil.fenToYuan(item.saleGoodsMoney)}</font></td>
        <td align="left"><font size="3">${arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</font></td>`);
        if(cargoInfo.saleCosts)for(let keyb=0;keyb<cargoInfo.saleCosts.length;keyb++){let itemb=cargoInfo.saleCosts[keyb];
          let moneyTemp=0;
          for(let i=0;i<(item.saleCosts?item.saleCosts.length:0);i++){
            if(itemb.costClauseId==item.saleCosts[i].costClauseId){moneyTemp=item.saleCosts[i].money;break}
          }
          cargoInfoTotal['costs'+itemb.costClauseId]=(cargoInfoTotal['costs'+itemb.costClauseId]).add(moneyTemp);
          if(moneyTemp)resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyTemp)}</font></td>`);
          else resArr.push(`<td align="left"><font size="3"></font></td>`);
        }resArr.push(`</tr>`);
      }
      resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(890)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.processWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.stockInOutWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.transferWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.purchaseWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.saleWeight,this.state.weightMode)}</font></b></td>
      <td align="left"><b><font size="3"></font></b></td>
      <td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal.saleGoodsMoney)}</font></b></td>
      <td align="left"><b><font size="3">${arithUtil.keToWeight(cargoInfoTotal.balanceWeight,this.state.weightMode)}</font></b></td>`);
      if(cargoInfo.saleCosts)for(let key=0;key<cargoInfo.saleCosts.length;key++){let itemb=cargoInfo.saleCosts[key];
        resArr.push(`<td align="left"><b><font size="3">${arithUtil.fenToYuan(cargoInfoTotal['costs'+itemb.costClauseId])}</font></b></td>`);
      }resArr.push(`</tr><tr style="height: 50px"><td></td></tr>`);
    }
    resArr.push(`<tr><td align="center" colspan="7"><b><font size="3">${stringUtil.language(432)}</font></b></td></tr>`);
    resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1048)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1022)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(962)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1050)}</font></b></td>
      <td align="left"><b><font size="3">${stringUtil.language(1041)}</font></b></td></tr>`);
    if(cargoInfo.detailed)for(let key=0;key<cargoInfo.detailed.length;key++){let item=cargoInfo.detailed[key];
      let totalItemInfo={number:0,weight:0,money:0};let totalItemInfoAvg={number:0,weight:0,moneyNumber:0,moneyWeight:0};
      if(item.items==null) item.items=[];
      for(let i=0;i<item.items.length;i++){
        totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
        totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
        item.items[i].money = item.items[i].goodsMoney.sub(item.items[i].diffGoodsMoney);
        // for(let j=0;j<item.items[i].costs.length;j++){
        //   item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
        // }
        totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
        if(item.items[i].mode==1){
          totalItemInfoAvg.number = (totalItemInfoAvg.number).add(item.items[i].number);
          totalItemInfoAvg.moneyNumber = (totalItemInfoAvg.moneyNumber).add(item.items[i].goodsMoney.sub(item.items[i].diffGoodsMoney));
        }
        if(item.items[i].mode==0){
          totalItemInfoAvg.weight = (totalItemInfoAvg.weight).add(item.items[i].weight);
          totalItemInfoAvg.moneyWeight = (totalItemInfoAvg.moneyWeight).add(item.items[i].goodsMoney.sub(item.items[i].diffGoodsMoney));
        }
      }
      resArr.push(`<tr><td align="left" rowspan="${item.items.length+1}"><b><font size="3">${stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}${!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</font></b></td>
      <td align="left" colspan="6" style="color: #52A056"><b><font size="3">
        ${stringUtil.language(1182)}${totalItemInfo.number}${stringUtil.language(1173)}，${arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，${arithUtil.fenToYuan(totalItemInfo.money)}${stringUtil.currency(1)}
        ${totalItemInfoAvg.number?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyNumber).div(totalItemInfoAvg.number,2)+stringUtil.currency(1)+'/'+stringUtil.language(1173):''}${totalItemInfoAvg.weight?'，'+arithUtil.fenToYuan(totalItemInfoAvg.moneyWeight).div(arithUtil.keToWeight(totalItemInfoAvg.weight,this.state.weightMode),2)+stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):''}
        ${totalItemInfo.number?'，'+arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode).div(totalItemInfo.number,2)+(this.state.weightMode?stringUtil.language(1172)+'/'+stringUtil.language(1173):stringUtil.language(1023)+'/'+stringUtil.language(1173)):''}
      </font></b></td></tr>`);
      for(let keyb=0;keyb<item.items.length;keyb++){let itemb=item.items[keyb];
        resArr.push(`<tr><td align="left"><b><font size="3">${itemb.customerName}</font></b></td>
        <td align="left"><b><font size="3">${itemb.number}</font></b></td>
        <td align="left"><b><font size="3">${arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</font></b></td>
        <td align="left"><b><font size="3">${(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</font></b></td>
        <td align="left"><b><font size="3">${itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</font></b></td>
        <td align="left"><b><font size="3">${arithUtil.fenToYuan(itemb.money)}</font></b></td></tr>`);
      }
    }
    resArr.push(`<tr style="height: 50px"><td></td></tr>`);
    let otherMoneyItems=[],docMoney=cargoInfoTotal.saleGoodsMoney,ownerMoney=cargoInfoTotal.saleGoodsMoney;
    if(cargoInfo.saleCosts){
      for(let i=0;i<cargoInfo.saleCosts.length;i++){
        docMoney = (docMoney).add(cargoInfo.saleCosts[i].money);
        if(cargoInfo.cargoMode==0&&cargoInfo.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
          ownerMoney = (ownerMoney).add(cargoInfo.saleCosts[i].money);
        }
        otherMoneyItems.push({costClauseName:cargoInfo.saleCosts[i].costClauseName,money:cargoInfo.saleCosts[i].money});
      }
    }
    otherMoneyItems.push({costClauseName:stringUtil.language(746),money:docMoney});
    if(cargoInfo.cargoMode==0)otherMoneyItems.push({costClauseName:stringUtil.language(592),money:ownerMoney});
    if(cargoInfo.cargoMode==2)otherMoneyItems.push({costClauseName:stringUtil.language(594),money:ownerMoney});
    if(cargoInfo.cargoMode==1||cargoInfo.cargoMode==2){
      if(cargoInfo.purchaseCosts){
        let caigouchenben=cargoInfoTotal.purchaseGoodsMoney;
        for(let i=0;i<cargoInfo.purchaseCosts.length;i++){
          caigouchenben=caigouchenben.add(cargoInfo.purchaseCosts[i].money);
          otherMoneyItems.push({costClauseName:cargoInfo.purchaseCosts[i].costClauseName,money:cargoInfo.purchaseCosts[i].money});
        }
        otherMoneyItems.push({costClauseName:stringUtil.language(610),money:caigouchenben});
      }
      if(cargoInfo.processCosts){
        let jiagongfei=0;
        for(let i=0;i<cargoInfo.processCosts.length;i++){
          jiagongfei=jiagongfei.add(cargoInfo.processCosts[i].money);
          otherMoneyItems.push({costClauseName:cargoInfo.processCosts[i].costClauseName,money:cargoInfo.processCosts[i].money});
        }
        otherMoneyItems.push({costClauseName:stringUtil.language(993),money:jiagongfei});
      }
      otherMoneyItems.push({costClauseName:stringUtil.language(747),money:cargoInfo.transferMoney});
      otherMoneyItems.push({costClauseName:stringUtil.language(748),money:cargoInfo.transferToMoney});
    }
    if(cargoInfo.rebateMoney!=0)otherMoneyItems.push({costClauseName:stringUtil.language(1150),money:cargoInfo.rebateMoney});
    if(cargoInfo.disburdenMoney!=0)otherMoneyItems.push({costClauseName:JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,money:cargoInfo.disburdenMoney});
    if(cargoInfo.entrustMoney!=0)otherMoneyItems.push({costClauseName:stringUtil.language(941),money:cargoInfo.entrustMoney});
    if(cargoInfo.discountMoney!=0)otherMoneyItems.push({costClauseName:stringUtil.language(543),money:cargoInfo.discountMoney});
    if(cargoInfo.cargoMode==0&&cargoInfo.expends){
      for(let i=0;i<cargoInfo.expends.length;i++){
        otherMoneyItems.push({costClauseName:cargoInfo.expends[i].ownerExpendClauseName,money:cargoInfo.expends[i].money});
      }
    }
    for(let key=0,keyb=0;key<otherMoneyItems.length;key=key+4,keyb++){
      let item0 = otherMoneyItems[key];
      resArr.push(`<tr><td align="left"><b><font size="3">${item0.costClauseName+'：'+arithUtil.fenToYuan(item0.money)}</font></b></td>
      <td><b><font size="3"></font></b></td>`);
      if((key+1)<otherMoneyItems.length){
        let item1 = otherMoneyItems[key+1];
        resArr.push(`<td align="left"><b><font size="3">${item1.costClauseName+'：'+arithUtil.fenToYuan(item1.money)}</font></b></td>
        <td><b><font size="3"></font></b></td>`);
      }
      if((key+2)<otherMoneyItems.length){
        let item2 = otherMoneyItems[key+2];
        resArr.push(`<td align="left"><b><font size="3">${item2.costClauseName+'：'+arithUtil.fenToYuan(item2.money)}</font></b></td>
        <td><b><font size="3"></font></b></td>`);
      }
      if((key+3)<otherMoneyItems.length){
        let item3 = otherMoneyItems[key+3];
        resArr.push(`<td align="left"><b><font size="3">${item3.costClauseName+'：'+arithUtil.fenToYuan(item3.money)}</font></b></td>
        <td><b><font size="3"></font></b></td>`);
      }
      resArr.push(`</tr>`);
    }
    resArr.push(`<tr style="height: 50px"><td align="right" colspan="${colspans}" style="color: #F00"><b><font size="6">${(cargoInfo.cargoMode!=0?stringUtil.language(756)+'：':stringUtil.language(754)+'：')+arithUtil.fenToYuan(cargoInfo.balance)}</font></b></td></tr>`);
    otherUtil.createExcel(resArr.join(''),(cargoInfo.cargoMode==1?stringUtil.language(426)+(cargoInfo.targetName?'_'+cargoInfo.targetName:''):cargoInfo.targetName)+'（'+stringUtil.language(1175)+cargoInfo.cargoNo+stringUtil.language(1176)+'）');
  }
}

const styles = {
  roleTabItem:{
    width:'50%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize:17,
    fontWeight:'bold',
    color:'#FFF',
    cursor:'pointer'
  },
  roleItem:{
    height:arithUtil.pxToDp(50),
    borderBottomStyle:'solid',
    borderBottomColor:'#DCDCDC',
    borderBottomWidth: 1,
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    marginLeft:arithUtil.pxToDp(8),
    marginRight:arithUtil.pxToDp(8),
    paddingLeft:arithUtil.pxToDp(10),
    cursor:'pointer'
  },
  inputText:{
    height:arithUtil.pxToDp(35),
    borderStyle:'solid',
    borderColor:'#d0d0d0',
    borderWidth:1,
    borderRadius:2,
    padding:0,
    paddingLeft:arithUtil.pxToDp(5)
  },
  textInput:{
      height:arithUtil.pxToDp(35),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5),
      textAlign:'center',
      textAlignVertical:'center'
  },
};